
.content_form_gobernanza{
    position: relative;
    width: 100%;
    height: 100vh;
}
.content_form_gobernanza form{
    position: absolute;
    top: 48%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 35%;
    width: 410px;
    border-radius: 5px;
    
}

.boxes_form_gobernanza{
    padding: 2rem 0rem;
    background: #fff;
    border-radius: 3px;
}
.form_login_title p{
    text-align: center;
    margin: 2rem;
    font-size: 20px;
    color: #12406F;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
}
.title{
    justify-content: center;
    display: grid;
    align-items: center;
    
}
.boxes_form_gobernanza .logo{
    justify-content: center;
    display: flex;
}
.box_form_gobernanza{
    width: 100%;
    display: grid;
    padding: .5em 2em;
}
.box_form_gobernanza_text{
    justify-content: space-between;
    display: flex;
}
.box_form_gobernanza label{
    font-size: 16px;
    color: #545454;
    font-weight: 500;
}
.box_form_gobernanza input{
    padding: 7px 10px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 40px;
    line-height: 24px;
    border-radius: 5px;
    width: 100%;
}

.box_form_gobernanza_btn{
    width: 100%;
    display: grid;
    padding: 0.5em 2em;
}
.box_form_gobernanza_btn button{
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #003366;
    box-shadow: none;
    background: #12406F;
    color: #fff;
    font-size: 16px;
}
.box_form_gobernanza_btn button:hover{
    background: #00A7E0;
    border: 1px solid #00A7E0;
    cursor: pointer;
}

/* LoginGobernanza js */
.login_gobernanza{
    width: 65%;
    height: 100vh;
    background-color: #12406F;
    background-image: url("../assets/images/gobernanza/gobernanza-image-home-bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /*background: #eee;*/
}
.gobernanza_login_content{
    display: flex;
}
.lateral_box_gobernanza{
    width: 35%;
    background: #e5e5e5;
}