.noticias_boxes {
    display: flex;
    flex-wrap: wrap;
}

.noticia_box {
    width: calc(33.33% - 20px);
    margin: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}
.noticia_box:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.noticia_img_header {
    height: 155px;
}
.noticia_img_header img {
    width: 100%;
}
.noticia_img_header img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.noticia_title{
    color: #545454;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
}
.noticia_categoria, .noticia_fecha {
    color: #545454;
    margin-top: 10px;
    font-size: 14px;
}
.noticia_fecha {
    margin-top: 10px;
}
.noticia_categoria {
    /*border-bottom: 0.5px solid #ddd;*/
    font-style: italic;
    font-weight: 600;
}
.noticia_categoria i{
    font-size: 12px;
    margin-right: 5px;
}
.box_noticias_text{
    padding: 0rem 1rem 1rem 1rem;
    
}
.noticias_content_area{
    display: flex;
    padding: 4rem 0rem;
    justify-content: space-between;
}
.noticias_content{
    width: 74%;
}
.noticias_content_newsletter{
    width: 24%;
}
.noticias_box_newsletter h2{
    font-size: 22px;
    font-weight: 400;
    color: #545454;
    text-align: center;
    
}
.noticias_box_newsletter p{
    color: #545454;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.noticias_input_newsletter input{
    padding: 8px 8px;
    border-radius: 3px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}
.noticias_input_newsletter button{
    background-color: #225981;
    padding: 8px 8px;
    width: 100%;
    border: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #225981;
    margin-top: 10px;
}

/* Anuncios */
.anuncios{
    width: 100%;
    padding: 2rem 0rem 4rem;
}
.anuncios_content{
    display: flex;
    justify-content: space-between;
}
.anuncio_box{
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 32.3%;
    padding: 1rem;
    min-height: 135px;
    align-content: space-between;
    display: grid;
}
.anuncio_box_text{
    color: #545454;
}
.anuncio_box_text label{
    font-size: 16px;
}
.anuncio_box_text h3{
    font-size: 18px;
    margin-top: .5rem;
}
.anuncio_box p{
    font-size: 14px;
    margin-bottom: 0rem;
}

.anuncio_box:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}




@media screen and (max-device-width: 767px) {
    .noticias_content_newsletter {
        width: 100%;
    }
    .noticias_content_area{
        display: block;
    }
    .noticias_boxes{
        display: block;
    }
    .noticias_content {
        width: 100%;
    }
    .box_noticias_text {
        padding: 0rem 1rem 1rem 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
    }
    .noticia_box{
        border: none;
    }
    .noticia_img_header {
        height: 255px;
    }
    .anuncios_content{
        display: block;
    }
    .anuncio_box{
        width: 100%;
        margin-bottom: 14px;
    }
   
}