
.contacto{
    background: #EEE;
}

.title_contacto_help h3{    
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.box_content_contacto {
    width: auto;
    display: flex;
}
.tabs_contacto {
    display: block;
    justify-content: center;
    width: 100%;
    margin-top: 6rem;
    margin-bottom: 6rem;
}
.tab-container-contacto{
    display: flex;
}
.tabContacto {
    padding: 1rem;
    margin: 0px 5px 5px 0px;
    cursor: pointer;
    /* background: #EEE; */
    background: #FFF;
    font-size: 18px;
    font-weight: 400;
    color: #707070;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.tabContacto.activo {
    /* background: #F5F5F5; */
    background: #12406F;
    color: #FFF;
    font-weight: 600;
    border-radius: 3px;
}
.content-container-contacto {
    width: 100%;
    /* background: #F5F5F5; */
    background: #FFF;
    height: auto;
    min-height: 460px;
    align-items: center;
    display: flex;
}

.info_contacto {
    width: 100%;
    /*font-size: 18px;*/
    font-size: 14px;
    letter-spacing: 0;
    color: #545454;
   /* padding: 2rem 2rem 2rem 2rem;*/
   padding: 10px;
}

.info_contacto_title{
    font-size: 1.2rem;
    color: #003366;
    font-weight: 600;
    margin-bottom: 1rem;
}
.info_contacto_text p{
  margin-bottom: 0rem;
}
.info_contacto_text a{
  text-decoration: none;
  color: #707070;
}
.content_contacto_info{
    display: flex;
}
.info_contacto_text{
    color: #707070;
    width: 50%;
    margin: 5rem 0rem 5rem 3rem;
}
.info_contacto_icon{
    margin-top: 1rem;
    /*margin-bottom: 3rem;*/
}

.content_contacto_map{
    width: 50%;
}

/* DropDown */

.dropbtn {
    background-color: #12406F;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropbtn i{
    align-items: center;
    display: flex;
    margin-left: 2px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 190px;
    width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #12406F;
  }
  .dropdown_options{
    display: grid;
  }
  .dropdown_options label{
    padding: .5rem;
    cursor: pointer;
    font-size: 14px;
  }
  .dropdown_options label:hover{
    background: #EEE;

  }
 .contact_form_contacto{
    width: 100%;
    margin: 6rem 0rem;
    display: flex;
 }
 .contact_form_contacto_box{
    width: 50%;
    padding: 1rem;
 }
 .formulario .form-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.formulario input[type=email], .formulario input[type=text] {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    height: 50px;
    margin-bottom: 1.5rem;
    padding: 4px 10px;
    width: 49%;
}
.formulario select, .formulario textarea {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    padding: 4px 10px;
    width: 49%;
}
.formulario input[type=email], .formulario textarea{
    width: 100%;
}
.formulario textarea{
    min-height: 90px;
}
.btn_enviar_formulario_contact button{
    padding: 10px 35px;
    border: 1px solid #ddd;
    text-decoration: none;
    color: #fff;
    background: #12406F;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
}
.box_form_contact{
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    display: block;
    padding: 3rem;
    text-align: center;
    background: #e2f0fe;
}
.redes_list_contacto{
    display: flex;
    justify-content: center;
}
.redes_list_contacto li{
    width: 50px;
    margin: .5rem;
}

.alertMessage {
  background: aliceblue;
  border: 1px solid #E2F0FE;
  padding: .5rem;
  margin: .5rem 0rem;
  font-size: 14px;
  border-radius: 4px;
}
.alertMessage p{
  margin-bottom: 0rem;
}
  @media (max-width: 768px) {

    .content_contacto_info {
        display: block;
    }

    .content_contacto_map {
        width: 100%;
    }
    .info_contacto_text {
        color: #707070;
        width: 100%;
        margin: 1.2rem 0rem 2rem 0.2rem;
    } 
    .info_contacto_title h3{
        font-size: 20px;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 16px;
        font-weight: 400;
    }
    .contact_form_contacto{
      display: block;
    }
    .contact_form_contacto_box{
      width: 100%;
      padding: 1rem 0rem;
    }
    .contact_form_contacto_box h2{
        font-size: 20px;
    }
    .contact_form_contacto_box p{
        font-size: 14px;
    }
    .box_form_contact {
        padding: 2rem 0.6rem;
    }
    .formulario h2{
      font-size: 20px;
    }
    .formulario p{
      font-size: 14px;
    }
    .formulario input[type=email], .formulario input[type=text] {
      height: 40px;
      margin-bottom: 1rem;
      padding: 5px 5px;
    }
    .formulario select, .formulario textarea {
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 5px 5px;
      font-size: 12px;
    }
    /* Establece el tamaño del marcador de posición */
    .formulario input[type=email]::placeholder,
    .formulario input[type=text]::placeholder,
    .formulario textarea::placeholder {
        font-size: 12px; /* Cambia el tamaño del marcador de posición según sea necesario */
    }
    .formulario .chackbox_text{
      font-size: 14px;
    }


  }

  