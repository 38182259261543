.panel_viaje_curso{
    padding: 6rem 0rem;
    background: #f5f5f5;
}
.barra1_viaje_curso{
    display: flex;
    justify-content: space-between;
    margin: 0rem 1rem;
}

.barra1_viaje_curso label{
    font-size: 18px;
    color: #12406F;
    font-weight: 600;
    padding: 0rem 0rem 1rem;
}
.content-viaje-curso{
    width: 100%;
    padding: 1rem;
    background: #fff;
    margin-bottom: 4rem;
}

.viaje-curso-tabla th{
    color: #12406F;
    background:#F4F4F4;
    padding: .8rem .5rem;
}
.viaje-curso-tabla tr{
    padding: .8rem 0rem;
}
.content-viaje-curso table{
    border: none;
}
.content-viaje-curso td{
    padding: .5rem .5rem;
    color: #12406F;
}

.daily-table h5{
    font-size: 18px;
    font-weight: 400;
    color: #333;
}





.panel_viaje_curso .table-container {
    max-height: 400px; /* Ajusta la altura máxima de la tabla */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
  }
  
  .viaje-curso-tabla {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Asegura que las columnas tengan un ancho fijo */
  }
  
  .viaje-curso-tabla thead tr {
    position: sticky; /* Fija el encabezado */
    top: 0; /* Siempre al principio del contenedor */
    z-index: 2; /* Asegura que esté por encima de los datos */

  }
  
  .viaje-curso-tabla th,
  .viaje-curso-tabla td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .viaje-curso-tabla button{
    background-color: #D5E7FB;
    border: none;
    padding: .2rem;
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 10px 10px 20px -7px rgba(0, 0, 0, 0.2);

  }
.clima_title{
    color: #888;
    border-bottom: 2px dotted #888;
}
  .clima_box{
    display: flex;
    display: flex;
    justify-content: space-between;
  }
  .clima_box li{
    color: #888;
    font-size: 14px;
  }
  .clima_box {
    padding-left: 0rem;
    margin: 1rem 0rem;
  }

  .viaje-curso-tabla button{
    font-size: 11px;
  }

  .title_table_itinerario {
    background: #12406F!important;
    color: #fff!important;
    padding: .5rem!important;
}

  @media (max-width: 768px){
    .panel_viaje_curso {
      padding: 2rem 0rem;
    }

    .content-viaje-curso table{
      font-size: 14px;
    }
    .viaje-curso-tabla button{
      font-size: 14px;
    }
    .barra1_viaje_curso label {
      font-size: 1rem;
    }
    .barra1_viaje_curso {
      justify-content: center;
  }
    .viaje-curso-tabla th, .viaje-curso-tabla td {
      padding: 2px;      
  }

  .viaje-curso-tabla tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .viaje-curso-tabla td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: transparent;
  }

  .clima_box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .clima_box li {
    font-size: 12px;
  }
  .mensaje{
    width: 100%;
  }
  .viaje-curso-tabla button {
    box-shadow: 3px 5px 15px -9px rgba(0, 0, 0, 0.2);
  }

  }



  #prueba:nth-child(odd) {
    vertical-align: inherit;
    background-color: #f7f7f7;
  }
