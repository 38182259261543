/* Header style */
.header_section{
    width: 100%;
    color: #fff;
    background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
}
.header_section_box{
    display: grid;
}
.header_section_title{
    padding: 60px 0px;
    margin-top: 8%;
}
.header_section_title h3{
    font-size: 38px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 600;
}
.header_section_title p{
    font-size: 18px;
    margin-bottom: 16px;
}

.header_section h3, .header_section_title p{
    width: 100%;
    justify-content: center;
    display: flex;
}


/* Carga */
.header_section.carga{
    width: 100%;
    color: #fff;
    background: linear-gradient(71.93deg,#333333 26.34%,#707070 56.72%,#404040 94.79%);
}

@media (max-width: 768px){
    .header_section_title{
        text-align: center;
    }
    .header_section_title h3{
        font-size: 1.5rem;
        margin-bottom: 0px;
    }
    .header_section_title p{
        font-size: 17px;
    }
}

