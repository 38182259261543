.tarifario.carga {
    background: #E8E8E8;
    padding: 6rem 0rem;
    display: block;
}
.tarifario_content_box {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: 1rem;
}
.tabla-tarifa.carga {
    width: 65%;
}
.form_contact_tarifas.carga{
    width: 32%;
    font-size: 16px;

}
.qr_box_content{
    width: 100%;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;    
    background-color: #fff;
}
.qr_box_content img{
    width: 100%;
}
.qr_box_content p{
    padding: 1rem;
    color: #7C7C7C;
    font-size: 16px;
}
.qr_box_content a{
    width: 100%;
    padding: 1rem;
    text-decoration: none;    
    font-weight: 500;
    color: #7C7C7C;
}
.zoho_form_box_content{
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    /*padding: 3rem 2.5rem;*/
    margin-bottom: 1rem;
    background-color: #fff;
}
.zoho_form_box_content h3{
    color: #7C7C7C;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.zoho_form_box_content p{
    color: #7C7C7C;
    font-size: 16px;
    margin-bottom: 10px;
}
.select_route_carga{
    width: 300px;
}
.box_select_route{
    width: 100%;
    background: #fff;
}
.select_ruta_carga{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: .5rem .5rem;
    cursor: pointer;
}
.select_ruta_carga i{
    display: flex;
}

.text_box_content_carga{
    padding: 3rem 2.5rem 2rem 2.5rem;
}
.btn_box_content_carga{
    width: 100%;
    background: #7C7C7C;
    height: 50px;
    display: flex;    
    padding: 0rem 2.5rem;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.btn_box_content_carga a{
    color: #fff;
    text-decoration: none;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.btn_box_content_carga a label i{
    display: flex;
    font-size: 20px;
}
@media (max-width: 768px){
    .tarifario_content_box {
        width: 100%;
        display: block;        
    }  
    .tabla-tarifa.carga {
        width: 100%;
    }
    .form_contact_tarifas.carga {
        width: 100%;
        font-size: 16px;
    }
    
}

@media only screen and (max-width: 992px) {
    .qr_box_content a {
        color: #7C7C7C;
        font-size: 20px;
        font-weight: 600;
    }
    .zoho_form_box_content {
        padding: 2rem 1rem;
    }
    .zoho_form_box_content p {
        font-size: 14px;
    }
    .qr_box_content {
        padding: 2rem 0rem;
    }
    .qr_box_content p {
        font-size: 14px;
    }
}