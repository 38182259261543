.box_itinerario{
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.content_itinerario {
    padding: .8rem 1rem;
}

.item_itinerario {
    width: calc(33% - 10px);
    background-color: #fff;
    box-sizing: border-box;
    padding: 1rem;
    color: #225981;
}
.box_itinerario span{
    width: 2px;
    height: 35px;
    background-color: #225981;
    align-content: center;
}
.container_itinerario{
    padding: 0rem 0rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}
.content_rutas{  
    width: 65%;
    background-color: #fff;
    padding: 1.5rem 0rem 0rem 0rem;
}

.content_itinerario h2{
    font-size: 20px;
    color: #202020;
    font-weight: 600;
    padding: 0rem 1rem;
    margin-bottom: 0rem;
}

.item_itinerario label:nth-child(1) {
    color: #3d3d3d;
    font-weight: 500;
}

.item_itinerario label:nth-child(2) {
    /*color: #225981;*/
    color: #003366;
    font-weight: 600;
    margin-left: 7px;
    font-size: 1.2rem;
}
.content_itinerario p{
    font-size: 15px;
    color: #7C7C7C;
    font-weight: 400;
    padding: 0rem 1rem;
    margin-bottom: 0rem;
}

.i4ewOd-pzNkMb-haAclf{
    display: none;
}