@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-straight/css/uicons-solid-straight.css');
.content_booking{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.box_booking{    
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-top: 10px;
    padding: 0.3rem;
    display: flex;
    flex: 1 0 auto;
    height: 55px;
}
.item_booking{
    display: grid;
    width: 100%;
    cursor: pointer;
}
.icon_booking{
    display: flex;
    align-items: center;
    padding: 0rem .5rem;
}
.icon_booking i{
    font-size: 1.5rem;
    color: #12406F;
}
.box_booking select, 
.box_booking input{
    border: none;
}
.box_booking label{
    color: #7C7C7C;
    font-weight: 400;
    font-size: 14px;
    ursor: pointer;
}
.box_booking select, 
.box_booking input{
    color: #7C7C7C;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
}
.box_booking button{
    border-radius: 3px;
    border: none;
    color: #fff;
    background: #F5A800;
    width: 100%;
    /*height: 100%;*/
    min-height: 40px;
}
.box_booking:last-child{
    border: none;
    padding: 0px;
}
.box_booking.initial,
.box_booking.initial input{
    background: #EAECEF;
}
.modalPasajero.booking{
    width: 100%;
    z-index: 9;
}
.modalAuto.booking{
    width: 100%;
    margin-top: 5px;
}