.certificaciones{
    padding: 6rem 0rem;
    background: #f1f1f1;
}
.certificaciones h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
.certificaciones p {
    font-size: 18px;
    margin-bottom: 16px;
    color: #545454;
}
.certificaciones_list{
    display: flex;
    flex-wrap: wrap;
}
.certificaciones_box{
    width: calc(25% - 10px); /* Calculamos el ancho restando el espacio deseado */
    margin: 5px; /* Agregamos un margen de 5px entre los elementos */
    background-color: #fff;
    box-sizing: border-box; /* Para incluir el padding y el borde en el ancho */
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem;
}
.certificaciones_box_img{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.certificaciones_box img{
    width: 200px;
}
.certificaciones_box label{
    font-size: 16px;
    font-weight: 500;
    margin-top: 1rem;
}
.certificaciones_box p{
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 768px) {
    .certificaciones p{
        font-size: 14px;
    }
    .certificaciones_list{
        display: block;
    }
    .certificaciones_box{
        width: 100%;
    }
    .certificaciones_box_body h2{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .certificaciones_box_body p {
        font-size: 14px;
        margin-bottom: 16px;
    }
    .text_certificaciones {
        padding: 0rem; 
    }
    .btn_certificaciones_box {
        margin-top: 0rem; 
        padding: 0px; 
        font-size: 12px;
    }
}