.fleetserices_ship{
    background: #f2f2f2;
    border-bottom: 1px solid #ddd;
}
.fleetserices{
    background: #f2f2f2;
}
.fleetserices_content{
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.fleetserices_box{
    display: flex;
    width: 100%;
    padding: 2rem;
    background: #fff;
    border-radius: 5px;
    margin-top: 2rem;
}
.fleetserices_img_box{
    width: 50%;
    display: inline-flex;    
    padding: 2rem;
}
.fleetserices_img_medico{
    width: 100%;
    justify-content: center;
    display: flex;
}
.fleetserices_img_medico img{
    width: 50%;
}
.fleetserices_img img{
    width: 100%;
    height: 100%;
}
.fleetserices_text_box{
    width: 50%;
    padding: 2rem;
}
.fleetserices_text_medico{
    width: 70%;
}
.fleetserices_text h2, .fleetserices_text_medico h2 {
    color: #545454;
}
.fleetserices_text p , .fleetserices_text_medico p{
    margin-top: 1rem;
    color: #545454;
}
.fleetserices_btn{
    margin-top: 1rem;
}
.fleetserices_medico_icons{
    display: flex;
}
.fleetserices_medico_icons p{
    font-size: 45px;
    color: #D0D0D0;
    margin-right: 1rem;
}
.fleetserices_btn button{
    padding: 12px 20px;
    background: #12406F;
    color: #fff;
    border: none;
    border-radius: 4px;
}
.fleetserices_bottom{
    background-color: #003466;
}
.fleetserices_medico{

    margin-top: -18rem;
}
.entretenimiento{
    padding-bottom: 14rem;
}

@media (max-width: 768px){
    .fleetserices_box{
        display: block;
    }
    .fleetserices_img_box, .fleetserices_text_box{
        width: 100%;
        padding: 0rem;
    }
    .fleetserices_text h2, .fleetserices_text_medico h2{
        font-size: 20px;
        margin: 1rem 0rem;
    }
    .fleetserices_text p, .fleetserices_text_medico p{
        font-size: 14px;
    }
    
}