.logo-carousel {
  margin: 20px;
  padding: 0 20px;
  margin: 0rem 1rem;
}
  .slick-slide{
    display: inline-flex;
  }
  
  .logo-carousel img {
    display: inline-flex;
    max-width: 65%;
    height: auto;
    margin: 0 auto;
  }
  
  .slick-prev:before, .slick-next:before{
    color: #bbb!important;
  }