.valoracion{
    position: fixed;
    right: 0;
    justify-content: end;
    display: flex;
    z-index: 2;
    top: 45%;
}

.box_valoracion{
    background: #3B93CD;
    width: 35px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 14px;
    color: #fff;
    transform: translate(-1%, -50%);
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
    font-size: 14px;
    opacity: .8;
}

.box_valoracion:hover{
    opacity: 1;
}
.box_valoracion:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.modal_valoracion {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 9;
}
.modal_valoracion .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
    width: 27%;
    min-width: 350px;
    max-width: 420px;

}
.modal_valoracion .cerrarbtn_modal{
    width: 100%;
    align-items: end;
    justify-content: end;
    display: flex;
    padding: 10px 10px 0px 10px;
}
.modal_valoracion .cerrarbtn_modal button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #8E8E8E;
}
.cerrarbtn_modal button i {
    font-size: 25px;
}

.boxes_valoracionform {
    display: grid;
    width: 100%;
    justify-items: center;
    margin-top: 1rem;
}

.boxes_valoracionform label{
    display: inline-block;
    margin-bottom: 0.2rem;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 6px;
}

.calificacion{
    width: 100%;
    justify-content: center;
    display: flex;
    font-size: 45px;
}
.calificacion i{
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.calificacion button{
    color: #8E8E8E;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 45px;
}
.calificacion button:hover,
.calificacion button:focus {
    color: #005B81;
}
.box_valoracionform {
    width: 100%;
    display: inline-flex;
}
.boxes_valoracionform {
    display: grid;
    width: 100%; 
}

.boxes_valoracionform textarea{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    /* min-height: 120px; */
    line-height: 24px;
    border-radius: 5px;
}
.boxes_valoracionform label {
    display: inline-block;
    margin-bottom: 0.2rem;
    font-size: 18px;
    color: #8E8E8E;
}
.boxes_valoracionform textarea {
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    /* min-height: 120px; */
    line-height: 24px;
    border-radius: 5px;
    width: 100%;
}

.btn_valorar{
    padding: 10px 19px 9px;
    color: #fff;
    background: #005B81;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-flex;
    text-decoration: none;
    justify-content: center;
    margin-bottom: 2rem;
    cursor: pointer;
    width: 100%;
}
.btn_valorar:disabled {
    background-color: #707B80;
}

@media (max-width: 768px){
    .contentbox_contactform {
        padding: 0rem 0rem;
    }
}