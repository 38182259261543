
/* contador */
.box_contador{
    width: 100%;
    background-color: aquamarine;
}
.box_flota{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    background: #00274A;
    padding: 1rem;
    height: 250px;
    color: #fff;

}
.box_contador_flota {
    width: 33.33%;
    justify-content: center;
    align-items: center;
    display: grid;
    padding: 2rem 0rem;
    text-align: center;
}
.box_contador_flota span{
    font-size: 5rem;
    font-weight: 600;
    
}


/* seccion 1 */


.flota{
    width: 100%;
    display: flex;
     margin-top: 4rem;
}

.flota_text h2{
    font-size: 20px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}

.flota_text p{
    color: #545454;
    margin-top: 10px;
    margin-bottom: 36px;
    font-size: 16px;
}
.flota_img{
    width: 50%;
}
.flota_img img{
    width: 100%;
}


/* Flota */
.flotaBox{
    display: flex;
    width: 100%;
}
.content_box_flota{
    padding: 1px;
    width: 25%;
    position: relative;
    display: inline-block;
    text-align: center;
    /*overflow: hidden;*/
}
.content_box_flota img{
    width: 100%;
    filter: brightness(0.7);
}
.flota_text_info{
    position: absolute;
    /*top: 50%;*/
    top: 100%;
    /*transform: translate(-50%, -50%);*/
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 400;

}
.content_box_flota  .flota_text_info{
    visibility: hidden;
}
.content_box_flota:hover .flota_text_info{
    visibility: visible;
    top: 50%;
    transition: top 0.5s, font-size 0.5s;
    -webkit-transition: top 0.5s, font-size 0.5s;
   

}
.box_selection_flota{
    position: absolute;
    padding: 20px;
    z-index: 5;
}
.box_selection_flota select{
    background: transparent;
    color: #fff;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #FFF;
    font-size: 20px;
}
.box_selection_flota option {     
    color:#545454;
    background-color:#FFF;
}

/* */
.flota{
    margin-top: 6rem;
}

.content_fota{
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 50px hsla(0,0%,8%,.1);
    align-items: center;
    margin-bottom: 3rem;
}
.btns_flota{
    display: grid;
}
.flota_reserva{
    background: #12406F;
    padding: 5px 20px 5px 20px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
    height: 48px;
    margin: 5px;
    font-size: 15px;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.destinos_landing{
    background-position: bottom;
}
.flota_ver_mas{
    background: #FF8F00;
    padding: 5px 20px 5px 20px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
    height: 48px;
    margin: 5px;
    font-size: 15px;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title_buque_flota {
    font-size: 22px;
    color: #575757;
    font-weight: 400;
    text-transform: uppercase;
}
.name_buque_flota {
    font-size: 26px;
    color: #575757;
    font-weight: 600;
    text-transform: uppercase;
}
.routes_buque_flota {
    color: #575757;
    font-weight: 600;
}
.content_fota i{
    margin-right: 6px;
}
.content_info_fota  p{
    margin: 8px 0px;
    font-size: 17px;
    font-weight: 600;
    color: #929393;
}
.content_fota i > img{
    width: 16px;
    
}
.content_img_fota{
    width: 33%;
    display: flex;
}
.content_img_fota img{
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 235px;
}

@media (max-width: 768px){
    .content_fota {
        display: block;
        padding: 10px;
    }
    .content_img_fota {
        width: 100%;
    }
    .content_info_fota {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .title_buque_flota {
        font-size: 20px;
        margin-bottom: 0rem;
        margin-top: 1rem;
    }
    .name_buque_flota {
        font-size: 22px;
    }
    .routes_buque_flota {
        font-size: 16px;
    }
    .content_info_fota p {
        font-size: 14px;
    }
    .flota_reserva {
        background: #12406F;
        padding: 5px 20px 5px 20px;
        height: 45px;
        font-size: 14px;
    }
    .flota_ver_mas {
        background: #FF8F00;
        padding: 5px 20px 5px 20px;
        height: 45px;
        font-size: 14px;
        text-align: center;
        justify-content: center;
    }
}
