@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-thin-rounded/css/uicons-thin-rounded.css');
.reserva_verical{
    width: 100%;
    background-color: #f5f5f5;
}
.modal_content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    transition: background-color 0.3s ease;
    z-index: 10;
    overflow-y: auto;
}
.btn_cerrar_modal_reservar{
    text-align: end;
    padding: 1rem 0rem 0rem 0rem;
}
.reservacion_modal{
    margin-top: 1rem;
    background: #fff;
}
.box_tipo_viaje{
    width: 100%;
    display: flex;
    padding: .4rem 0rem; 
}
.box_modal{
    margin: 1rem 0rem;
    padding: .2rem 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.box_ruta label{
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.box_modal input[type=date]{
    width: 100%;
    border: none;
    color: #747474;
    font-weight: 500;
}
.box_ruta input[type=text]{
    width: 100%;
    margin-bottom: .5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.box_ruta select{
    color: #747474;
    font-weight: 500;
    border: none;
}
.box_modal select{
    width: 100%;
}
.box_ruta button{
    width: 100%;
    border: none;
    padding: .5rem 0rem;
    background: #F5A800;
    color: #fff;
    border-radius: 4px;
}
.box_ruta button.disable{
    background-color: #FF8F0095;
}

.custom-button {
    background: #fff;
    border: 1px solid #747474; 
    padding: .4rem .5rem;
    margin-right: .5rem;  
    color: #005B81;
    border-radius: 4px;
}
.custom-button.active-button {
    background-color: #747474;
    border: 1px solid #747474;
    color: #fff;
}
.cupon_reserva_vertical {
    text-align: center;
    color: #000;
    margin-top: .8rem;
}
.cupon_reserva_vertical label{
    font-size: 14px;
    color: #747474;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cupon_reserva_vertical i{
    display: flex;
    margin-left: 4px;
}
.box_reserva_vertical{
    padding: 2rem 0rem;
}
.btn_reservar_vertical{
    padding: 8px 0px;
    background: #FF8F00; 
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 16px;
}
      
.box_reserva_vertical p{
    margin-bottom: .5rem;
    font-size: 16px;
    color: #545454;
}

