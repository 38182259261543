.abordo_al_bolante{
    padding: 6rem 0rem;
}

.abordo_al_bolante h3{
    margin-top: 1rem;
    color: #545454;
}
.abordo_al_bolante strong{
    color: #1a1a1a;
    font-weight: 600;
}
.abordo_al_bolante ul li{
    list-style: disc;
}