@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
.info_util{
    display: flex;
}
.message_antes_viajar{
    margin-top: 4rem;
}
.message_antes_viajar h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
    color: #545454;
}   
.message_antes_viajar p{
    font-size: 18px;
    letter-spacing: 0;
    color: #545454;
}  
.antes_viaje{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 2rem;
}
.box_antes_viaje{
    width: 33.3%;
    border: 1px solid #dbdbdb;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
    min-height: 225px;
}
.box_antes_viaje:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}
.icon_antes_viaje{
    justify-content: center;
    align-items: center;
    display: flex;
}
.tab-container i{
    padding: 7px;
}
.icon_antes_viaje img{
    width: 115px;
    margin-top: 1rem;
}
.title_antes_viaje{
    min-height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    padding: 0rem 2rem;
    justify-content: center;
}
.title_antes_viaje h3{
    color: #545454;
    font-size: 23px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
}
.info_antes_viaje {
    width: 100%;
    font-size: 18px;
    letter-spacing: 0;
    color: #545454;
    padding: 2rem 3rem;
    background-color: #fff;
}
.comprar_boleto_info .tarifas_especiales_info .mujeres_embarazadas_info{
    min-height: 500px;
}
.info_antes_viaje h4{
    color: #353535;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 1rem;
    font-weight: 500;
    /*text-align: center;*/

}
/*

.tab.active::before {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('../assets/images/chat.svg');
    position: absolute;
    z-index: 5;
    background-size: cover;

}
.tab::before {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('../assets/images/chat.svg');
    position: absolute;
    z-index: 5;
    background-size: cover;

}*/

table.mascota {
    width: 100%;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
table.mascota th{
    padding: .5rem 0rem;
    background: #EFEFEF;
    color: #333;
}
table.mascota tr:nth-child(1){
    padding: .5rem 0rem;
    background: #fff;
    color: #333;
}
.img_info_util{
    margin-top: 4rem;
}
.info_antes_viaje label a{
    color: #0075C9;
    text-decoration: underline;
}
/*
.info_antes_viaje label {
    color: #5CB2E4;
    text-decoration: underline;
}*/
.text_info_util{
    width: 60%;
}
.info_antes_viaje p{
    margin-top: 12px;
    /*color: #7C7C7C;*/
    color: #353535;
    font-weight: 500;
    margin-bottom: 1rem;
    
}
.info_antes_viaje ul{
    margin-top: 1rem;
    margin-left: 2rem;

}
.info_antes_viaje li{
    margin-top: 10px;
    color: #545454;
}
.info_visible{
    width: 90%;
    text-align: justify;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.svgarrow_back svg{
    float: right;

}
.svgarrow_back svg:hover path{
    color: #12406F;
    fill: #12406F!important;
    cursor: pointer;
}

.mascota-tabla th{
    font-size: 15px;
    color: #575757;
    text-align: center;
    background: #eee;
}
.mascota-tabla td{
    font-size: 14px;
    color: #575757;
}
.mascota-tabla tr:nth-child(2) td{
    font-size: 15px;
    color: #575757;
    font-weight: 500;
    background: #f7f7f7;
}
/*https://consulmex.sre.gob.mx/riodejaneiro/index.php/servicios-consulares/forma-migratoria-multiple*/
/* Responsivo */
@media screen and (max-width:600px){
    .antes_viaje{
        display: block;
    }
    .box_antes_viaje{
        width: 100%;
    }
}

@media screen and (max-width: 858px) and (orientation:  landscape){
  

}

.tabs_viajar {
    display: flex;
    justify-content: center;
    width: 100%;
 
}
  
.tab-container {
    display: flex;
    flex-direction: column;
    width: 27%;
    padding-top: 1.5rem;
}
.tab-boxes{
    
}
.tab_viajar {
    padding: .5rem;
    /*margin: 0px 5px 11px 5px;*/
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
    display: flex;
}
.tab_viajar:hover{
    color: #000;
}
  
.tab-boxes .active {
    /*background: #F5F5F5;*/
    color: #000;
    font-weight: 700;
    /*margin-right: -15px;*/
    /*border-radius: 3px;*/
    /*border-bottom: 2px solid #5CB2E4;*/
}
.tab_viajar.active i{
    color: #fff;
}
  
.content-container {
    width: 73%;
    margin-left: 15px;
}

.title_callToAction h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;

}
.info_antes_de_viajar{
    padding: 4rem 0rem;
    background: #F5F5F5;
    display: flex;
}
.tab_viajar.active p{
    background: #B8D1E3;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    margin-bottom: 0rem;
}