.condiciones_servicios {
    padding: 6rem 0rem;
}
.condiciones_servicios h3{
    margin-top: 1rem;
    color: #545454;
}
.condiciones_servicios p{
    margin-top: 15px;
    text-align: justify;
    color: #545454;
}

