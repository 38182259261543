#soluciones{
    background: #F1F1F1;
}
.header_soluciones{
    width: 100%;
    height: 70vh;
    position: relative;
    overflow: hidden;
}
.header_soluciones video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.header_title_soluciones{
    position: absolute;
    top: 20%;
    width: 100%;
}
.title_soluciones{
    filter: drop-shadow(2px 4px 6px black);
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #FFF;
}

.title_soluciones h2{
    font-size: 2.5rem;
    width: 100%;
    justify-content: center;
    display: flex;
}

.title_soluciones p{
    font-size: 1.3rem;
    font-weight: 500;
    width: 100%;
    justify-content: center;
    display: flex;
    text-align: center;
    padding: 0px 70px;
}

.icons_soluciones{
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon_soluciones{
    width: 10%;
    margin: 3rem;
}
.block_carga{
    background-color: #F5F5F5;
}
.servicio_transporte{
    width: 100%;
    display: flex;
    padding: 4rem 0rem;

}
.text_servicio_transporte,
.img_servicio_transporte{
    width: 50%;
    padding: 1rem;
  
}
.text_servicio_transporte h2{
    margin-bottom: 1rem;
    color: #7C7C7C;
    font-weight: 600;
    font-size: 2rem;
}
.text_servicio_transporte p{
    margin-top: 1rem;
    color: #7C7C7C;
    font-weight: 500;
    font-size: 1.2rem;
    
}
.btn_servicio_transporte{
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.btn_servicio_transporte a{
    padding: 10px 22px;
    margin-right: 10px;
    border-radius: 3px;
    color: #7C7C7C;
    background-color: transparent;
    border: 3px solid #7C7C7C;
    font-weight: 500;
    font-size: 1.1rem;
    text-decoration: none;
}
.img_servicio_transporte img{
    width: 100%;
    border-radius: 4px;
}
.container_service{
    width: 100%;
    background-color: #000;
    
    padding-top: 7rem;
    padding-bottom: 7rem;
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.9);
    margin-bottom: 10px;
}
.service_carga_bg1{
    background-image: url('../assets/images/carga/CARGA-BGI01.webp');
    background-position: center;
}  
.service_carga_bg2{
    background-image: url('../assets/images/carga/CARGA-BGI02.webp');
}
.service_carga_bg3{
    background-image: url('../assets/images/carga/CARGA-BGI03.webp');
    background-position: center;
}

.service_content_carga{
    display: flex;
    justify-content: end;
}
.service_box{
    width: 40%;
    display: grid;
    color: #FFF;
    width: 42%;
    display: grid;
    color: #FFF;
    background-color: rgba(0,0,0,.5);
    padding: 2rem;
}
.service_box h2{
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
}
.service_box p{
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}
.service_box_list{
    display: grid;
    margin-bottom: 1rem;
}
.service_box  label{
    font-weight: 500;
    font-size: 1.1rem;
}
.service_box a{
    padding: 10px 22px;
    margin-right: 10px;
    border-radius: 3px;
    color: #7C7C7C;
    background-color: #F2F2F2;
    border: 3px solid #F2F2F2;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    text-decoration: none;
}
.service_box button{
    padding: 10px 22px;
    margin-right: 10px;
    border-radius: 3px;
    color: #7C7C7C;
    background-color: #F2F2F2;
    border: 3px solid #F2F2F2;
    font-weight: 500;
    font-size: 1.1rem;
}
.heroImg_carga{
    display: none;
}

/**/

/* Rutas y Horarios */
.cta_carga{
    width: 100%;
    padding: 8rem 0rem;
    /*background:linear-gradient( to right,#2D5B7B 0%,#2D5B7B 50%,#D4EDFF 50%, #D4EDFF 100%);*/
    background: linear-gradient(to right, #404040 0%, #404040 50%, #d3d3d3 50%, #d3d3d3 100%);
    margin-top: -4rem;
}
.cta_container_carga{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cta_box_carga{
    transition: 0.3s;
    width: 49%;
    border-radius: 5px;
    min-height: 230px;
}
.cta_box_carga:nth-child(1){
    /*background-color: #005B81;*/
}
.cta_content_carga {
    display: flex;
    padding: 1.5rem 1rem;
    height: 100%;
}
.cta_info_carga{
    align-content: space-between;
    display: grid;
}
.cta_img_carga{
    width: 22%;
}
.cta_box_img_carga{
    width: 100%;    
    background: #F5A800;
    padding: 1rem;
    border-radius: 50%;
}
.cta_box_carga:last-child .cta_box_img_carga{
    background-color: #404040;
}
.cta_img_carga img{
    width: 100%;
}
.cta_text_carga {
    width: 100%;
    padding: 0rem 1rem;
    font-weight: 500;
}
.tarifa .cta_text_carga h2{
    color: #404040; 
    font-size: 26px;
    font-weight: 500;
}
.ruta-horario .cta_text_carga h2{
    color: #F5A800;
    font-size: 26px;
    font-weight: 500;
}
.tarifa.cta_text_carga p {
    color: #545454;
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 16px;
}
.ruta-horario .cta_text_carga p{
    color: #F5A800;
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 16px;
}

.cta_btn_carga {
    padding: 0rem 1rem;
    height: 50px;
    align-content: center;
}
.cta_btn_carga a{
    padding: 10px 15px;
    color: #404040 ;
    background: transparent;
    border: 2px solid #404040 ;
    border-radius: 5px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    text-decoration: none;
    font-weight: 500;
}
.cta_box_carga:nth-child(1) a{
    color: #F5A800;
    background: transparent;
    border: 2px solid #F5A800;
}
/* */
.box_carga_info{
    display: grid;
    text-align: center;
    padding: 1.5rem;
}
.box_carga_info label{
    font-size: 20px;
    font-weight: 500;
    color: #444444;
}
.box_carga_info_icon{
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
}
.box_carga_info i{    
    background: #404040;
    color: #fff;
    font-size: 2rem;
    height: 80px;
    padding: 1rem;
    width: 80px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
/**/

.services_carga{
    padding: 4rem 0rem;
    background: #404040;
}
.services_carga_content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.services_carga_content a{
    width: calc(25% - 10px);
    padding: 1rem;
    text-decoration: none;
}
.services_carga_box{
    width: 100%;
    background-color: ghostwhite;
    margin: 1px;
}

.services_carga_box_img{
    width: 100%;
}
.services_carga_box_img img{
    width: 100%;
}
.services_carga_box h3{
    font-size: 22px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    background: #404040;
    padding: 1rem 0rem;
}
/* Maritimo, Terrestre y multimodal */
.servicio_carga_content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.servicio_carga_box{
    width: 49%;
}
.servicio_carga_box h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.servicio_carga_box p{
    font-size: 16px;
    margin-bottom: 1rem;
    color: #545454;
}
.servicio_carga{
    width: 100%;
    padding: 6rem 0rem;
}
.servicio_carga:nth-child(even){
    background: #F1F1F1;
}

.btn_carga_rodada{
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    text-align: start;
}
.btn_carga_rodada a {
    padding: 10px 22px;
    margin-right: 10px;
    border-radius: 3px;
    /*color: #7C7C7C;*/
    color: #fff;
    background-color: transparent;
    border: 3px solid #fff;
    font-weight: 500;
    font-size: 1.1rem;
    text-decoration: none;
}


.servicio_carga_rodada {
    width: 100%;
    position: relative;
}
.servicio_carga_rodada_content {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: -4rem;
    background: #f1f1f1;
    padding: 2rem;
    border-radius: 5px;

    background-image: url('../assets/images/soluciones/BGI-ferrycarga.webp');
    background-size: cover;
    background-position: center;
}
.servicio_carga_rodada_box {
    width: 49%;
}
.rodada {
    display: grid;
    align-content: center;
    color: #fff;
}
.servicio_carga_rodada_box img {
    width: 100%;
    border-radius: 3px;
}


@media (max-width: 952px) {
    .cta_img_carga {
        width: 35%;
    }
    .cta_box_img_carga {
        padding: 5px;
    }
    .servicio_carga_box img {
        height: 100%;
    }
    .services_carga_content{
        display: flex;
        flex-wrap: wrap;
    }
    .services_carga_content a {
        width: calc(50% - 10px);
        margin: 5px;
    }
}
@media (max-width: 768px) {
    .header_soluciones{
        height: auto;
    }
    .header_soluciones video{
        display: none;
    }
    .heroImg_carga {
        display: block;       
    }
    .heroImg_carga img{
        width: 100%;
    }
    .servicio_transporte{
        display: block;
    }
    .text_servicio_transporte, .img_servicio_transporte{
        width: 100%;
    }
    .service_box{
        width: 100%;
    }
    .header_title_soluciones {
        top: 13%;
    }

    /* Maritimo, Terrestre y multimodal */

    .servicio_carga_content{
       /* display: block;*/
        padding: 0rem 0rem;
    }
    .servicio_carga_box{
        width: 100%;
        margin: 1rem 0rem;
    }
    .servicio_carga_box h2 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
    }
    .servicio_carga_box p{
        font-size: 14px;
        margin-bottom: 1rem;
    }
    .servicio_carga:nth-child(odd) {
        background-color: #f2f2f2;
    }
    .servicio_carga{
        width: 100%;
        margin-top: 0rem;
        padding: 0;
        padding-top: 4rem;
    }

    .cta_carga{
        padding: 2rem 0rem;
        background: #f5f5f5;
    }
    .cta_img_carga {
        width: 40%;
    }
    .cta_container_carga {
        display: block;
        padding: 4rem 0rem;
    }
    .cta_box_carga {
        width: 100%;
        margin-bottom: 1rem;
        min-height: 100px;
    }
    .tarifa .cta_text_carga h3,
    .ruta-horario .cta_text_carga h3{
        font-size: 18px;
    }
    .ruta-horario .cta_text_carga p,
    .tarifa .cta_text_carga p {
        font-size: 14px;
    }

    .cta_box_img_carga{
        max-width: 98px;
        padding: 10px;
    }
    .services_carga_content a {
        width: calc(100% - 10px);
        margin: 5px;
    }
    .servicio_carga_rodada_box {
        width: 100%;
    }
    .cta_box_carga:nth-child(1){
        background-color: #404040;
    }
    .cta_box_carga:nth-child(2){
        background-color: #d3d3d3;
    }

    .sorteo {
        width: 100%;
        /* padding: 6rem 0rem; */
        background: transparent!important;
    }
   
}

@media (max-width: 480px){
    .header_soluciones video{
        display: none;
    }
}