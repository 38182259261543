.horizontal_form{
    display: flex;
}
.booking_horizontal{
    width: 100%;
    align-items: center;
    display: flex;
    height: 100%;
}
.formBooking_horizontal{
    width: 100%;
    justify-content: center;
    display: flex;
}

/* Contents Booking */

.contentBooking_horizontal {
    display: inline-flex;
}

/* Origen */

.box_formOrigen_horizontal, 
.box_formDestino_horizontal,
.box_fechaSoloIda_horizontal {
    padding: 1px 15px;
    background: #fff;
    border: none;
    border-radius: 4px;
    /*border-right: 1px solid #767676;*/
    height: 45px;
    margin-left: 1%;
    margin-right: 1%;
    width: 220px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.box_formButton_horizontal {
    align-items: center;
    display: flex;
}
.box_formButton_horizontal button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
}
.box_formOrigen_horizontal select,
.box_formDestino_horizontal select {
    /*height: 45px;*/
    width: 100%;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    font-size: 16px;
    color: #7C7C7C;
}
/* Destino */

/* Fechas */
.box_fechaSalida_horizontal,
.box_fechaRegreso_horizontal {
    padding: 1px;
    background: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 1%;
    margin-right: 1%;
    align-items: center;
    display: flex;
}

.box_fechaSalida_horizontal input, 
.box_fechaRegreso_horizontal input, 
.box_fechaSoloIda_horizontal input {
    width: 100%;
    padding: 0px 20px;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    font-size: 16px;
    color: #7C7C7C;
}
.box_formOrigen_horizontal label, .box_formDestino_horizontal label, 
.box_fechaSalida_horizontal label, .box_fechaRegreso_horizontal label, 
.box_fechaSoloIda_horizontal label {
    display: block;
    color: #141414;
    padding: 0px 20px;
    margin-top: 4px;
    margin-bottom: 0px;
}


/* Switch */

.switchBooking{
    display: flex;
    align-items: center;
    margin-right: 1%;
    margin-left: 2%;
}
.switchBooking p {
    color: #fff;
    width: 100px;
    margin-left: 18px;
}
.checkbox_type {
    display: none;
}
  
.slider_switch {
    /*width: 60px;
    height: 30px;*/
    width: 56px;
    height: 26px;
    background-color: lightgray;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 4px solid transparent;
    transition: .3s;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}

.slider_switch::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(-30px);
    border-radius: 20px;
    transition: .3s;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox_type:checked ~ .slider_switch::before {
    transform: translateX(30px);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox_type:checked ~ .slider_switch {
    background-color: #2196F3;
}

.checkbox_type:active ~ .slider_switch::before {
    transform: translate(0);
}

.switchBooking p{
    color: #fff;
}

/* Btn Reservar */
.btnBuscar_horizontal button {
    height: 45px;
    width: 100%;
    border-radius: 4px;
    border: none;
    color: #fff;
    z-index: 5;
}

button.btnBuscar_horizontal {
    background: #FF8F0095;
    border-radius: 4px;
}

.btnBuscar_horizontal button:hover {
    background: #FF8F00;
}
.box_formButton_horizontal svg{
    width: 100%;
    height: 100%;
}


/* Btn Enviar */

.textPlaceholder_horizontal {
    /* position: absolute; */
    display: flex;
    color: #fff;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    height: 100%;
    width: 115px;
}
.textPlaceholder_horizontal svg{
    width: 25px;
}

/* */

option:disabled {
    color: -internal-light-dark(graytext, rgb(170, 170, 170));
}