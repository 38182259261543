.box_form_grupo{
    background: #f5f5f5;
    padding: 4rem 0rem;
}
.formulario_contacto{
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.formulario_contacto_title{
    width: 100%;
}
.formulario_contacto_text{
    width: 75%;
}
.formulario_contacto h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 1rem;
    font-weight: 400;
    color: #707070;
}
.formulario_contacto p{
    font-size: 18px;
    margin-bottom: 1rem;
    color: #545454;
}
.form_contact form {
    width: 60%;
    margin-top: 1rem;
}
.contentbox_contactform{
    width: 100%; 

}
.form_contact{
    display: flex;
}
.box_contactform{
    width: 100%;
    display: inline-flex;
    margin-top: 10px;
}
.boxes_contactform{
    display: grid;
    width: 100%;
    margin-right: 40px;
    margin-top: 10px;
}
.boxes_contactform label{
    display: inline-block;
    margin-bottom: 0.2rem;
    font-size: 15px;
    color: #7B7B7B;
}
.boxes_contactform input[type=text]{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 45px;
    line-height: 24px;
    border-radius: 5px;
    width: 100%;
}
.boxes_contactform input[type=checkbox]{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 18px;
    line-height: 24px;
    border-radius: 5px;
    margin-right: 5px;
    width: 18px;
}
.boxes_contactform select{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 45px;
    line-height: 24px;
    border-radius: 4px;
}
.checkbox_telefono{
    display: flex;
    align-items: center;
}
.checkbox_telefono label{
    font-size: 14px;
    margin-bottom: 0rem;
}
.boxes_contactform textarea{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    /*min-height: 120px;*/
    line-height: 24px;
    border-radius: 5px; 
}
/*
.custom-input::placeholder {
    font-size: 16px; /* Cambia el tamaño del placeholder /
    color: #999;    /* Cambia el color del placeholder /
    
}
*/
.box_content-btn{
    width: 100%;
    margin-top: 14px;
}

.box_content-btn button{

/* padding: 8px 11px;*/
    padding: 10px 19px 9px;
    color: #fff;
    /*background: #00155F;*/
    background:#002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-flex;
}

.box_content-btn button:hover{
    background: #FF8F0095;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}


/* */
.localizar_punto{
    width: 40%;
    margin-top: 2rem;
}

.box_content_busqueda_punto{

}

.busqueda{
    width: 100%;
    display: flex;
}

.busqueda input[type=text]{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 45px;
    line-height: 24px;
    border-radius: 4px 0px 0px 4px;
    width: 100%;
    border-right: none;
}
.busqueda input::placeholder {
    font-size: 16px; /* Cambia el tamaño del placeholder */
    color: #999;    /* Cambia el color del placeholder */
    /* Otros estilos de placeholder si es necesario */
}

.box_content_busqueda_punto button{
   /* padding: 8px 11px; */
    padding: 10px 19px 9px;
    color: #fff;
    /* background: #00155F; */
    background: #002648;
    border-color: transparent;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-flex;
    cursor: pointer;
    align-items: center;
}


    

    