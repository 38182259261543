.horizontal-scroll-container {
    /*  width: 300px;*/ /* Establece el ancho del contenedor */
      width: 100%;
      overflow-x: auto; /* Hace que el desbordamiento horizontal sea visible solo si es necesario */
      white-space: nowrap; /* Evita el retorno de línea para que los elementos se muestren en una línea */
    }
    .horizontal-scroll-container::-webkit-scrollbar {
      display: none;
    }
    .scroll-content {
      display: flex; /* Hace que los elementos internos se muestren en una fila */
    }
    .scroll-item {
      width: 89%;
      /*min-width: 120px; *//* Establece el ancho mínimo de cada elemento */
      min-width: 89%;
      margin-right: 10px; /* Añade un margen entre los elementos (opcional) */
    }
    .box_destino_phone{
      width: 100%;
    }
    .box_destino_phone p{
      width: 100%;
      white-space: normal;
      font-size: 14px;
      margin-bottom: 0px;
      
    }
  
    .box_destino_text_phone p:nth-child(2){
      font-size: 12px;
      color: #545454;
    }
    .box_destino_text_phone{
        padding: 5px;
        border: 1px solid #ccc;
        min-height: 120px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .scroll-item img{
      width: 100%;
      /*height: 160px;*/
      /*height: 220px;*/
      min-height: 185px;
      border-radius: 3px;
    }
    #destinos {
        margin-top: 2rem;
    }
    .promociones_title_home_phone{
        display: flex;
        justify-content: space-between;
    }
    .promociones_title_home_phone button{
        border: none;
        background-color: transparent;
    }

    .text_btn_scroll{
      display: flex;
    }
    .btn_scroll{
      align-content: center;
    }
