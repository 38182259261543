@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css');

.ruta_horario{
    background: #EEE;
    padding-bottom: 6rem;
}
.content_select_ruta_horario{
    width: 100%;
    padding-top: 4rem;
}
.box_select_ruta_horario{
   /* display: inline-grid;*/
   width: 49%;
   background-color: #fff;
   padding: .5rem;
   cursor: pointer;
   border: 1px solid #ddd;
   border-radius: 3px;

}
.icon_select_ruta_horario{
    /*width: 40px;*/
    width: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.icon_select_ruta_horario i.fi.fi-ss-ship {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px;
}
.icon_select_ruta_horario .i.fi.fi-rr-caret-square-down {
    font-size: 20px;
    color: #002648;
}
.icon_select_ruta_horario  .fi-ss-ship:before {
    color: #002648;
    width: 100%;
    font-size: 25px;
    
}
.icon_select_ruta_horario  .fi-rr-caret-square-down:before {
    color: #002648;
    font-size: 20px;
}
i.fi.fi-rr-caret-square-down {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.select_ruta label:nth-child(1) {
    color: #7C7C7C;
    font-weight: 500;
    font-size: 14px;
}
.select_ruta label:nth-child(2) {
    color: #7C7C7C;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.select_ruta_horario_box_content .select_ruta label:nth-child(2) {
    color: #002648;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.select_ruta label i{
    display: flex;
    margin-left: 3px;
}
.select_ruta_horario{
    display: flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;
    padding: 3px 5px;
}
.select_ruta_horario_box_content {
    display: flex;
    cursor: pointer;
  /*  border-bottom: 1px solid #ddd;*/
    border-radius: 3px;
    background-color: #fff;
    padding: 3px 0px;
}
.select_ruta{
    display: grid;
    margin-left: 5px;
    cursor: pointer;
}
.optionListRoutes{
    width: auto;
    height: auto;
    position: absolute;
    z-index: 7;
    background-color: #fff;
    border-top: 2px solid #7C7C7C;
    padding: 3px 6px;
    border: 1px solid #ddd;
    border-radius: 3px;
    max-width: 289px;
    width: 100%;
    /*overflow-y: scroll;*/
}
.optionRoutes{
    color: #7C7C7C;
    margin: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
}
.box_content_ruta_horario{
    width: 65%;
}
.semana_ruta_horario{
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-size: 15px;
}
.semana_ruta_horario i{
    color: #12406F;
    margin-right: 8px;
    font-size: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.content_semana_ruta_horario{
    background: #EEEEEE;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #7C7C7C;
    font-weight: 500;
}
.routes_schedules{
    width: 100%;
    display: flex;
}
.tabs_routes_schedules {
    display: block;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
   
}
  
.tab-container-routes-schedules {
    display: flex;
}
  
.tabRutas {
    padding: .5rem;
    margin: 0px 5px 5px 0px;
    cursor: pointer;
    /*background: #EEE;*/
    background: #FFF;
    font-size: 18px;
    font-weight: 400;
    color: #707070;
    /*
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    */
    justify-content: center;
    align-items: center;
    display: flex;
}
.tabRutas.activo {
    /*background: #F5F5F5;*/
    background: #FFF;
    color: #12406F;
    font-weight: 600;
}
i.fi.fi-rr-angle-small-right {
    justify-content: center;
    align-items: center;
    display: flex;
}
.tabRutas.activo .i.fi.fi-rr-angle-small-right{
    background: #F5F5F5;
    color: #12406F;
    font-weight: 600;
}
.tabRutas  .i.fi.fi-rr-angle-small-right {
    justify-content: center;
    align-items: center;
    display: flex;
}
.content-container-ruta_horario {
    width: 100%;
    background: #FFF;
    height: 500px;
}
.title_callToAction h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}

.info_ruta {
    width: 100%;
    font-size: 18px;
    letter-spacing: 0;
    color: #545454;
    padding: 2rem;
}
.info_ruta h4{
    color: #003366;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
}
.list_ruta_horario{
    width: 100%;
    justify-content: center;
    display: flex;
}
.list_ruta_horario ul{
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-left: 0rem;
}
.list_ruta_horario li{
    text-align: center;
    border-bottom: 3px solid #7C7C7C;

}
.box_btn_ruta_horario{
    justify-content: center;
    display: flex;
    width: 100%;
}
.btn_ruta_horario{
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.btn_ruta_horario button{
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    background: transparent;
    color: #003366;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.btn_ruta_horario button i{
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 5px;
}
.box_content_mapa_ruta_horario{
    width: 35%;
    padding: 0px 10px;
    margin-top: 7rem;
}
.box_content_mapa_ruta_horario img{
    width: 100%;
    max-height: 330px;
}
.style_rutas_horario{
    font-size: 1.2rem;
}
.day_list{
    display: grid;
    width: 100%;
    padding: 0px 8px 0px 8px;
    cursor: pointer;
}
.diaAbreviado{
    color: #7C7C7C;
    font-weight: 500;
    font-size: 15px;
}
.numeroDia{
    color: #7C7C7C;
    font-weight: 500;
    font-size: 17px;
}
.current_day .diaAbreviado,
.current_day .numeroDia{ 
    color: #12406F;
    font-weight: 700;
}
.list_ruta_horario li .current_day{
    /*border-bottom: 3px solid #12406F;
    border-bottom: 3px solid #40D1F1;*/
}
/*
    border-bottom: 3px solid #12406F;
    border: 1px solid #12406F99;
    border-radius: 0px;
    /* background: red;

*/
.selected_day .diaAbreviado,
.selected_day .numeroDia{
   /* color: #225981;*/
   /*color: #40D1F1;*/
   color: #a51010;
}

/* Horario */
.horarios_list{
    /*width: 20%;*/
    width: 100%;
    margin: 0px 0px 0px 9px;
}

.horarios_ruta_horario{
    /*margin-top: 7rem;*/
    background: #FFF;
}
.content_horarios_list{
    width: 100%;
}
.content_horarios_list p{
    text-align: center;
}
.box_horario_list {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
}
.box_horario_list label{
    color: #7C7C7C;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.2rem;
}
.box_horario_list p{
    color: #003366;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.horario_list{
    display: flex;
    
}
.horarios_ruta_horario_title{
    font-size: 1.2rem;
    color: #003366;
    justify-content: center;
    display: flex;
    text-align: center;

}
.horarios_ruta_horario_title h4{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
}
/*
.content-container-ruta_horario,
.box_content_mapa_ruta_horario,
.horarios_ruta_horario{
    min-height: 340px;
}*/
.select_ruta_horario,
.optionListRoutes{
    min-width: 220px;
    max-width: 275px;
}
.info_ruta_title_services{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.info_ruta_title{
    width: auto;
}
.info_ruta_services{
    width: auto;
    align-items: center;
    display: flex;
}
.info_ruta_services h3{
    color: #003366;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1rem;
}
.info_ruta_services i{
    display: flex;
    margin-left: 3px;
    margin-top: 4px;
}
.list_ruta_services{
    display: flex;
    width: 100%;
    justify-content: end;
}

.icons_ruta_services{
    height: auto;    
}
.icon_ruta_services img{
    width: 50px;
}
.icon_ruta_services p{
   font-size: 12px;
   color: #7C7C7C;
}

.mostrar_servicios{
    cursor: pointer;
}

.icon_ruta_services_img{
    justify-content: center;
    display: flex;
}
.icon_ruta_services {
    padding: 5px;
    justify-content: center;
    display: grid;
}
i.fi.fi-ss-ticket {
    font-size: 22px;
    /*color: #002648;*/
}

@media (max-width: 768px) {
    .box_content_ruta_horario {
        width: 100%;
    }
    .info_ruta_title span{
        font-size: .5em;
    }
    .info_ruta_title span:nth-child(1){
        font-size: .8em;
    }
    .info_ruta_title span:nth-child(2){
        font-size: .8em;
    }
    .list_ruta_horario ul{
        font-size: 12px;
        padding-left: 0px;  
    }
    .day_list {
        padding: 0px 8px 0px 8px;
    }
    .diaAbreviado {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 10px;
    }
    .numeroDia {
        color: #12406F;
        font-weight: 500;
        font-size: 10px;
    }
    .semana_ruta_horario{
        display: flex;
    }
    .semana_ruta_horario button{
        border: 1px solid #003366;
        background: #225981;
        color: #ffffff;
        border-radius: 3px;
    }
    .semana_ruta_horario button{
        font-size: 10px;
        padding: 5px;
        justify-content: center;
        display: flex;
    }
    .semana_ruta_horario i{
        color: #ffffff;
        font-size: 12px;        
    }
    .content_semana_ruta_horario {
        color: #003366;
    }
    .routes_schedules {
        width: 100%;
        display: block;
        background: #fff;
    }
    .info_ruta {
       padding: 0px;
    }
    .btn_semana_ruta_horario{
        width: 60%;
        padding: 10px 10px;
    }
    .btn_semana_ruta_horario button{
        font-size: 11px;
        padding: 8px 4px;
        justify-content: space-between;
        width: 100%;
    }
    .btn_semana_ruta_horario i{
        display: flex;
        align-items: center;
    }
    .list_ruta_horario ul {
        background: #F2F2F2;
        border-radius: 3px;
    }
    .current_day span.numeroDia {
        color: #fff;
        font-weight: 700;
        background: #12406F;
        border-radius: 25px;
        width: 20px;
        height: 20px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .list_ruta_horario li, .list_ruta_horario li .current_day {
        border-bottom: none;
    }
    .list_ruta_horario li {
        padding: 7px 0px;
    }
    .content-container-ruta_horario{
        min-height: auto;
    }
    .horarios_list {
        width: 100%;
        margin: 0px;
    }
    .horarios_ruta_horario {
        margin-top: 0rem;
    }
    .box_horario_list {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin-top: 8px;
    }
    .box_horario_list p{
        margin-bottom: 0rem
    }
    .box_horario_list p {
        font-size: 1rem;
        font-weight: 600;
    }

    .horarios_ruta_horario_title {
        font-size: 1rem;
        color: #003366;
        justify-content: start;
        display: flex;
    }

    .horarios_ruta_horario {
        min-height: auto;
    }
    .box_content_mapa_ruta_horario {
        margin-top: 1rem;
        width: 100%;
    }
    .box_content_mapa_ruta_horario {
        padding: 0px 0px;
    }
    .info_ruta_services {
        display: block;
    }
    .info_ruta_services h3 {
        border: 1px solid #12406F;
        border-radius: 4px;
        margin-top: 1rem;
    }
    .box_content_mapa_ruta_horario img {
        border-radius: 3px;
    }
    .box_horario_list label {
        font-size: 1rem;
    }
    .tabs_routes_schedules {
        margin-bottom: 0rem;
    }
    .horarios_ruta_horario_title h4 {
        margin-top: 0rem; 
    }
    .content_semana_ruta_horario{
        background: transparent;
        font-size: 12px;
    }

}
  