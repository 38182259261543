@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-brands/css/uicons-brands.css');


.noticia_container{
    width: 100%;
    background: #f1f1f1;
    padding: 6rem 0rem;
}
.opc_regresar{
    margin-top: 1rem;
}
.opc_regresar a{
    text-decoration: none;
    color: #545454;
}
.tag_line{
    display: flex;
    color: #545454;
    margin-top: 1rem;
    font-size: 14px;
}
.tag_line p{
    margin-right: 12px;
}
.content_noticia_nota{
    display: flex;
    background-color: #fff;
}
.nota_content{
    width: 70%;
    padding: 2rem 2rem 2rem 3rem;
}
.lateral_box{
    width: 30%;
    padding: 2rem 3rem 2rem 0rem;
}
.nota_content h1{
    color: #545454;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 22px;
    font-weight: 600;
    text-transform: math-auto;
}
.nota_content h1, .nota_content p, .nota_content li {
    color: #545454;
}
.nota_content p{
    margin-bottom: 0rem;
    text-align: justify;
}
.nota_content ul{
    margin-left: 1.5rem;
}
.nota_content li{
    list-style: disc;
}

.noticias_box_prensa h2, .noticias_box_redes h2{
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 400;
    color: #545454;
    text-align: center;
}
.noticias_box_prensa p{
    color: #545454;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0rem;
}
.box_prensa{
    margin-top: 10px;
}
.noticias_box_redes ul{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.noticias_box_redes ul a{
    text-decoration: none;
    border-radius: 50%;
    border: 1px solid #225981;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}
.noticias_box_redes ul a i{
    color: #225981;
    font-size: 25px;
    padding: 10px;
    display: flex;
}

.noticias_box_newsletter {
    margin-bottom: 1rem;
}

.nota_content_img_header img{
    width: 100%;
    border-radius: 5px;
}
.noticias_box_redes {
    background: #f1f1f1;
    padding: 1rem;
    border-radius: 5px;
}
.noticias_box_newsletter {
    background: #f1f1f1;
    margin: 2rem 0rem;
    padding: 1rem;
    border-radius: 5px;
}
.noticias_box_prensa {
    background: #f1f1f1;
    padding: 1rem;
    border-radius: 5px;
}
@media (max-width: 768px) {
    .content_noticia_nota{
        display: block;
    }
    .nota_content{
        width: 100%;
        padding: 1rem 0rem;
    }
    .lateral_box {
        width: 100%;
        padding: 1rem;
    }
    .noticias_box_redes h2{
        text-align: center;
    }

}