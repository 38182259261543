 .content_lateral_rse{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 9;
 }
 .menu_sostenibilidad{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
 }
 .menu_sostenibilidad_content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 85px;
    padding: 0rem 4rem;
 }
 .box_menu_sostenibilidad:nth-child(1){
    background-image: url('../assets/images/LOGO-BF-BLANCO.png');    
    width: 150px;
    height: 100px;
    align-content: center;
    background-repeat: no-repeat;
    background-size: contain;
 }
 .box_menu_sostenibilidad:nth-child(2){
    justify-content: end;
    align-items: center;
    display: flex;
 }
 .box_menu_sostenibilidad img{
    width: 100%;
 }
.btn_menu_sostenibilidad{
    padding: .5rem;
    cursor: pointer;
    color: #fff;
    font-size: 28px;
}
.menu_lateral_rse {
    background: #fff;
    height: 100vh;
    top: 0;
    right: 0;
    width: 25%;
    position: absolute;
    max-width: 345px;
}
.btn_menu_lateral_rse{
    padding: 1.5rem 2rem;
    justify-content: end;
    align-items: center;
    align-content: center;
    width: 100%;
    display: flex;
}
.btn_menu_lateral_rse i{
    color: #545454;
    cursor: pointer;
}
.list_menu_lateral_rse{
    color: #545454;
}
.list_menu_lateral_rse li{
    font-size: 16px;
    padding: .5rem 0rem;
    cursor: pointer;
}
.list_menu_lateral_rse li a{
    color: #545454;
    text-decoration: none;
}
/*
{
    background: transparent;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #37373790, rgba(40, 40, 40, 0));
    position: absolute;
    z-index: 9;
}
*/
@media (max-width: 768px){

}

 