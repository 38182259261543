.chartering, .feedering, .proyecto{
    display: grid;
    align-content: center;
}

.servicio_carga_box img{
    width: 100%;
    border-radius: 3px;

}


.box_info_carga{
    display: flex;
}
.box_info_carga i{
    background: #333;
    padding: 1rem;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    margin-right: 1rem;
    font-size: 20px;
}
.box_info_carga label{
    font-size: 20px;
    font-weight: 500;
    color: #444444;
}


/* Header carga */
.header_carga.multimodal{
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/images/carga/CARGA-BGI-MULTIMODAL.webp');
    padding: 8rem 0rem 4rem;
    background-size: cover;
}
.multimodal h2, .multimodal p{
    color: #fff;
}

.multimodal .servicio_carga_box{
    width: 75%;
}