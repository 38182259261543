@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
.hero{
    width: 100%;
    /*margin-bottom: 35px;*/
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;    
    position: relative;
}
.hero video{
    width: 100%;
    height: auto;
    display: block;
    min-height: 155px;
    position: relative;
    display: inline-block;
}
.heroImg{
    display: none;
}
.hero_text{
    position: absolute;
    top: 20%;
    width: 100%;
}
.content_hero_text{
    display: flex;
    justify-content: end;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 400;
    
}
.box_hero_text:nth-child(2){
    margin-left: 10px;
}

.box_hero_text label{
    font-weight: 400;
    justify-content: end;
    display: flex;  
    opacity: 0.8;
    line-height: 45px;
}

.boxBooking{
    position: absolute;
    top: 65%;
    /*top: 59%;*/
    width: 100%;
}

.box_formOrigen select::-ms-expand {
    display: none; /*Evita que se muestre la flecha por defecto en versiones de IE*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;    
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.015em!important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.box_controlAudio{
    cursor: pointer;
    position: absolute;
    display: flex;
    background: transparent;
    border: none;
    align-items: center;
    /*
    bottom: 20%;
    right: 25%;
    */
    /*
    bottom: 3%;
    right: 1%;
    */
    bottom: 7px;
    right: 2%;
    z-index: 9;
  }
  .controlAudio{    
    background: rgba(0, 0, 0, .5);
    border-radius: 25px;
    width: auto;
    min-width: 400px;
    height: 45px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    color: #fff;
    padding: 0rem 1rem;
  }

  .box_controlAudio label{
    font-size: 14px;
    color: #d3d3d3;
    font-style: italic;

  }
 
  .controlAudio input[type=range]{
    height: 3px;
    width: 100px;
    display: flex;
  }
  .controlAudio i{
    display: flex;
  }

  
@media only screen and (max-width: 320px) {
    .content_hero_text {
        font-size: 4.2vw;
       
    }
}
@media (max-width: 768px){
    .hero_text {
        top: 22%;
    }
    .content_hero_text {
        font-size: 4.2vw;
        font-size: 6vw;
        justify-content: center;
    }
    .box_hero_text label{
        line-height: 35px;
    }

    
    

}