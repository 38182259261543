.tarifario{
    background: #f5f5f5;
    padding-bottom: 6rem;
    display: block;
}
.tarifario th{
    color: #12406F;
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 0px;
    padding: 1rem 1rem;
}
.tarifario td{
    font-weight: 500;
    font-size: 16px;
    color: #7C7C7C;
    padding: .5rem 1rem;
}

.tarifario p{
    font-weight: 500;
    font-size: 12px;
    color: #7C7C7C;
    margin-bottom: 0rem;
}
.content-tabla-tarifa{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0rem;
}
.tabla-tarifa{
    width: 49%;    
}
.tabla-tarifa table{
    width: 100%;
    background: #fff;
}
.tabla-tarifa table th:nth-child(2),
.tabla-tarifa table td:nth-child(2){
    text-align: end;
}
.tarifario .tbody{
    border-radius: 4px;
}
.tarifario .tbody, .tarifario tr{
    border: 1px solid #ddd;
}
.tabla_grid {
    background: #2D5B7B;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    padding: 2.5rem;
}
.tabla_grid_button{
    margin: 2rem 0rem;
}
.tabla_grid a{
    border: 2px solid #fff;
    padding: 8px 12px;
    text-decoration: none;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;

}
.tabla_grid.img{
    width: 100%;
    height: auto;
    max-height: 142px;
    background-image: url('../assets//images/tarifas/box-tarifa-carga.png');
    background-repeat: no-repeat;
}
@media (max-width: 768px){
    .content-tabla-tarifa{
        display: block;
        margin: 1rem 0rem;
    }
    .tabla-tarifa {
        width: 100%;
        margin-bottom: 1rem;
    }
    .tarifario .box_select_ruta_horario{
        width: 100%;
    }
   
}
