
.promo_container{
    width: 100%;
    display: flex;
    margin-top: 4rem;
}
.promociones_zone{
    background: #E8E8E8;
}
.promociones_zone_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 4rem 0rem;
}

.promociones_zone_box{
    /*width: 32%;    */
    width: calc(33% - 10px);
    margin: 5px;
    /*background: #fff;*/
    padding: .5rem;
    border-radius: 5px;
    display: grid;
}
.promociones_zone_box img{
    width: 100%;
    border-radius: 4px;
}
.promociones_zone_content{
    padding: .5rem 0rem;
    justify-content: space-between;
    align-content: space-between;
    display: grid;
    height: 100%;
    min-height: 200px;
}
.promociones_zone_box h1{
    font-size: 1.2rem;
    font-weight: 500;
}
.promociones_zone_content a{
    width: 100%;
    background: #FF8F00;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
}

.viajeEnGrupo-section{
    background: linear-gradient(to bottom, #E8E8E8 50%, #fff 50%);
}
.viajaEnGrupo-cta{
    width: 100%;
    display: flex;
    background:#fff;
    border-radius: 8px;
    box-shadow: 0 0 50px hsla(0, 0%, 8%, .1);
}
.viajaEnGrupo-cta img{
    width: 100%;    
    border-radius: 8px;
    height: 100%;
}
.viajaEnGrupo-cta-img{
    width: 60%;
    padding: 2rem;
}
.viajaEnGrupo-cta-text{
    display: grid;
    width: 40%;
    padding: 2rem;
}

.viajaEnGrupo-cta-text a{
    background: #FF8F00;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
    width: 100%;
    display: block;
    height: 42px;
    text-align: center;
    align-content: center;
}
.promo1{
    width: 100%;
    background: pink;
    padding: 1rem;
    margin: .5rem;
    background-image: url('../assets/images/promociones/transportamas.webp');
    background-size: cover;
    height: 270px;
    border-radius: 4px;
    cursor: pointer;
}
.promo_container a{
    width: 32.2%;
    text-decoration: none;
    margin: .5rem;
    height: 270px;
    cursor: pointer;
}
.promo2{
    width: 100%;
    background: pink;
    padding: 1rem;
    margin: .5rem;
    background-image: url('../assets/images/promociones/mascota-p.webp');
    background-size: cover;
    height: 320px;
    border-radius: 4px;
    margin-top: -1.2rem;
    cursor: pointer;
}
.promo3{
    width: 100%;
    background: pink;
    padding: 1rem;
    margin: .5rem;
    background-image: url('../assets/images/promociones/planChoyero.png');
    background-size: cover;
    height: 270px;
    border-radius: 4px;
    cursor: pointer;
}
.promo4{
    width: 100%;
    background: pink;
    padding: 1rem;
    margin: .5rem;
    background-image: url('../assets/images/inapam.jpg');
    
    background-size: cover;
    height: 270px;
    border-radius: 4px;
    cursor: pointer;
}
.promo1 h2,
.promo2 h2,
.promo3 h2{
    color: #fff;
    font-size: 26px;
    text-align: center;
}

.promo1 p,
.promo2 p,
.promo3 p{
    color: #fff;
    font-size: 18px;
    text-align: center;
}


@media (max-width: 768px){

    .promociones_zone_container{
        display: flex;
        flex-wrap: wrap;
    }
    .promociones_zone_box{
        width: calc(50% - 10px);
        margin: 5px;
    }
    .viajaEnGrupo-cta{
        display: grid;
    }
    .viajaEnGrupo-cta-img{
        width: 100%;    
    }
    .viajaEnGrupo-cta-text{
        width: 100%;
    }
    .viajaEnGrupo-cta-text h2{
        font-size: 1.2rem;
        font-weight: 500;
    }

}

@media (max-width: 420px){
    
    .promociones_zone_container{
        display: flex;
        flex-wrap: wrap;
    }
    .promociones_zone_box{
        width: calc(100% - 10px);
        margin: 5px;
    }
   
}