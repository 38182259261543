@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Poppins', sans-serif;
}
/*
:root {
    --home-bg-color: #f2f3ee;
    --menu-bg-color: #cbcbc2;
    --silde-btn-border: #808080;
    --slide-btn-bg: #ddf2db;
    --slide-btn-hoverbg: #f1fff1;
    --text-color: #616161;
    --box-shadow-color: #b5b5ac;
    --light-green: #9ab09a;
    --box-shadow: 0px 0px 3px 5px var(--box-shadow-color);
    --border-radius: 60px 5px;
    --icon-hover-bg: #fff;
}
*/

.menu_form_horizontal{
    display: none;
    background: transparent;
    height: 80px;
    width: 100%;
    /*
    border-bottom: 1px solid #bbb;
    box-shadow: 5px 0 10px rgba(0, 0, 0, .6);
    */
    background: linear-gradient(180deg,#37373790,rgba(40,40,40,0));
    position: absolute;
    z-index: 7;
}

.menu_form_horizontal.scrolled{
    /* Cambia el color de fondo cuando se hace scroll */
    display: block;
    background-color: rgba(255,255,255, 0.3);
    box-shadow: 1px 2px 10px rgba(0,0,0,0.1);
    position: fixed;
}
.menu_form_horizontal.scrolled:hover{
    /* Cambia el color de fondo cuando se hace scroll */
    background-color: #002648;
}

.menu_icon_responsive{
    display: none;
}

.menu{
    background: transparent;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*
    border-bottom: 1px solid #bbb;
    box-shadow: 5px 0 10px rgba(0, 0, 0, .6);
    */
    background: linear-gradient(180deg,#37373790,rgba(40,40,40,0));
    position: absolute;
    z-index: 9;
}

.menuOptions, .menuIcons{
    display: flex;
}
.logo{
    width: 135px;
    display: inline-flex;
    height: 100%;
}
.logo img{
    height: 75%; 
    top: 7px;
    /*
    width: 200px;
    height: 100%;
    */
    width: 100%;
    height: auto;
    margin-top: 0px;
}
  
.logo img , .menu_icons{
    margin-top: 5px;

}
.menu_items{
    margin-left: 80px;
}
.menu_items, .menu_icons{
    display: flex;
    list-style: none;
    align-items: center;
    
}
.menu_items li, .menu_icons li{
    /*border-radius: 3px;
    margin: 0 5px;*/
    padding: 4px;
}
.menu_icons li span{
    cursor: pointer;
}
.menu_items li{
    padding: 7px 7px;
    position: relative;
    display: block;
    /*margin: 0px 6px;*/
}
.menu_icons li{
    padding: 6px 4px;
    position: relative;
}
.menu_items li a, .menu_icons li a{      
    text-decoration: none;
    font-size: 14px;
    display: flex;
    color: #fff;
    /*font-weight: 600;*/
    font-family: 'Poppins', sans-serif; 
    justify-content: space-between;
}
.menu_items .menu-item {
    display: inline-block;
    list-style: none;
    position: relative;
}

.menu_items .menu-item::after {
    content: '';
    display: block;
    height: 1px;
/*   width: 0; */
/*  background-color: #001A71;*/
background-color: #fff;
/*   transform: translateX(50%); */
/*   animation-name: width;
  animation-duration: 2s; */
  transition-property: left, right, width;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 110%;
}

.menu_items .menu-item:hover::after {
/*   width: 35px; */
  left: 0;
  right: 0;
}
/*
  .menu_items li:hover, li.active{
      background: rgba(0, 23, 113, .1);
      transition: .4s;
      position: absolute;
      content: "";
      height: 2px;
      background-color: #242424;
      width: 50%;
      margin-left: 15px;
      top: 50%;
      
  }*/
  .menuIcons {
      justify-content: end;
      height: 100%;
  }
  
  a.menu-item.cargaOpc {
    display: flex;
    align-items: center;
}

  .cargaOpc img{
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
  
  /* Btn-Menu*/
  
    .navbar-toggle {
      background: none;
      border: none;
      cursor: pointer;
      /*padding: 20px 22px 18px 10px;*/
      padding: 9px;
    }
    .navbar-toggle:hover {
        /*background: rgba(255,255,255,.2);*/
        background-color: #06153D80;
        border-radius: 5px;
    }
    .navbar-toggle  .fi-rr-bars-staggered{
        font-size: 25px;
        color: #fff;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .navbar-toggle .fi-rr-cross-circle{
        font-size: 25px;
        color: #141414;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .btnCerrar .navbar-toggle {
        background: none;
        border: none;
        cursor: pointer;
        padding: 20px 22px 18px 10px;
    }
    
    .toggle-icon {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #fff;
      position: relative;
      transition: background-color 0.3s ease;
    }
    
    .toggle-icon:before,
    .toggle-icon:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: absolute;
      transition: transform 0.3s ease;
    }
    
    .toggle-icon:before {
      top: -8px;
    }
    
    .toggle-icon:after {
      top: 8px;
    }

    .bandera{
        width: 22px;
        margin-left: 3px;
    }
    .bandera-list{
        width: 100px;
    }
    .bandera-list li a{
        display: inline-flex;
    }
    #btnMenu{
        display: grid;
    }
    /*Cerrar*/
    .cerrar{
        font-size: 30px;
        color: #141414;;       
    }
    
    /* Estilos del menú lateral */
    .sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        z-index: 9;
        transition: right 0.5s ease;
    }
    .sidebar.show {
        right: 0;
        top: 0;
    }
    .boxSidebar{
        background: #fff;
        color: #141414;
        width: 45%;
        height: 100vh;
        right: -55%;
        top: 0;
        position: relative;
        min-height: 100%;
        overflow-y: scroll;
    }
    .boxAsidebar{
        width: 100%;
        padding: 1% 5%;
    }
    .btnCerrar {
        text-align: right;
    }
    .blockAsidebar_first {
        display: inline-flex;
        width: 50%;
    }
    .blockAsidebar_second {
        display: inline-flex;
        width: 50%;
    }
    li.block {
        margin-bottom: 1rem;
    }
    .boxAsidebar ul li a {
        line-height: 1.5em;
        font-size: 22px;
        font-weight: 400;
        padding: 7px;
        color: #141414;
        padding: 7px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 6px;
        text-decoration: none;
    }
    .blockAsidebar_first ul, .blockAsidebar_second ul{
        margin-left: 0px;
        padding-left: 0px;
    }
    .sidebar ul ul li a{
        font-size: 18px;
        font-weight: 300;
        margin-top: 8px;
    }
    .sidebar ul ul ul li a{
        font-size: 14px;
        font-weight: 300;
        color: #fff;
    }    
    .asidebarNoticia{
        width: 100%;
        background: #f1f1f1;
        padding: 3rem 2rem;
        display: flex;
    }
    .asidebarNoticia_box{
        width: 80%;
        background: #F7F7F7;
        padding: 1rem;
    }
    .asidebarNoticia_box h2{
        margin-bottom: 1rem;
        color: #141414;
        font-weight: 500;
    }

    .asidebarNoticia_box p{
        margin-bottom: 1rem;
    }
    .asidebarNoticia_box button{
        padding: 10px 19px 9px;
        color: #fff;
        background: #0A1A72;
        border-color: transparent;
        border-radius: 5px;
    }
    .asidebarNoticia_icons{
        width: 20%;
    }
    .asidebarNoticia a{
        text-decoration: none;
    }
    .noticia_box_asidebar{
        display: block;
    }
    .noticia_box_asidebar p{
        color: #747474;
        margin-bottom: 0px;
    }
    .noticia_box_asidebar h3{
        color: #141414;
        width: 100%;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
    }
    .asidebar_noticia_box{
        display: flex;
        justify-content: space-between;
    }
    .img_box_asidebar{
        width: 50%;
        height: 130px;
        background-size: cover;
        border-radius: 3px;
    }
    ul {
        
        margin-left: 0rem;
    }
    /*
    .boxSidebar{
        background-color: #fff;
        color: #141414;
        width: 40%;
        height: 100vh;
        position: fixed;
        right: -50%;
        top: 0;
        transition: right 0.3s ease;
        z-index: 19;
    }
    .boxAsidebar{
        width: 100%;
        padding: 5%;
    }
    
    .boxAsidebar ul li a {
        line-height: 1.5em;
        font-size: 22px;
        font-weight: 400;
        padding: 7px;
        color: #141414;
        padding: 7px;
        font-family: 'Poppins', sans-serif;
    }

    
    .btnCerrar {
        text-align: right;
        padding-right: 7%;
    }
    .blockAsidebar_first {
        display: inline-flex;
        width: 50%;
    }
    .blockAsidebar_second {
        display: inline-flex;
        width: 50%;
    }

    .sidebar ul ul li a{
        font-size: 18px;
        font-weight: 300;
    }

    .sidebar ul ul ul li a{
        font-size: 14px;
        font-weight: 300;
        color: #fff;
    }
    .sidebar .block{
        margin-bottom: 28px;
    }

    .asidebarNoticia{
        width: 100%;
        background: transparent;
        padding: 16px;
        justify-content: center;
        display: flex;
    }
    .asidebarNoticia_box{
        width: 80%;
        background: #F7F7F7;
        padding: 3rem;
    }
    .asidebarNoticia_box h2{
        margin-bottom: 1rem;
    }
    .asidebarNoticia_box p{
        margin-bottom: 1rem;
    }
    .asidebarNoticia_box button{
        padding: 10px 19px 9px;
        color: #fff;
        background: #0A1A72;
        border-color: transparent;
        border-radius: 5px;
    }
*/
    

    @media (max-width: 368px) {
      .menuOptions .menu_items{
          display: none;
      }
      .sidebar {
          width: 100%;
          left: -140%;
          z-index: 1;
          top: 65px;
      }
      .sidebar.show {
          left: 0;
          top: 65px;
        }
      .menu_icons{
          z-index: 9;
      }
      .menu{
          height: 65px;
      }
      .logo img {
          margin-top: 5px;
          max-width: 107px;
      }
      
  }
  
  /*
    @media (max-width: 768px) {
        .navbar-toggle {
            display: block;
        }
    
        .menu {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #074b7d;
            width: 100%;
            padding: 10px;
        }
    }*/

    /*SUB MENU desplegable  VIAJAR*/
    .submenu-item{
        display: none;
        z-index: 9;
    }
    .menu_items .menu-item:hover .submenu-item{
        display: block;
    }

    .menu_items li .submenu-item, .menu_icons li .submenu-item{
        display: none;
        position: absolute;
        background: #06153D80;

        /*color: #fff;*/
        border-radius: 2px;
        -webkit-box-shadow: 0 0 50px rgba(20,20,20,.1);
        box-shadow: 0 0 50px rgba(20,20,20,.1);
        position: absolute;

        /*padding: 12px;*/
        width: 230px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);   
        padding-left: 0rem;     
    }
    .menu_items li:hover > .submenu-item{
        display: inherit;
    }
    .menu_icons li:hover > .submenu-item{
        display: inherit;
    }
    .menu_items li .submenu-item li{
        /* width: 200px; */
        float: none;
        display: list-item;
        position: relative
    }
    .menu_items li .submenu-item li a{
        padding: 5px 15px;
        color: #fff;
        border-radius: 3px;
    }
    .menu_icons li .submenu-item li a{
        padding: 5px 15px;
        color: #fff;
    }
    .menu_items li .submenu-item li a:hover{
       background-color: #ffffff25;
    }
    .menu_icons li .submenu-item li a:hover{
        background-color: #ffffff25;
     }
  
    .submenu-item .a:hover::after {
        /* width: 35px; */
        left: 0;
        right: 0;
    }
    
    .submenu-item .a::after {
        content: '';
        display: block;
        height: 1px;
        /* width: 0; */
        background-color: #001A71;
        /* transform: translateX(50%); */
        transition-property: left, right, width;
        transition-duration: .5s;
        transition-timing-function: ease-out;
        position: absolute;
        left: 50%;
        right: 50%;
        top: 110%;
    }

    .anticon-down{
        font-size: 11px;

        margin-left: 4px;
    }


    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
       /*  background-color: rgba(0, 0, 0, 0.5); Color de fondo oscuro con opacidad */
        background: rgba(20,20,20,.75);
        z-index: 999; /* Asegura que esté por encima de otros elementos */
      }

      .menu_mobile a.menu-item {
        width: 60px;
        display: flex;
        align-items: center;
    }
    .ant-space.css-dev-only-do-not-override-18iikkb.ant-space-vertical.ant-space-gap-row-small.ant-space-gap-col-small {
        width: 100%!important;
    }

    @media (max-width: 768px)
    {
        .menu_items li .submenu-item{
            position: relative;
            /*background-color: transparent;*/
            background: #12406f96;
            box-shadow: none;
            width: 100%;
        }
    }