.destinos_content_general {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;

}
.box_content_destinos {
    width: 33%;
}
.box_group_card_destino {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 10px 12px; */
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    transition: transform .3s;
    height: 260px;
    max-height: 260px;
}
.box_group_card_destino:hover {
    /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
    -webkit-box-shadow: 10px 10px 10px 2px rgba(161,161,161,0.62);
-moz-box-shadow: 10px 10px 10px 2px rgba(161,161,161,0.62);
box-shadow: 10px 10px 10px 2px rgba(161,161,161,0.62);
}

/*
.destino_content img{
    width: 100%;
    height: 300px;
}
.destino_content h2{
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: #0075BE;
}
.destino_content p{
    margin-top: 2rem;
}

.galeriaDestinos{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.box_galeriaDestinos{
    width: 50%;
}

.galeriaDestinos img{
    width: 100%
}

img.stock1{
    width: 90%;
}
img.stock2{
    width: 90%;
    float: right;
}

img.stock3{
    width: 100%;
    margin-bottom: 4rem;
}
.callToAction_title h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
} 
*/
.destino{
    background: #f5f5f5;
    margin: 4rem 0rem;
}
.destino_info_util{
    display: flex;
}
.content_destinos_info{
    padding: 4rem 1rem;
    width: 50%;

}
.box_destinos_info{
    margin-bottom: 2rem;
}
.box_destinos_info h3{
    color: #353535;
    font-size: 22px;
}
.box_destinos_info label{
    font-size: 16px;
}
.box_destinos_info ul{
    padding-left: 0rem;
}
.box_destinos_info ul li {
    color: #353535;
    font-weight: 400;
    font-size: 16px;
}
.box_destinos_info ul li strong{
    color: #353535;
    font-weight: 500;
    font-size: 18px;
}


@media (max-width: 768px) {
    .box_content_destinos {
        width: 100%;
        margin-top: 1rem;
    }
    .destinos_content_general {
        width: 100%;
        display: grid;
        margin-top: 2rem;     
    }
    .destino_info_util{
        display: block;
        margin-top: 2rem;
    }
    .content_destinos_info{
        padding: 1rem 1rem;
        width: 100%;
    }
}