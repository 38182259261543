
.answer_pgunas_frcuentes{
    width: 80%;
}
.answer_pgunas_frcuentes h1{
    font-size: 22px;
    color: #545454;
}
.answer_pgunas_frcuentes p{
    font-size: 18px;
    margin-bottom: 0px;
    color: #545454;
    font-size: 16px;
}
.answer_pgunas_frcuentes ul,
.lista_preguntas ul{
    padding-left: 0rem;
    margin-left: 0rem;
    font-size: 16px;
}

.box_share{
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0rem;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-top: 4rem;
}
.box_share p{
    margin-bottom: 0rem;
}
.btn_share{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.btn_share i{
    display: flex;
    margin-left: 5px;    
}
.share_options{
    display: block;
}
.share_options button{
    display: block;
   
}
.share_options{
    background: #fff;    
    padding: 1.5rem 3rem 1.5rem 1rem;
    box-shadow: 0 0 2px hsla(0, 0%, 8%, .12), 1px 3px 10px hsla(0, 0%, 8%, .12);
    border-radius: 3px;
    position: absolute;
}
