  .noticias_content_line{
    display: flex;
    width: 100%;
  }
  .noticias_content_line .noticia{
    width: 50%;
    position: relative;
    display: inline-block;
    text-align: center;
    padding-right: 5px;
  }
  .noticias_content_line {
    width: 100%;
    height: 430px;
    border-radius: 4px;
  }
  .noticias_content_line .noticias{
    width: 50%;
    display: block;
  }
 
  .noticia-content-box {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .noticias_text_home{
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #fff;
    text-align: left;
    padding: 1rem;
    /*filter: drop-shadow(2px 4px 6px black);*/
  }
  .noticias_text_home i{
    color: #707070;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 12px;
  }
  .noticias_text_home p{
    color: #707070;
    background: #ffffffBF;
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 25px;
    margin-bottom: 12px;
    font-style: italic;
    font-weight: 500;
    font-size: 11px;
  }
  .noticias_text_home h3{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .noticias_home {
    width: 50%;
    padding-left: 5px;
    display: grid;
    align-content: space-between;
  }
  .noticia-content-box {
    width: 100%;
    /*margin-bottom: 10px;*/
  }
  .noticia_img_header_home{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
  }
  .noticias_img_header_home{
    height: 210px;   
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px; 
    background-position: center;
  }
.noticias_home_horizontal{
  display: flex;
  justify-content: space-between;
  margin-top:10px;
}

.noticias_img_header_home_horizontal{
  width: auto;
  min-height: 150px;
  background-size: cover;
  background-repeat: repeat;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.noticias_text_home_horizontal{
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 125px;
  padding: 0px 0.7rem;
}
.noticia-content-horizontal {
  width: 24.2%;
}
.noticia-content-horizontal a{
  text-decoration: none;
}
.noticias_text_home_horizontal p{
  color: #707070;
  margin-top: 10px;
  font-style: italic;
  font-weight: 500;
  font-size: 11px;
  
}
.noticias_text_home_horizontal h3{
  color: #545454;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.noticia-item-horizontal:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.noticia-content-box:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.noticias_content_line .noticia:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Celular */
.noticias_home_mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.noticia-content-mobile {
  width: 49%;
  box-sizing: border-box;
  margin-top: 10px;
}
.noticia-content-mobile a{
  text-decoration: none;
}
.noticia-item-mobile {
  width: 100%;
  height: 120px;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.noticias_text_home_mobile {
  border: 1px solid #ddd;
  padding: 0.8rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.noticias_text_home_mobile p{
  color: #707070;
}
.noticias_text_home_mobile h3{
  font-size: 11px;
  line-height: normal;
  color: #707070;
}

.informacion-div {
  -webkit-line-clamp: 3; 
  display: -webkit-box; 
  overflow: hidden; 
  -webkit-box-orient: vertical;
}