#fp-nav ul li a span, .fp-slidesNav ul li a span{
  background: #fff!important;
}
.fp-slidesNav.fp-bottom ul{
  align-items: center;
  justify-content: center;
  display: flex;
}
.section:nth-child(1) {
  /*background: #016582;*/
  background: transparent;
}
.section{
  /*background:red;*/
  background: transparent;
}
.slide:first-child {
  color: #fff;
  font-weight: bold;
}
.hero_sostenibilidad{
  /*
  width: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;    
  position: absolute;
  display: contents;
  z-index: 1;
  */
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.hero_sostenibilidad video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  display: inline-block;
}
.home_rse_mobile{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../assets/images/sostenibilidad/home.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.home_rse_mobile img{
  width: 100%;  
  padding: 3rem 1rem 2rem;
  margin-top: 2rem;
}
.icons_pilares {  
  width: 100%;
  height: 200px;
  position: absolute;

  top: 54%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_pilar{
  margin: 1rem;
}
.icon_pilar p{
  font-size: 1rem;  
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.icon_pilar_img{
    justify-content: center;
    display: flex;
}
.icon_pilar img{
  width: 4rem;
}
.rse-economia-sociedad{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/sostenibilidad/BG-economia-sociedad.webp');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rse-bienestar-empresarial{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/sostenibilidad/BGI-BienestarEmpresarial.webp');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rse-ecosistemas-terrestre{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/sostenibilidad/BGI-EcosistemasTerrestres.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rse-ecosistemas-marinos{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/sostenibilidad/BGI-EcosistemasMaritimos.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rse-form{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/images/sostenibilidad/tecolote.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rse-economia-sociedad-content{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.rse-form-content {
  display: flex;
  height: 90vh;
}
.rse-economia-sociedad-side-left{
  width: 50%;
}
.rse-form-side-left {
  width: 50%;
  background: rgba(0, 0, 0, 0.5);
  padding: 8rem 6rem;
  align-content: center;
}
.rse-form-box-left{
  width: 100%;
}
.rse_form_text_box{
  align-content: center;
  height: 100%;
  padding-left: 4rem;
}
.rse_form_text_box label:nth-child(-n+3){
  font-size: 2rem;
}
.rse_form_text_box p{
  font-size: 18px;
  font-weight: 500;
}
.rse-form-box-img {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.rse-form-box-img img{
  width: 80%;
}
.rse-economia-sociedad-side-right{
  width: 50%;
  justify-content: center;
  display: flex;
}
.rse-economia-sociedad-side-right p{
  font-size: 1rem;
  font-weight: 300;
}

.rse-economia-sociedad h2{
  font-size: 2.5em;
  color: #FFF;
  font-weight: 400;
  line-height: 1.1;
  padding: 0rem 1rem;
}
.rse-economia-sociedad h3 {
  font-size: 1.5em;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-weight: 500;
  
}
.rse-economia-sociedad p{
  font-size: 1rem;
  font-weight: 300;
}
.btn_bottom_hero_rse {
  position: absolute;
  bottom: 35px;
  left: 50%;
}
.btn_bottom_hero_rse button{
  background: transparent;
  border: none;
  color:#fff;
}
button.btn_readmore_rse {
  border: 3px solid #fff;
  background: transparent;
  color: #fff;
  padding: 12px 50px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
  margin-top: 1rem;
}

.btn_bottom_rse{
  position: absolute;
  bottom: 35px;
  left: 50%;
}

.btn_left_rse{
  position: absolute;
  top: 50%;
  left: .5%;
  transform: translate(-50%, -50%);
}
.btn_right_rse{
  position: absolute;
  top: 50%;  
  right: .5%;
  transform: translate(-50%, -50%);
}
.btn_bottom_rse button, .btn_left_rse button, .btn_right_rse button {
  background-color: transparent;
  border: none;
  color: #FFF;
  cursor: pointer;
}
.btn_bottom_rse i{
  /*font-size: 35px;*/
}
.rse-economia-sociedad-title{
  display: flex;
}
.rse-economia-sociedad-title-img img{
  max-width: 110px;
  margin-right: 0rem;
}
.rse_text_left{  
  padding: 0rem 1rem;
}
.rse_text_left_form{
  display: block;
}
.rse_form_box_input{
  width: 100%;
  margin-bottom: 1rem;
}
.rse-form-side-left h3{
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.rse_form_box_input input[type=email], .rse_form_box_input input[type=password]{
  padding: 10px 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: #f1f1f1;
}
.rse_form_box_input button{
  padding: 10px 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #12406F;
  background: #12406F;
  color: #fff;
}

.home_rse_mobile .icon_pilar_img img{
  width: 85px;
  padding: 0rem 1rem 0rem;
}
.home_rse_mobile .icon_pilar p{
    font-size: 14px;  
    font-weight: 500;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 0.5rem;
    text-align: center;
  }
  .home_rse_mobile .icon_pilar_img {
        text-align: center;
  }
  .home_rse_mobile .icons_pilares_mobile {
    /*
    width: 100%;
    height: 200px;
    position: absolute;
    z-index: 2;
    top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    */
    width: 100%;
    height: auto;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0rem 1rem;
}
.icons_pilares_mobile .icon_pilar{
  width: calc(50% - 10px);
  margin: 0px 5px;
  padding: 0.5rem 1rem;
}
.icons_pilares_mobile .icon_pilar_img img{
  margin-top: 0px;
}

.rse-economia-sociedad-title{
  padding: 0rem 1rem;
}
.rse-economia-sociedad-side-left h2 {
  font-size: 2.5em;
  padding: 0rem 1rem;
}
.rse-economia-sociedad-side-left h3 {
font-size: 1.5em;
}
.rse-economia-sociedad-side-left p {
  font-size: 1rem;
  font-weight: 300;
}

.rse-economia-sociedad-side-right img{
  max-width: 220px;
}





/* Scroll animation */
.btn {

  width: 24px;
  height: 40px;
 
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: 2px solid rgb(255, 255, 255);
  /*box-shadow: 0px 0px 10px rgb(105, 127, 255);*/
  position: relative;
  padding: 0px;
}

.scroll {
  width: 5px;
  height: 10px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  /*box-shadow: 0px 0px 10px rgb(105, 127, 255);*/
  animation: scroll_4013 2s linear infinite;
  transform: translateY(40%);
}
/*
.btn:after {
  content: 'scroll';
  position: absolute;
  top: 140%;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
*/
@keyframes scroll_4013 {
  0% {
    transform: translateY(40%);
  }

  50% {
    transform: translateY(90%);
  }
};

/* Scroll animation */

@media (max-width: 768px){
  
  .icons_pilares_mobile {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .icon_pilar {
    width: 50%;
    margin: 1rem 0rem;
  }  
  .rse-economia-sociedad-content{
    display: block;
  }
  .rse-economia-sociedad-side-left h2{
    padding: 0rem 1rem;
  }
  .rse-economia-sociedad-side-left{
    width: 100%;
    text-align: center;
  }
  .rse-economia-sociedad-side-right{
    width: 100%;
    justify-content: center;
  }
  .rse-economia-sociedad h2 {
    justify-content: center;
    display: flex;
  }
  .rse_text_left{
    justify-content: center;
  }
  .rse-economia-sociedad-side-right img {
    max-width: 160px;
  }





  

 
}



