    /*SECCION TERMINALES */
    .box_terminales{
        width: 100%;
        height: auto;
        margin-top: 4rem;
        padding: 0 12px;
    }
    .box_terminales h2{
        font-size: 28px;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 16px;
        font-weight: 400;
    }
    .box_terminales p{
        font-size: 18px;
        margin-bottom: 16px;
        color: #545454;

    }
    .box_terminales button{
        /*padding: 8px 11px;*/
        padding: 10px 19px 9px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        box-shadow: none;
        color: #353535;
    }

    .box_terminales button:hover{
        background-color: #f7f7f7;
        border: 1px solid #cfcfcf;
        box-shadow: none;
        color: #353535;
    }
    .content_terminales, .content_antesViajar, .content_paraViajar{
        padding: 0 12px;
        margin-top: 35px;
    }
    .cardTerminales {
        transition: 0.3s;
        width: 100%;
        border: 1px solid #dbdbdb;
        border-radius: 5px;
    }
    .cardTerminales img{
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    
    .cardTerminales:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    
    .textTerminales {
        padding: 15px 16px;

    }
    .textTerminales h4 {
        color: #000;
        margin-top: 16px;
        font-size: 20px;
        font-weight: 400;
    }
    .textTerminales p {
        color: #545454;;
        margin-top: 10px;
        margin-bottom: 36px;
        font-size: 15px;
    }

    /* SECTION AUTORIDADES */
    
    .box_autoridades{
        width: 100%;
        height: auto;
        margin-top: 4rem;
        padding: 0 12px;
    }
    .content_autoridades{
        width: 70%;
        text-align: left;
    }
    .box_autoridades h2{
        font-size: 28px;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 16px;
        font-weight: 400;
    }
    .box_autoridades p{
        font-size: 18px;
        margin-bottom: 16px;
        color: #545454;

    }
