.header_carga.integracion{
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/images/carga/CARGA-BGI-INTLOG.webp');
    padding: 8rem 0rem 4rem;
    background-size: 100% 100%;
    /* background-position: bottom; */
    color: #fff;
}
.integracion h2, .integracion p{
    color: #fff;
}
.beneficios.integracion_logistica{
    padding: 6rem 0rem;
}
.beneficios_container.integracion_logistica{
    display: flex;
    width: 100%;
}
.beneficios_content.integracion_logistica{
    width: 100%;
    display: flex;
    padding: 1rem;
}

.beneficios_box.integracion_logistica {
    text-align: center;
    align-items: center;
    display: grid;
    align-content: center;
    justify-content: center;
}
.beneficios_box.integracion_logistica img{
    
}
.beneficios_box.integracion_logistica h3{
    color: #434343;
    font-size: 16px;

}
.servicio_carga_box.ilvideo{
    position: relative;
}
.btn-controls-video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-controls-video button{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #f1f1f1;
}
/*
.button-visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.button-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.play-pause-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

*/

@media (max-width: 952px){
    .servicio_carga_box video{
        height: auto;
    }
}