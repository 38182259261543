#content{
    margin-top: 4rem;
}
.box_group-card{
    
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 12px;
    background-size: contain;
    color: #fff;    
}
.contact-box-card{
    height: 250px;
    /*margin-bottom: 4rem*/
}

.box_content-card{
    padding: 12px 12px; 
    display: flex;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
}/*
.box_content-card{
    padding: 24px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    width: 100%;
    display: flex;
}*/

.box_icon-card{
    display: inline-block;
    width: 10%;
    padding: 15px 5px;
}
.box_icon-card img{
    width: 100%;
}
.boxes_content{
    display: inline-block;
    width: 90%;
    padding: 15px 0px 0px 15px;
}
.box_content-title{
    width: 100%;
    margin-bottom: 3px;
}
.box_content-title h2{
    font-size: 22px;
    font-weight: 500;
    color: #141414;
}
.box_content-subtext {
    margin-bottom: 16px;
}
.box_content-subtext p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li a{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext li:hover{
    color: #141414;
   text-decoration: underline;
    
}
.box_content-btn{
    width: 100%;
}
.box_content-btn a{
/* padding: 8px 11px;*/
    padding: 10px 19px 9px;
    color: #fff;
    /*background: #00155F;*/
    background:#002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
}
.box_content-btn a:hover{
    background: #FF8F0095;
}



@media (max-width: 768px){
    .box_content-title h2{
        font-size: 20px;
    }
    .box_content-subtext ul li{
        margin-bottom: 0px;
    }
    .box_content-subtext ul li a {
        font-size: 14px;
    }
}