/*
.content_box_promociones{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
}
.box_content_promos{
    width: 49%;
    
    position: relative;
    margin-top: 1rem;

}
.box_content_promos::before {
    content: '';
    background: #225981; 
    height: 100%;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;  
}


.box_content_promos:nth-child(1) {   
    content: '';
    height: 100%;
    background: #F5A81B;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.box_content_promos:nth-child(2) {
   
    content: '';
    height: 100%;
    background: #F5A81B;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.box_content_promos:nth-child(3) {
   
    content: '';
    height: 100%;
    background: #F5A81B;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.box_content_promos:nth-child(4) {
   
    content: '';
    height: 100%;
    background: #F5A81B;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}*/

/*
.box_content_promos:nth-child(1) .box_decoration::before {
    background: #F5A81B;
    content: '';
 
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.box_content_promos:nth-child(2) .box_decoration::before {
    background: #225981; 
    content: '';
    height: 100%;
    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.box_content_promos:nth-child(3) .box_decoration::before {
    background: #225981;; 
}
.box_content_promos:nth-child(4) .box_decoration::before {
    background: #F5A81B; 
}

.box_decoration::before {
    content: '';

    width: 14px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
*/
/*
.promociones_landing{
    width: 100%;
    margin-top: 4rem;
}
.content_box_promociones_land {
    width: 99%;
    display: flex;
}
.box_content_promos, .promo_content_land{
    width: 50%;
    height: 500px;
    margin-right: 1%;
}
.box_group_card_promo_land {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    transition: transform .3s;
    height: 500px;
   
}
.box_group_card_promo_opc1, .box_group_card_promo_opc2{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    transition: transform .3s;
    height: 245px;
}
.box_content_promos_land:nth-child(1) {
    margin-bottom: 10px;
}*/
/* Promociones*/
.sorteo{
    width: 100%;
    /*padding: 6rem 0rem;*/
    background: linear-gradient(to right, #404040 0%, #404040 50%, #d3d3d3 50%, #d3d3d3 100%);

}
.sorteo_content{
    width: 100%;
    margin-top: -4rem;
    background: #fff;
    border-radius: 5px;
}
.sorteo_content img{
    width: 100%;
    border-radius: 5px;
}
.sorteo_text h2{
    padding-top: 1rem;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 16px;
    font-weight: 600;    
}
.sorteo_text p{
    font-size: 18px;
    margin: 1rem 0rem;
    color: #545454;

}
.sorteo_text button{
    background: #FF8F00;
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
}


/* Promociones*/
