
/* Contenido Texto */
.nosotros{
    display: flex;
    width: 100%;
    margin-top: 4rem;
}
.nosotros_text{
    padding: 0px  25px 0px 0px;
    width: 50%;
}

.nosotros_text h2{
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.nosotros_text p{
    font-size: 18px;
    margin-bottom: 16px;
    color: #545454;
}
.nosotros_img{
    width: 50%;
}
.nosotros_img img{
    width: 100%;
}
/* Nosotros */

/* Mision y vision */

.mision_vision_nosotros{
    display: flex;
    padding: 4rem 0rem;
}
.box_mision_vision_nosotros{
    display: block;
    width: 33.3%;
    text-align: center;
    padding: 1rem;
}
.box_icon_mision_vision_nosotros{
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
    margin-bottom: 1rem;
}
.box_mision_vision_nosotros p{
    color: #545454;
}
.icon_mision_vision_nosotros{
    width: 80px;
    background: #225981;
    height: 80px;
    color: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.5rem;
}
.icon_mision_vision_nosotros i{
    display: flex;
}
/* Mision y vision */

/* Valores */

.valores_nosotros{
    width: 100%;
    background-color: #eee;
    padding: 4rem 0rem; 
}
.valores_bf{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.box_valores_bf h3{
    color: #225981;
    font-weight: 500;
    font-size: 22px;
}
.box_valores_bf{
    width: 49%;
    background: #fff;
    color: #545454;
    padding: 2rem;
}
.box_valores_bf ul{
    padding-left: .5rem;
}
.box_valores_bf ul li{
    list-style: disc;
    margin-top: 5px;
}

/* Valores */
.title_nosotros {
    text-align: center;
    color: #225981;
    padding: 2rem 0rem;
}
/* Linea de tiempo */


.linea_de_tiempo_nosotros{
    width: 100%;
    padding: 4rem 0rem;
}
.linea_tiempo_nosotros{
    display: flex;
    width: 100%;
}
.box_linea_tiempo{
    width: 25%;
    padding: .5rem;
}
.img_linea_tiempo, .img_linea_tiempo img{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.box_text_linea_tiempo{
    border: 1px solid #dbdbdb;
    padding: 1rem;
    border-radius: 5px;
    min-height: 330px;
}
.box_linea_tiempo h3{
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}
.box_linea_tiempo p{
    color: #545454;
    margin-top: 10px;
    margin-bottom: 36px;
    font-size: 16px;
}




/* Linea de tiempo */

/* Servicio box */ 

.servicios_title h2{
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
    margin-top: 4rem;
}
.servicios_title{
    width: 100%;
    display: block;
}
.servicios{
    width: 100%;
    display: flex;
}
.servicios_title{
    display: block;
    width: 100%;
}

.servicios_box{
    width: 50%;
    padding: 0px 25px 0px 25px;
}
.servicios_box li{
    list-style-type: disc;
    margin-top: 16px;
    font-size: 18px;
}


/* Código de ética */

.codigo_etica{
    margin-top: 4rem;
    width: 100%;
    height: 500px;
    background-image: url('../assets/images/codigoEtica.webp');
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.codigo_etica_box{
    background:#fff;
    width: 50%;
    padding: 25px;
    border: 1px solid #ddd;
}

/* Baja ferries en numeros */
.bf_numeralia{
    margin-top: 4rem;
    background-color: #00274A;
}
.bf_numeralia_box{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bf_numeralia_item{
    width: 33.33%;
    padding: 30px;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.bf_numeralia_item h4{
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}
.bf_numeralia_item p{
    font-size: 17px;
    color: #fff;
    font-weight: 200;
}

.box_item_icon{
    display: flex;
    justify-content: center;
}

.box_item_icon i{
    font-size: 6rem;
    font-weight: 600;
    color: #fff;
}
.box_item_icon img{
    width: 50px;
}
.slider_nosotros{
    width: 100%;
    height: auto;
    padding-top: 6rem;
    position: relative;
    display: inline-block;
    text-align: center;
    color: #fff;
    background: linear-gradient(71.93deg, #001b33 26.34%, #004887 56.72%, #273b49 94.79%);

}

/* slider */


/* numeralia */
.numeralia{
    background-image: url('../assets/images//nosotros/nuneralia.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.numeralia_content {
    padding: 4rem 0rem;
}
.numeralia_row {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0rem;
}
.numeralia_box {
    color: #fff;
    text-align: center;
}

.numeralia_box label{
    font-size: 16px;
}
.numeralia_box p{
    font-size: 2rem;
    padding: 5px 0px;
    margin-bottom: 0rem;
    font-weight: 600;
    text-align: center;
    line-height: 35px;
}


@media (max-width: 768px){
    .nosotros {
        display: block;
    }
    .nosotros_text, .nosotros_img, .box_mision_vision_nosotros, .box_valores_bf,.box_linea_tiempo,.codigo_etica_box{
        width: 100%;
    }
    .box_valores_bf {
        margin-bottom: 1rem;
    }
    .mision_vision_nosotros{
        display: block;
    }
    .valores_bf,.linea_tiempo_nosotros{
        display: block;
    }
    .numeralia_row{
        display: block;
        padding: 0rem;
    }
    .line{
        display: none;
    }
    .timeline-content {
        width: 100%;
    }
    .timeline-content{
        width: 100%!important;
    }
    .timeline-event.right {

        margin-top: 0rem!important;
    }
    .timeline-event.left:not(:first-of-type) {
        margin-top: 0rem!important;
    }
    .right .timeline-content::after {
        display: none!important;
    }
    .right .timeline-box::after {
        display: none!important;
    }
    .left .timeline-content::after {
        display: none!important;
    }
    .left .timeline-box::after {
        display: none!important;
    }

}