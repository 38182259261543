.content_destino1 a, .content_destino2 a, .content_destino3 a{
    text-decoration: none;
}
.promociones_content_general{
    width: 100%;
    margin-top: 4rem;
    display: flex;
}

.promociones_content_general .box_content_promo{
    width: 50%;
}

.promociones_content_general .box_group_card_promo:nth-child(1) {
    background-size: cover;
    height: 500px;
    
}
.promociones_content_general .content_box_promociones{
    width: 50%;
    display: grid;
}
.promociones_content_general .content_box_promociones{
    width: 50%;
}
