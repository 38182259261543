/* styles.css */
.custom-datepicker .ant-picker {
    border: 2px solid #fff;
    border-radius: 0px;
    width: 100%;
    height: 26px;
    padding: 0px 20px;
  }
  .custom-datepicker{
    width: 100%;
  }
  
  .custom-datepicker .ant-picker:hover {
    border: 2px solid #fff;
  }

  .ant-space.css-dev-only-do-not-override-18iikkb.ant-space-vertical.custom-datepicker {
    width: 100%;
  }
  .formBooking_fijo .custom-datepicker .ant-picker {
    padding: 0rem;
  }
  
