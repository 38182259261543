@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: 'Poppins', sans-serif;
}

button.hamburger {
    height: 30px;
    display: flex;
    background: transparent;
    border: none;
}
/*
@media only screen and (max-width: 420px) {

    .menu {
        display: none!important;
    }
    .menu_mobile{
        display: block!important;
    }
    .menu_mobile .navbar{
        background: red;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        padding: 0px 20px
    }
    .menu_logo{
        align-items: center;
        vertical-align: middle;
        display: flex;
    }
    .menu_logo img{
        width: 120px;
    }
    .menu_btn button{
        border: none;
        background: transparent;
    }
    .hamburger i{
        display: flex;
        color: #fff;
        font-size: 32px;
    }
    .menu_btn {
        display: flex;
    }

    .navigation-menu{
        display: none;
    }
    .navigation-menu.expanded {
        display: block;
        height: 100vh;
        transition-delay: 1s;
    }

}*/


    /* Small devices (portrait tablets and large phones, 320px and up) */
    @media only screen and (max-width: 320px) {

        /* Menu */
        .header{
            display: none;
        }
    
        .menu_mobile{
            display: block;
            width: 100%;
            background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
            height: 60px;
        }
        .navbar{
            width: 100%;
            height: 60px;
            justify-content: space-between;          
            display: flex;
        }
        .menu_logo{
            width: 30%;
            justify-content: center;
            align-items: center;
            display: flex;
        }
        .menu_logo img{
            width: 100%;
        }
        .hamburger {
            display: block;
        }
        .menu_btn{
            justify-content: center;
            align-items: center;
            display: flex;
        }
        
        .navigation-menu ul {
            display: none;
        }
        .navigation-menu ul {
            position: absolute;
            top: 60px;
            left: 0;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 77px);
            background-color: white;
            border-top: 1px solid black;
        }
        .navigation-menu li {
            text-align: center;
            margin: 0;
        }
        .navigation-menu li a {
            width: 100%;
            padding: 1.5rem 0;
        }
        .navigation-menu li:hover {
                background-color: #eee;
        }
        .navigation-menu ul {
            /* previous styles */
            display: none;
        }
    
        /* Menu Celular */
        .hamburger i{
            color: #fff;
            font-size: 25px;
        }
    
        .hamburger i.fi.fi-rr-cross-circle{
            color: #fff;
            font-size: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
            
        .navigation-menu .menu_mobile_list {
            border-top: none!important;
        }
        .navigation-menu li {
            text-align: left;
            margin: 0;
            padding: 1rem 2rem;
        }
        .navigation-menu.expanded li {
            padding: 1rem!important;
            font-size: 1.1rem!important;
        }
        /*
        .navigation-menu.expanded ul {
            display: block!important;
            background: #F7F7F7!important;
            z-index: 9!important;
            color: #002648!important;
        }*/
        .navigation-menu ul a{
            text-decoration: none!important;
            color: #002648!important;
        }
        /*
        .submenu-item {
            display: none!important;
        }*/
        .submenu-item.active_submenu{
            display: block!important;
        }
        .submenu-item.active_submenu-item {
            display: block!important;
        }
        .submenu-item.active_submenu li a{
            font-size: 1.1rem!important;
            padding: 1rem!important;
        }
    
    
    
        /* Video Hero */
        .hero video{
            display: none!important;
        }
    
        /* Noticias */
        .noticias {
            width: 100%;
            display: block!important;
        }
    
        /* Imagen Hero 
        .hero .heroImg{
            display: block!important;
            width: 100!important;
            height: auto!important;
        }
        .heroImg img{
            min-height: 50vh!important;
            max-height: 80vh!important;
            width: 100%!important;
            filter: brightness(0.9);
        }
        .hero_text {
            top: 20%!important;
        }
        *//*
    
        .content_hero_text {
            width: 100%;
            display: grid;
            justify-content: center!important;
            color: #fff;
            text-transform: uppercase;
        }
        
        .box_hero_text {
            display: inline-flex!important;
            align-items: center!important;
            height: 40px!important;
        }
        .box_hero_text h2 {
            font-size: 7vw!important;
            margin-right: 8px!important;
        }
        .box_hero_text label {
            font-size: 5vw!important;
        }*/
    
        /*Formulario Reservas */
        .boxBooking {
            top: 44%!important;
        }
        .formBooking {
            width: 100%!important;
        }
        .radioBooking {
            width: 100%!important;
        }
        .radioButons{
            width: 50%!important;
        }
        .contentBooking {
            width: 100%!important;
        }
        
        .btnBuscar button:hover{
            background: #FF8F00;
        }
        
        .textPlaceholder:hover{
            background: #FF8F00;
            border-radius: 4px;
        }
        
        .textPlaceholder .icon{
            display: contents;
        }
        
        /*input::placeholder {
            color: #fff;
            font-size: 1.5em;
        }*/
        
        span.anticon.anticon-swap-right {
            border-radius: 15px;
            background: #fff;
            padding: 5px;
        }
        .modal_valoracion .modal-content {
            width: 26%!important;
        }
        .v-phone {
            display: block;
        }
        .v-desktop{
            display: none;
        }
    
        /* Formulario Fijo */
        .horizontal_form {
            display: grid!important;
            justify-content: center!important;
        }
        .box_select_ruta_horario{        
            margin: 5px 0px!important;
        }
    
    
        /* Valoracion */
        .modal-content{
            width: 90%!important;
        }
        
    
        /* Promociones */
        .box_group_card_promo {
            height: 290px!important;
        }
    
    
        /* Spotify */
        .box_spotify {
            display: block!important;
        }
        .box_text_spotify {
            width: 100%!important;
        }
        .title_spotify {
            font-size: 2rem!important;
        }
        .icon_spotify {
            width: 25%!important;
            display: flex!important;
        }
        .text_spotify {
            font-size: 18px!important;
        }
        .text_block_spotify {
            margin-bottom: 2rem!important;
        }
        .list_icon_spotify img {
            width: 30px!important;
        }
        .content_frame_spotify {
            width: 100%!important;
            justify-content: center!important;
            display: flex!important;
            margin-top: 2rem!important;
        }
        .frame_spotify {
            width: 100%!important;
        }
    
        /* RSE */
    
        .videoRSE video {
            display: none!important;
        }
    
        
        .RSEImg{
            display: block!important;
        }
        .RSEImg img{
            width: 100%!important;
            }
        .videoRSE .RSElogo {
            width: 70%!important;
        }
    
        /* Footer */
        footer .links {
            display: block!important;
        }
        footer .links_columna {
            display: block!important;
            padding: 10px 24px;
        }
    
        /*Rutas y horario */
        .tabsTabla{
            display: block!important;
        }
        .tablaRutasHorario {
            width: 100%!important;
        }
    
        .reservaBox {        
            width: 100%!important;
            padding: 20px 20px;
        }
    
        /* Call to Action */
    
        .ant-row{
            display: block;
        }
        .ant-col{
            max-width: 100%;
        }
        .contact-box-card {
            height: auto!important;
            min-height: 225px!important;
            margin-bottom: 0rem!important; 
        }
        .copyright{
            display: block!important;
        }
    
        .modal_valoracion .modal-content {
            width: 27%; 
        }
    
        /* Ruta y Horario */
        .box_content_mapa_ruta_horario {
            width: 100%!important;
            margin-top: 1rem!important;
        }
        /* Noticias */
        .box_mosaico_noticias {
            display: block!important;
        }
        .box_mosaico_block_izq {
            display: grid!important;
            width: 100%!important;
        }
        .box_mosaico_block_der {
            display: block!important;
            width: 100%!important;
        }
        .mosaico_box_3, .mosaico_box_4 {
            display: grid!important;
            width: 100%!important;
        }
    
        /* Promociones */
        .promociones {
            display: block!important;
        }
        .box_content_card_promo, .content_box_promociones {        
            width: 100%!important;
        }
        .promociones_content {
            display: block!important;
            width: 100%!important;
        }
        .box_content_promo {
            width: 100%!important;
            min-height: auto!important;
        }
    
        /* Destinos landing */
        .promociones_content {
            margin-top: 4rem!important;
        }
    
        /* Contacto */
    
        .v-desktop{
            display: none!important;
        }
        .v-phone{
            display: block!important;
            visibility: visible!important;
        }
        .v-phone .content_contacto_info {
            display: block!important;
        }
        .v-phone .info_contacto_text {
            color: #707070!important;
            width: 100%!important;
            margin: 1rem!important;
        }
        .v-phone .content_contacto_map {
            width: 100%!important;
            padding: 1rem!important;
        }
        .v-phone .info_contacto_icon {
            font-size: 14px!important;
        }
        .v-phone .info_contacto_text p{
            font-size: 14px!important;
        }
        .v-phone .tab-container-contacto{
            display: none!important;
        }
        .v-phone .info_contacto_title {
            font-size: 1rem!important;
        }
        .horarios_ruta_horario {
            margin-top: 1rem!important;                
        }
        .tabs_routes_schedules {
            margin-bottom: 0rem!important;
        }
        .content_tables th {
            font-size: 17px!important;
        }
        .formulario_contacto_text {
            width: 100%!important;
        }
        .form_contact {
            display: block!important;
        }
        .formulario_contacto p {
            font-size: 16px!important;
            text-align: justify!important;
            word-spacing: 0.05em!important;
            text-justify: inter-word!important;
        }
        .form_contact form {
            width: 100%!important;
        }
        .box_contactform {
            display: block!important;;
        }
        .box_content-title h2 {
            font-size: 18px!important;
            font-weight: 500!important;
            color: #141414!important;
        }
        .formulario_contacto p {
            font-size: 16px!important;
            color: #545454!important;
        }
        .localizar_punto {
            width: 100%!important;
        }
        .box_group-card {
            padding: 10px 0px!important; 
        }
        .box_content_busqueda_punto button {
            padding: 5px 8px 5px 8px!important;            
        }
        .textPrepararViaje {
            padding: 15px 16px!important;
            min-height: auto!important;
            height: 230px!important;
        }
        .textPrepararViaje h4 {
            margin-top: 0px!important;
        }
        .box_prepararViaje p {
            font-size: 18px!important;
        }
        .textPrepararViaje p {
            font-size: 17px!important;
        }
       

        /**/
        .header{
            display: none!important;
        }
    
        .menu_mobile{
            display: block!important;
            width: 100%!important;
            background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%)!important;
            height: 60px!important;
        }
        
        .menu_mobile .navbar{
            width: 100%!important;
            height: 60px!important;
            justify-content: space-between!important;          
            display: flex!important;
            padding: 20px!important;
        }
        .menu_logo{
            width: 30%!important;
            justify-content: center!important;
            align-items: center!important;
            display: flex!important;
        }
        .menu_logo img{
            width: 100%!important;
        }
        .hamburger {
            display: block!important;
        }
        
        .navigation-menu ul {
            /* previous styles */
            display: none;
        }
    
        /* Menu Celular */
        .hamburger i{
            color: #fff;
            font-size: 25px;
        }
    
        .hamburger i.fi.fi-rr-cross-circle{
            color: #fff;
            font-size: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
            
        .navigation-menu .menu_mobile_list {
            border-top: none!important;
        }
        .navigation-menu li {
            text-align: left;
            margin: 0;
            padding: 1rem 2rem;
        }
        .navigation-menu.expanded li {
            padding: 1rem!important;
            font-size: 1.1rem!important;
        }
        /*
        .navigation-menu.expanded ul {
            display: block!important;
            background: #F7F7F7!important;
            z-index: 9!important;
            color: #002648!important;
        }*/
        .navigation-menu ul a{
            text-decoration: none!important;
            color: #002648!important;
        }
        /*
        .submenu-item {
            display: none!important;
        }
        */
        .submenu-item.active_submenu{
            display: block!important;
        }
        .submenu-item.active_submenu li a{
            font-size: 1.1rem!important;
            padding: 1rem!important;
        }
        .antes_de_tu_viaje .lista_temas{
            width: 100%;
        }
        .v-phone .box_group_card_promo{
            min-height: 160px!important;
        }
                        
    }
              
        /* Small devices (portrait tablets and large phones, 420px and up) */
      
        @media only screen and (max-width: 420px) {
    
            /* Menu */
            .header{
                display: none;
            }
        
            .menu_mobile{
                display: block;
                width: 100%;
                background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
                height: 60px;
            }
            .navbar{
                width: 100%;
                height: 60px;
                justify-content: space-between;          
                display: flex;
            }
                .menu_logo{
                width: 30%;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .menu_logo img{
                width: 100%;
            }
            .hamburger {
                display: block;
            }
            .menu_btn{
                justify-content: center;
                align-items: center;
                display: flex;
            }
            
            .navigation-menu ul {
                display: none;
            }
            .navigation-menu ul {
                position: absolute;
                top: 60px;
                left: 0;
                flex-direction: column;
                width: 100%;
                height: calc(100vh - 77px);
                background-color: white;
                border-top: 1px solid black;
            }
            .navigation-menu li {
                text-align: center;
                margin: 0;
            }
            .navigation-menu li a {
                width: 100%;
                padding: 1.5rem 0;
            }
            .navigation-menu li:hover {
                    background-color: #eee;
            }
            .navigation-menu ul {
                /* previous styles */
                display: none;
            }
        
            /* Menu Celular */
            .hamburger i{
                color: #fff;
                font-size: 25px;
            }
        
            .hamburger i.fi.fi-rr-cross-circle{
                color: #fff;
                font-size: 25px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
                
            .navigation-menu .menu_mobile_list {
                border-top: none!important;
            }
            .navigation-menu li {
                text-align: left;
                margin: 0;
                padding: 1rem 2rem;
            }
            .navigation-menu.expanded li {
                padding: 1rem!important;
                font-size: 1.1rem!important;
            }
            /*
            .navigation-menu.expanded ul {
                display: block!important;
                background: #F7F7F7!important;
                z-index: 9!important;
                color: #002648!important;
            }*/
            .navigation-menu ul a{
                text-decoration: none!important;
                color: #002648!important;
            }
            /*
            .submenu-item {
                display: none!important;
            }*/
            .submenu-item.active_submenu{
                display: block!important;
            }
            .submenu-item.active_submenu li a{
                font-size: 1.1rem!important;
                padding: 1rem!important;
            }
        
        
        
            /* Video Hero */
            .hero video{
                display: none!important;
            }
        
            /* Noticias */
            .noticias {
                width: 100%;
                display: block!important;
            }
        
            /* Imagen Hero 
            .hero .heroImg{
                display: block!important;
                width: 100!important;
                height: auto!important;
            }
            .heroImg img{
                min-height: 50vh!important;
                max-height: 80vh!important;
                width: 100%!important;
                filter: brightness(0.9);
            }
            */
            /*
            .content_hero_text {
                width: 100%;
                display: grid;
                justify-content: center!important;
                color: #fff;
                text-transform: uppercase;
            }
           
            .box_hero_text {
                display: inline-flex!important;
                align-items: center!important;
                height: 40px!important;
            }
            .box_hero_text h2 {
                font-size: 8vw!important;
                margin-right: 10px!important;
            }
            .box_hero_text label {
                font-size: 6vw!important;
            }*/
            
        
            /*Formulario Reservas */
        
            .boxBooking {
                top: 44%!important;
            }
            .formBooking {
                width: 100%!important;
            }
            .radioBooking {
                width: 100%!important;
            }
            .radioButons{
                width: 50%!important;
            }
            .contentBooking {
                width: 100%!important;
            }
            /* Reserva fija */
            .box_select_ruta_horario{
                
                margin: 5px 0px;
            }
        
        
            
            /* Formulario Fijo */
            .horizontal_form {
                display: grid!important;
                justify-content: center!important;
            }
            .box_select_ruta_horario{        
                margin: 5px 0px!important;
            }
        
        
            /* Valoracion* /
            .modal-content{
                width: 90%!important;
            }
            
            
            /* Promociones */
            .box_group_card_promo {
                height: 290px!important;
            }
            /* Spotify */
            .box_spotify {
                display: block!important;
            }
            .box_text_spotify {
                width: 100%!important;
            }
            .title_spotify {
                font-size: 2rem!important;
            }
            .icon_spotify {
                width: 25%!important;
                display: flex!important;
            }
            .text_spotify {
                font-size: 18px!important;
            }
            .text_block_spotify {
                margin-bottom: 2rem!important;
            }
            .list_icon_spotify img {
                width: 30px!important;
            }
            .content_frame_spotify {
                width: 100%!important;
                justify-content: center!important;
                display: flex!important;
                margin-top: 2rem!important;
            }
            .frame_spotify {
                width: 100%!important;
            }
        
            /* RSE */    
            .videoRSE video {
                display: none!important;
            }        
            .RSEImg{
                display: block!important;
            }
            .RSEImg img{
                width: 100%!important;
            }
            .videoRSE .RSElogo {
                width: 70%!important;
            }
        
            /* Tarifas */
            .tables_tarifas {
                
                margin-top: 0rem!important; 
            }
            .table_section {
                display: grid!important;
                margin-top: 0rem!important;
            }
            .tables {
                width: 100%!important;
                margin-top: 2rem!important;
            }
            .tables:nth-child(2) {
                margin-left: 0rem!important;;
            }
        
            /* Footer */
            footer .links {
                display: block!important;
            }
            footer .links_columna {
                display: block!important;
                padding: 10px 24px;
            }
        
            /*Rutas y horario */
            .tabsTabla{
                display: block!important;
            }
            .tablaRutasHorario {
                width: 100%!important;
            }
        
            .reservaBox {        
                width: 100%!important;
                padding: 20px 20px;
            }
        
            /* Call to Action */
        
            .ant-row{
                display: block;
            }
            .ant-col{
                max-width: 100%;
            }
            .contact-box-card {
                height: auto!important;
                min-height: 225px!important;
                margin-bottom: 0rem!important; 
            }
            .copyright{
                display: block!important;
            }
        
            .modal_valoracion .modal-content {
                width: 27%; 
            }
            
            /* Ruta y Horario */
            .box_content_mapa_ruta_horario {
                
            }
            
                /* Noticias */
                .box_mosaico_noticias {
                display: block!important;
            }
            .box_mosaico_block_izq {
                display: grid!important;
                width: 100%!important;
            }
            .box_mosaico_block_der {
                display: block!important;
                width: 100%!important;
            }
            .mosaico_box_2, .mosaico_box_3, .mosaico_box_4 {
                display: grid!important;
                width: 100%!important;
            }
            /* Promociones */
            .promociones {
                display: block!important;
            }
            .box_content_card_promo {        
                width: 100%!important;
            }
                /* Promociones */
            .promociones {
                display: block!important;
            }
            .box_content_card_promo, .content_box_promociones {        
                width: 100%!important;
            }
            .promociones_content {
                display: block!important;
                width: 100%!important;
            }
            .box_content_promo {
                width: 100%!important;
                min-height: auto!important;
            }
                /* Destinos landing */
                .promociones_content {
                margin-top: 4rem!important;
            }
            /* Contacto */
            .v-desktop{
                display: none!important;
            }
            .v-phone{
                display: block!important;
            }
            .v-phone .content_contacto_info {
                display: block!important;
            }
            .v-phone .info_contacto_text {
                color: #707070!important;
                width: 100%!important;
                margin: 1rem!important;
            }
            .v-phone .content_contacto_map {
                width: 100%!important;
                padding: 1rem!important;
            }
            .v-phone .info_contacto_icon {
                font-size: 14px!important;
            }
            .v-phone .info_contacto_text p{
                font-size: 14px!important;
            }
            .v-phone .tab-container-contacto{
                display: none!important;
            }
            .v-phone .info_contacto_title {
                font-size: 1rem!important;
            }
            /*
            .routes_schedules {
                display: block!important;
            }
            .box_content_ruta_horario {
                width: 100%!important;
            }
            .info_ruta_title_services {
                display: block!important;            
            }
            .day_list {
                padding: 0px 5px 0px 5px!important;            
            }
    
            .numeroDia, .diaAbreviado {
                font-size: 12px!important;
            }
            .btn_ruta_horario button {
                font-size: 15px!important;
                margin-top: 1rem!important;
                margin-bottom: 2rem!important;
            }
            .horarios_list {
                width: 100%!important;
                margin: 0px 0px 0px 0px!important;
            }
            .horarios_ruta_horario {
                margin-top: 1rem!important;                
            }
            .box_content_mapa_ruta_horario {
                padding: 0px 0px!important; 
            }
            .content_tables th {
                font-size: 17px!important;
            }
            .boxBooking {
                top: 40%!important;
            }
            */
            /*
            .hero_text {
                /*top: 9%!important;*/
            }
            /*
            .heroImg img {
                max-height: 100vh!important;
            }*/
            /*
            .box_hero_text h2 {
                font-size: 5vw!important;
            }
            .box_hero_text label {
                font-size: 4vw!important;
            }*/
            input[type="radio"] {
                width: 15px!important;
                height: 15px!important;
            }
            .radioButons {
                font-size: 14px!important;
            }
            .box_formOrigen label, .box_formDestino label, .box_fechaSalida label, .box_fechaRegreso label, .box_fechaSoloIda label {
                font-size: 13px!important;
            }
            .box_formOrigen select, .box_formDestino select {
                height: 21px!important;
                font-size: 15px!important;
            }
            .btnBuscar button {
                height: 45px!important;
            } 
            .v-phone 
            .box_content_title h1{
                font-size: 17px!important;
            }
            .v-phone 
            .box_content_title h1, 
            .v-phone 
            .box_content_title p{
                display: flex!important;
                justify-content: end!important;
            }   
            .v-phone .box_content_promo {
                padding: 0px 10px!important;
            }
           

        
            
        
          
            /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (max-width: 600px) {
        
               /* Menu */
            .header{
                display: none;
            }
        
            .menu_mobile{
                display: block;
                width: 100%;
                background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
                height: 60px;
            }
            .navbar{
                width: 100%;
                height: 60px;
                justify-content: space-between;          
                display: flex;
            }
             .menu_logo{
                width: 30%;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .menu_logo img{
                width: 100%;
            }
            .hamburger {
                display: block;
            }
            .menu_btn{
                justify-content: center;
                align-items: center;
                display: flex;
            }
           
            /*.navigation-menu .menu_mobile_list {
                display: none;
            } */
            .navigation-menu .menu_mobile_list {
                position: absolute;
                top: 70px;
                left: 0;
                flex-direction: column;
                width: 100%;
                height: calc(100vh - 77px);
                background-color: white;
                border-top: 1px solid black;
            }
            .navigation-menu li {
                text-align: center;
                margin: 0;
            }
            .navigation-menu li a {
                color: rgb(250, 101, 101);
                width: 100%;
                padding: 1.5rem 0;
            }
            .navigation-menu li:hover {
                background-color: #eee;
            }
            .navigation-menu .menu_mobile_list {
                /* previous styles */
                display: none;
            }
        
            
             /* Menu Celular */
             .hamburger i{
                color: #fff;
                font-size: 25px;
        
            }
        
            .hamburger i.fi.fi-rr-cross-circle{
                color: #fff;
                font-size: 25px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .navigation-menu .menu_mobile_list {
                border-top: none!important;
            }
            .navigation-menu.expanded .menu_mobile_list {
                display: block!important;
                background: #F7F7F7!important;
                z-index: 9!important;
                color: #002648!important;
            }
            .navigation-menu .menu_mobile_list a{
                text-decoration: none!important;
                color: #002648!important;
        
            }
            .navigation-menu li {
                text-align: left;
                margin: 0;
                padding: 1rem 2rem;
            }
            /*
            .submenu-item {
                display: none!important;
            }*/
            .submenu-item.active_submenu{
                display: block!important;
            }
            .submenu-item li{
                padding: .5rem 2rem!important;
            }
        
            /* Valoracion */
            .modal-content{
                width: 90%!important;
            }
            
           
            .hero video{
                display: none!important;
            }
            /*
            .hero .heroImg{
                display: block!important;
                width: 100!important;
                height: auto!important;
            }
            .heroImg img{
                min-height: 50vh!important;
                max-height: 80vh!important;
                width: 100%!important;
                filter: brightness(0.9);
            }*//*
            .content_hero_text {
                width: 100%;
                display: grid;
                justify-content: center!important;
                color: #fff;
                text-transform: uppercase;
            }
            
            .box_hero_text {
                display: inline-flex!important;
                justify-content: center!important;
                height: 40px!important;
            }
            .box_hero_text h2 {
                font-size: 8vw!important;
                margin-right: 10px!important;
            }
            .box_hero_text label {
                font-size: 6vw!important;
            }*/
        
             /*Formulario Reservas */
             .boxBooking {
                top: 44%!important;
            }
            .formBooking {
                width: 100%!important;
            }
            .radioBooking {
                width: 100%!important;
            }
            .radioButons{
                width: 50%!important;
            }
            .contentBooking {
                width: 100%!important;
                justify-content: space-between;
            }
              /* Promociones */
            .box_group_card_promo {
                height: 290px!important;
                margin-bottom: 1.5rem;        
            }
            /* Spotify */
            .box_spotify {
              display: block!important;
            }
            .box_text_spotify {
              width: 100%!important;
            }
              .title_spotify {
                font-size: 2rem!important;
            }
            .icon_spotify {
              width: 25%!important;
              display: flex!important;
            }
            .text_spotify {
              font-size: 18px!important;
            }
            .text_block_spotify {
              margin-bottom: 2rem!important;
            }
            .list_icon_spotify img {
              width: 30px!important;
            }
            .content_frame_spotify {
                width: 100%!important;
                justify-content: center!important;
                display: flex!important;
                margin-top: 2rem!important;
            }
            .frame_spotify {
                width: 100%!important;
        
            }
          
             /* RSE */
        
             .videoRSE video {
                display: none!important;
             }
         
            
             .RSEImg{
                 display: block!important;
             }
             .RSEImg img{
                width: 100%;
             }
             .videoRSE .RSElogo {
                 width: 70%!important;
             }
        
             /* Noticias */
             .noticias {
                width: 100%;
                display: block!important;
            }
            
        
            /* Footer */
            footer .links {
                display: block!important;
            }
            footer .links_columna {
                display: block!important;
                padding: 10px 24px;
            }
        
            /*Rutas y horario */
            .tabsTabla{
                display: block!important;
            }
            .tablaRutasHorario {
                width: 100%!important;
            }
        
            .reservaBox {        
                width: 100%!important;
                padding: 20px 20px;
            }
        
            /* Call to Action */
        
            .ant-row{
                display: block;
            }
            .ant-col{
                max-width: 100%;
            }
            .contact-box-card {
                height: auto!important;
                min-height: 225px!important;
                margin-bottom: 0rem!important; 
            }
            .copyright{
                display: block!important;
            }
            
            /* Call to Action */
        
            .callToAction .ant-row{
                display: block;
            }
        
            .modal_valoracion .modal-content {
                width: 27%; 
            }
            
             /* Noticias */
             .box_mosaico_noticias {
                display: block!important;
            }
            .box_mosaico_block_izq {
                display: grid!important;
                width: 100%!important;
            }
            .box_mosaico_block_der {
                display: block!important;
                width: 100%!important;
            }
            .mosaico_box_2, .mosaico_box_3, .mosaico_box_4 {
                display: grid!important;
                width: 100%!important;
            }
               /* Promociones */
               .promociones {
                display: block!important;
            }
            .box_content_card_promo {        
                width: 100%!important;
            }
              /* Promociones */
              .promociones {
                display: block!important;
            }
            .box_content_card_promo, .content_box_promociones {        
                width: 100%!important;
            }
            .promociones_content {
                display: block!important;
                width: 100%!important;
            }
            .box_content_promo {
                width: 100%!important;
                min-height: auto!important;
            }
             /* Destinos landing */
             .promociones_content {
                margin-top: 4rem!important;
            }
            /* Contacto */
            .v-desktop{
                display: none!important;
            }
            .v-phone .content_contacto_info {
                display: block!important;
            }
            .v-phone .info_contacto_text {
                color: #707070!important;
                width: 100%!important;
                margin: 1rem!important;
            }
            .v-phone .content_contacto_map {
                width: 100%!important;
                padding: 1rem!important;
            }
            .v-phone .info_contacto_icon {
                font-size: 14px!important;
            }
            .v-phone .info_contacto_text p{
                font-size: 14px!important;
            }
            .v-phone .tab-container-contacto{
                display: none!important;
            }
            .v-phone .info_contacto_title {
                font-size: 1rem!important;
            }
            /*
            .routes_schedules {
                display: block;
            }
            .box_content_ruta_horario {
                width: 100%;
            }
            .info_ruta_title_services {
                display: block;            
            }
            .day_list {
                padding: 0px 5px 0px 5px;            
            }
            .day_list {
                display: grid;
                width: 100%;
                padding: 0px 15px 0px 15px;
                cursor: pointer;
            }
            .numeroDia, .diaAbreviado {
                font-size: 12px;
            }
            .btn_ruta_horario button {
                font-size: 15px;
                margin-top: 1rem;
                margin-bottom: 2rem;
            }
            .horarios_list {
                width: 100%;
            }
        */
            
        }
        
          
            /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (max-width: 768px) {
        
                /* Menu 
        
                .header{
                    display: none;
                }
        
                .menu_mobile{
                    display: block;
                    width: 100%;
                    background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
                    height: 70px;
                }
                .navbar{
                    width: 100%;
                    height: 70px;
                    justify-content: space-between;          
                    display: flex;
                }
                .menu_logo{
                    width: 30%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                .menu_logo img{
                    width: 100%;
                }
                .hamburger {
                    display: block;
                    width: 40px;
                    background: transparent;
                    border: none;
                    height: 40px;
                }
                .menu_btn{
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 50px;
                    height: 100%;
                }
                
                /* Call to Action 
        
                .callToAction .ant-row{
                    display: block;
                }
        
                /* Contacto 
                .v-phone{
                    display: none;
                }
                */
                
               /* Menu */
            .header{
                display: none;
            }
        
            .menu_mobile{
                display: block;
                width: 100%;
                background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
                height: 60px;
            }
            .navbar{
                width: 100%;
                height: 60px;
                justify-content: space-between;          
                display: flex;
            }
             .menu_logo{
                width: 30%;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .menu_logo img{
                width: 100%;
            }
            .hamburger {
                display: block;
            }
            .menu_btn{
                justify-content: center;
                align-items: center;
                display: flex;
            }
           
            /*.navigation-menu .menu_mobile_list {
                display: none;
            }*/
            .navigation-menu .menu_mobile_list {
                position: absolute;
                top: 60px;
                left: 0;
                flex-direction: column;
                width: 100%;
                height: calc(100vh - 77px);
                background-color: white;
                border-top: 1px solid black;
            }
            .navigation-menu li {
                text-align: center;
                margin: 0;
            }
            .navigation-menu li a {
                color: rgb(250, 101, 101);
                width: 100%;
                padding: 1.5rem 0;
            }
            .navigation-menu li:hover {
                background-color: #eee;
            }
            .navigation-menu .menu_mobile_list {
                /* previous styles */
                display: none;
            }
        
            
             /* Menu Celular */
             .hamburger i{
                color: #fff;
                font-size: 25px;
        
            }
        
            .hamburger i.fi.fi-rr-cross-circle{
                color: #fff;
                font-size: 25px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .navigation-menu .menu_mobile_list {
                border-top: none!important;
            }
            .navigation-menu.expanded .menu_mobile_list {
                display: block!important;
                background: #F7F7F7!important;
                z-index: 9!important;
                color: #002648!important;
            }
            .navigation-menu .menu_mobile_list a{
                text-decoration: none!important;
                color: #002648!important;
        
            }
            .navigation-menu li {
                text-align: left;
                margin: 0;
                padding: 1rem 2rem;
            }
            /*
            .submenu-item {
                display: none!important;
            }*/
            .submenu-item.active_submenu{
                display: block!important;
            }
            .submenu-item li{
                padding: .3rem 0rem!important;
            }
        
            /* Valoracion */
            .modal-content{
                width: 90%!important;
            }
            
           
            .hero video{
                display: none!important;
            }
            .hero .heroImg{
                display: block;
                width: 100%;
                height: auto!important;
            }
            .heroImg img{
                width: 100%;
                min-height: 75vh;
                max-height: 80vh;                
                filter: brightness(0.9);
            }/*
            .content_hero_text {
                width: 100%;
                display: grid;
                justify-content: center!important;
                color: #fff;
                text-transform: uppercase;
            }
            
            .box_hero_text {
                display: inline-flex!important;
                justify-content: center!important;
                height: 40px!important;
            }
            .box_hero_text h2 {
                font-size: 8vw!important;
                margin-right: 10px!important;
            }
            .box_hero_text label {
                font-size: 6vw!important;
            }*/
        
             /*Formulario Reservas */
             .boxBooking {
                top: 44%!important;
            }
            .formBooking {
                width: 100%!important;
                justify-content: center;
                display: flex;
            }
            .box_radio_booking {
                display: flex;
                width: 85%!important;
                justify-content: space-between;
            }
            .radioBooking {
                width: 100%!important;
            }
            .radioButons{
                width: 50%!important;
            }
                .contentBooking {
                width: 100%!important;
            }
        
        
        
              /* Promociones */
            .box_group_card_promo {
                height: 290px!important;
                margin-bottom: 1.5rem;
        
            }
            /* Spotify */
            .box_spotify {
              display: block!important;
            }
            .box_text_spotify {
              width: 100%!important;
            }
              .title_spotify {
                font-size: 2rem!important;
            }
            .icon_spotify {
              width: 25%!important;
              display: flex!important;
            }
            .text_spotify {
              font-size: 18px!important;
            }
            .text_block_spotify {
              margin-bottom: 2rem!important;
            }
            .list_icon_spotify img {
              width: 30px!important;
            }
            .content_frame_spotify {
                width: 100%!important;
                justify-content: center!important;
                display: flex!important;
                margin-top: 2rem!important;
            }
            .frame_spotify {
                width: 100%!important;
        
            }
          
             /* RSE */
        
             .videoRSE video {
                display: none!important;
             }
         
            
             .RSEImg{
                 display: block!important;
                 position: relative;
             }
             .RSEImg img{
                width: 100%;
             }
             .videoRSE .RSElogo {
                 width: 70%!important;
             }
        
             /* Noticias */
             .noticias {
                width: 100%;
                display: block!important;
            }
            
        
            /* Footer */
            footer .links {
                display: block!important;
            }
            footer .links_columna {
                display: block!important;
                padding: 10px 24px;
            }
        
            /*Rutas y horario */
            .tabsTabla{
                display: block!important;
            }
            .tablaRutasHorario {
                width: 100%!important;
            }
        
            .reservaBox {        
                width: 100%!important;
                padding: 20px 20px;
            }
        
            /* Call to Action */
        
            .ant-row{
                display: block;
            }
            .ant-col{
                max-width: 100%;
            }
            .contact-box-card {
                height: auto!important;
                min-height: 225px!important;
                margin-bottom: 0rem!important; 
            }
            .copyright{
                display: block!important;
            }
            
            /* Call to Action */
        
            .callToAction .ant-row{
                display: block;
            }
        
            .modal_valoracion .modal-content {
                width: 27%; 
            }
            
             /* Noticias */
             .box_mosaico_noticias {
                display: block!important;
            }
            .box_mosaico_block_izq {
                display: grid!important;
                width: 100%!important;
            }
            .box_mosaico_block_der {
                display: block!important;
                width: 100%!important;
            }
            .mosaico_box_2, .mosaico_box_3, .mosaico_box_4 {
                display: grid!important;
                width: 100%!important;
            }
               /* Promociones */
               .promociones {
                display: block!important;
            }
            .box_content_card_promo {        
                width: 100%!important;
            }
              /* Promociones */
              .promociones {
                display: block!important;
            }
            .box_content_card_promo, .content_box_promociones {        
                width: 100%!important;
            }
            .promociones_content {
                display: block!important;
                width: 100%!important;
            }
            .box_content_promo {
                width: 100%!important;
                min-height: auto!important;
            }
             /* Destinos landing */
             .promociones_content {
                margin-top: 4rem!important;
            }
            /* Contacto */
            .v-desktop{
                display: none!important;
            }
            .v-phone .content_contacto_info {
                display: block!important;
            }
            .v-phone .info_contacto_text {
                color: #707070!important;
                width: 100%!important;
                margin: 1rem!important;
            }
            .v-phone .content_contacto_map {
                width: 100%!important;
                padding: 1rem!important;
            }
            .v-phone .info_contacto_icon {
                font-size: 14px!important;
            }
            .v-phone .info_contacto_text p{
                font-size: 14px!important;
            }
            .v-phone .tab-container-contacto{
                display: none!important;
            }
            .v-phone .info_contacto_title {
                font-size: 1rem!important;
            }
            .formBooking_container {
                max-width: 375px;
            }
            .modalAuto, .modalPasajero {
                width: 100%;
            }

            .sidebar {
                display: none;
            }
            
            
        } 
        @media only screen and (max-width: 820px){
            .menu_items li a, .menu_icons li a {
                text-decoration: none;
                font-size: 11px;
                display: flex;
                color: #fff;
                /* font-weight: 600; */
                font-family: 'Poppins', sans-serif;
                justify-content: space-between;
            }
            .menu_img_show {
                background-image: url(http://localhost:3000/static/media/logo.80c14aa….png);
                width: 70px;
                height: 60px;
                background-position: center center;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            .menu_items li {
                padding: 7px 5px;
                position: relative;
                display: block;
                /* margin: 0px 6px; */
            }
            
        }
          
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (max-width: 992px) {
            .menu_img_show {
                background-image: url(http://localhost:3000/static/media/logo.80c14aa….png);
                width: 80px;
                height: 60px;
                background-position: center center;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            .menu_items {
                margin-left: 55px;
            }
            .menu_items li a, .menu_icons li a {
                text-decoration: none;
                font-size: 12px;
                display: flex;
                color: #fff;
                /* font-weight: 600; */
                font-family: 'Poppins', sans-serif;
                justify-content: space-between;
            }

            .menu_mobile{
                display: none;
            }
            .header{
                display: block;
            }
            @import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
            .formBooking {
                width: 50%;
            }
            
            /* RADIO BUTONS */
            .radioBooking {
            
                display: flex;
                justify-content: space-between;
                color: #fff;
                font-size: 18px;
                width: 56%;
            }
            .radioButons{
                display: flex;
                align-items: center;
            }
            
            input[type="radio"] {
                width: 20px;
                height: 20px;
                margin-right: 7px;
            }
            
            .contentBooking{
                display: inline-flex;
                width: 80%;
            }
            .box_formOrigen, .box_formDestino, 
            .box_fechaSalida, .box_fechaRegreso{
                width: 50%;
            }
            .box_fechaSoloIda{
                width: 100%;
            }
            .box_formOrigen, .box_fechaSalida {
                padding: 1px;
                background: #fff;
                border: none;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-right: 1px solid #767676;
            }
            .box_fechaSoloIda{
                padding: 1px;
                background: #fff;
                border: none;
                border-radius: 4px;
            
            }
            .box_fechaSalida, .box_fechaRegreso, .box_fechaSoloIda{
                margin-top: 5px;
            }
            .box_formOrigen label, .box_formDestino label,
            .box_fechaSalida label, .box_fechaRegreso label, 
            .box_fechaSoloIda label{
                display: block;
                color: #141414;
                padding: 0px 20px;
                margin-top: 4px;
            }
            .box_formOrigen select, .box_formDestino select{
                height: 25px;
                width: 100%;
                padding: 0px 20px;
                border: none;
                border-radius: 3px;
                -webkit-appearance: none;
                -moz-appearance: none;
                outline:none;
                font-size: 16px;
            }
            
            .box_formDestino, .box_fechaRegreso{
                padding: 1px;
                background: #fff;
                border: none;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-left: 1px solid #767676;
            }
            
            .box_formDestino{
                margin-left: -15px;
            }
            
            .box_fechaSalida input, .box_fechaRegreso input,
            .box_fechaSoloIda input{
                height: 30px;
                width: 100%;
                padding: 6px 20px;
                border: none;
                border-radius: 3px;
                -webkit-appearance: none;
                -moz-appearance: none;
                outline:none;
                font-size: 16px;
            }
            
            .ant-picker.css-dev-only-do-not-override-w8mnev {
                width: 100%;
                border: none;
            }
            :where(.css-dev-only-do-not-override-w8mnev).ant-picker-focused {
                border-color: #4096ff;
                box-shadow: none; 
                border-inline-end-width: 1px;
                outline: 0;
                border: none;
            }
            :where(.css-dev-only-do-not-override-w8mnev).ant-picker .ant-picker-input >input {
                font-size: 11px!important;
                padding: 0px 10px;
            }
            /* BOTON INTERCAMBIAR <-> */
            
            .box_formButton{
                margin-left: -15px;
                z-index: 5;
            }
            
            .box_formButton button{
                /*margin-top: 17px;*/
                margin-top: 11px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                border: 1px solid #767676;
                background-color: #fff;
            }
            .box_formButton button i{
                font-size: 13px;
                justify-content: center;
                align-items: center;
                display: flex;
                color: #4096ff;
            }
            
            /* BOTON BUSCAR */
            
            .btnBuscar button{
                height: 55px;
                width: 100%;
                border-radius: 4px;
                border: none;
                color: #fff;
            z-index: 5;
            }
            
            span.anticon.anticon-arrow-right{
                background: #ffffff95;
                padding: 2px;
                border-radius: 15px;
                width: 30px;
                height: 30px;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                align-content: center;
                display: flex;
            }
            .btnBuscar .icon {
                color: #ffffffb5;
                position: absolute;
                width: 20px;
                height: 20px;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
            .btnBuscar .icon  i.fi.fi-rr-arrow-right {
                color: #FFF;
                font-size: 19px;
                font-weight: 500;
                justify-content: center;
                align-items: end;
                display: flex;
                /*
                background: rgba(255,255,255,.4);
                padding: 5px;
                border-radius: 22px; */
            }
            
            
            .contentBooking .anticon svg{
                display: inline-block;
                color: #0066BF;
            }
            
            span.anticon.anticon-arrow-right{
                background: #ffffff95;
                padding: 2px;
                border-radius: 15px;
                width: 30px;
                height: 30px;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                align-content: center;
                display: flex;
            }
            
            .btnBuscar{
                position: relative;
                width: 80%;
                margin-top: 5px;
            }
            button.btnBuscarViaje {
                background: #FF8F0095;
                border-radius: 4px;
            }
            .formBooking .textPlaceholder{
                
                display: flex;
                color: #fff;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                width: 100%;
                justify-content: space-between;
                padding: 0 20px;
                align-items: center;
                font-size: 17px;
                font-weight: 500;
                height: 100%;
            
            }
            
            
            
            .btnBuscar button:hover{
                background: #FF8F00;
            }
            
            .textPlaceholder:hover{
                background: #FF8F00;
                border-radius: 4px;
            }
            
            .textPlaceholder .icon{
                display: contents;
            }
            /*
            input::placeholder {
                color: #fff;
                font-size: 1.5em;
            }*/
            
            span.anticon.anticon-swap-right {
                border-radius: 15px;
                background: #fff;
                padding: 5px;
            }
            .modal_valoracion .modal-content {
                width: 26%!important;
            }
            .v-phone {
                display: none;
            }

            .modalAuto, .modalPasajero {
                width: 100%!important;
            }

            .navbar-item > .navbar-link:after {
                display: none!important;
            }
            .sidebar ul ul li a {
                font-size: 17px;
            }
            
            .frame_spotify {
                width: 75%!important;
            }
        } 
            
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 993px) {
        .menu_mobile{
            display: none;
        }
        .header{
            display: block;
        }
        @import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
        .formBooking {
            /*width: 50%;*/
            width: 42%;
        }
        
        /* RADIO BUTONS */
        .radioBooking {
        
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-size: 15px;
            /*width: 59%;*/
            width: 80%;
            margin-bottom: 4px;
        }
        .radioButons{
            display: flex;
            align-items: center;
        }
        
        input[type="radio"] {
            width: 17px;
            height: 17px;
            margin-right: 5px;
        }
        
        .contentBooking{
            display: inline-flex;
            width: 80%;           
            justify-content: space-between; 
        }
        .box_formOrigen, .box_formDestino, 
        .box_fechaSalida, .box_fechaRegreso{
            width: 50%;
        }
        .box_fechaSoloIda{
            width: 100%;
        }
        .box_formOrigen, .box_fechaSalida {
            padding: 1px;
            background: #fff;
            border: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 1px solid #767676;
            background: #ffffff99;
        }
        .box_formOrigen.focus{
            background: #fff;
        }
        .box_formDestino.focus{
            background: #fff;
        }
        .box_fechaSalida.focus, 
        .box_fechaRegreso.focus, 
        .box_fechaSoloIda.focus{
            background: #fff;
        }
        .box_fechaSoloIda{
            padding: 1px;
            background: #fff;
            border: none;
            border-radius: 4px;
        
        }
        .box_fechaSalida, .box_fechaRegreso, .box_fechaSoloIda{
            margin-top: 5px;
        }
        .box_formOrigen label, .box_formDestino label,
        .box_fechaSalida label, .box_fechaRegreso label, 
        .box_fechaSoloIda label{
            display: block;
            color: #747474;
            padding: 0px 20px;
            margin-top: 4px;
            font-size: 14px;
        }
        .box_formOrigen select, .box_formDestino select{
            height: 25px;
            width: 100%;
            padding: 0px 20px;
            border: none;
            border-radius: 3px;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline:none;
            font-size: 16px;
            background: transparent;
            cursor: pointer;
        }
        
        .box_formDestino, .box_fechaRegreso{
            padding: 1px;
            background: #fff;
            border: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 1px solid #767676;
            background: #ffffff99;
        }
        
        .box_formDestino{
            /*margin-left: -15px;*/
            margin-left: -12px;
        }
        
        .box_fechaSalida input, .box_fechaRegreso input,
        .box_fechaSoloIda input{
            height: 30px;
            width: 100%;
            padding: 6px 20px;
            border: none;
            border-radius: 3px;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline:none;
            font-size: 16px;
        }
        
        .ant-picker.css-dev-only-do-not-override-w8mnev {
            width: 100%;
            border: none;
        }
        :where(.css-dev-only-do-not-override-w8mnev).ant-picker-focused {
            border-color: #4096ff;
            box-shadow: none; 
            border-inline-end-width: 1px;
            outline: 0;
            border: none;
        }
        :where(.css-dev-only-do-not-override-w8mnev).ant-picker .ant-picker-input >input {
            font-size: 11px!important;
            padding: 0px 10px;
        }
        /* BOTON INTERCAMBIAR <-> */
        
        .box_formButton{
            /*margin-left: -15px;*/
            margin-left: -12px;
            z-index: 5;
        }
        
        .box_formButton button{
            margin-top: 17px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            border: 1px solid #767676;
            background-color: #fff;
            margin-top: 13px;
        }
        .box_formButton button i{
            font-size: 13px;
            justify-content: center;
            align-items: center;
            display: flex;
            color: #4096ff;
        }
        
        /* BOTON BUSCAR */
        
        .btnBuscar button{
            height: 55px;
            width: 100%;
            border-radius: 4px;
            border: none;
            color: #fff;
        z-index: 5;
        }
        
        span.anticon.anticon-arrow-right{
            background: #ffffff95;
            padding: 2px;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            align-content: center;
            display: flex;
        }
        .btnBuscar .icon {
            /*color: #191919;*/
            color: #ffffffbf;
            position: absolute;
            width: 20px;
            height: 20px;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
        .btnBuscar .icon  i.fi.fi-rr-arrow-right {
            color: #FFF;
            font-size: 19px;
            font-weight: 500;
            justify-content: center;
            align-items: end;
            display: flex;
            /*
            background: rgba(255,255,255,.4);
            padding: 5px;
            border-radius: 22px;*/
        }
        
        
        .contentBooking .anticon svg{
            display: inline-block;
            color: #0066BF;
        }
        
        span.anticon.anticon-arrow-right{
            background: #ffffff95;
            padding: 2px;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            align-content: center;
            display: flex;
        }
        
        .btnBuscar{
            position: relative;
            width: 80%;
            margin-top: 7px;
        }
        button.btnBuscarViaje {
            background: #FF8F0095;
            border-radius: 4px;
        }
        .formBooking .textPlaceholder{
            
            display: flex;
            color: #fff;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            width: 100%;
            justify-content: space-between;
            padding: 0 20px;
            align-items: center;
            font-size: 17px;
            font-weight: 500;
            height: 100%;
        
        }    
        
        .btnBuscar button:hover{
            background: #FF8F00;
        }
        
        .textPlaceholder:hover{
            background: #FF8F00;
            border-radius: 4px;
        }
        
        .textPlaceholder .icon{
            display: contents;
        }
        /*
        input::placeholder {
            color: #fff;
            font-size: 1.5em;
        }*/
        
        span.anticon.anticon-swap-right {
            border-radius: 15px;
            background: #fff;
            /*padding: 5px;*/
        }
        /*
        .modal_valoracion .modal-content {
            width: 26%!important;
        } 
        .v-phone {
            display: none;
        }
          
        */ 
        
        }  
        @media (max-width: 952px) {
            .menu_img_show {
                width: 80px;

            }
            .menu_items {
                margin-left: 0px;
            }
          
        }    
            
        
    @media only screen and (min-width: 600px) {
        /* For tablets: */
    
      }
    @media only screen and (min-width: 768px) {
    /* For desktop: */
    
    
    }

    @media (min-width: 768px) and (max-width: 992px) {
        /* Sidebar */
    .blockAsidebar_first {                
        width: 100%;
        display: block;
    }
    .blockAsidebar_second {
        display: block;
        width: 100%;
    }


    /* Ipad */
    .hero_text {
        position: absolute;
        top: 27%;
        width: 100%;
    }
    .content_hero_text {
        display: flex;
        justify-content: end;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .box_hero_text label {
        font-weight: 400;
        justify-content: end;
        display: flex;
        opacity: 0.8;
        line-height: 35px;
    }
    .boxBooking {
        position: absolute;
        top: 56%;
        /* top: 59%; */
        width: 100%;
        z-index: 9;
    }
    .formBooking {
        width: 50%;
    }
    .radioBooking {
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: 18px;
        width: 100%;
    }
    .box_radio_booking {
        display: flex;
        /* width: 55%; */
        width: 55%;
        justify-content: space-between;
    }            
    .btnBuscar {
        position: relative;
        width: 80%;
        margin-top: 5px;
    }
    .contentBooking {
        display: inline-flex;
        width: 100%;
    }
    .btnBuscar {
        position: relative;
        width: 100%;
        margin-top: 5px;
    }
    .menu_img_show {
        background-image: url('../assets/images/logo.png');
        width: 85px;
        height: 60px;
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    }

    /* RSE HOME */
    @media (min-width: 769px) and (max-width: 818px){
        .videoRSE .RSElogo {
            max-width: 400px!important;
        }
    }