.noticia_list{
    padding: 6rem 0rem;
}
.text_noticias_list{
    text-align: center;
}
.table-noticias img{
    width: 200px;
    height: auto;
}
td.title{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
td.descrption{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
button.new{    
    background: #007BFF;
    border: 2px solid #007BFF;
    color: #fff;
    padding: .3rem .5rem;
}
button.new:hover{    
    background: #fff;
    color: #007BFF;
    border: 2px solid #007BFF;
}
button.editar{
    display: flex;
    background: #28A744;
    color: #fff;
    border: 2px solid #28A744;
    padding: .3rem .5rem;
}
button.editar:hover{
    background: #fff;
    border: 2px solid #28A744;
    color: #28A744;
}
button.eliminar{
    display: flex;
    background: #DC3545;
    border: 2px solid #DC3545;
    color: #fff;
    padding: .3rem .5rem;
}
button.eliminar:hover{
    background: #fff;
    border: 2px solid #DC3545;
    color: #DC3545;
}
button.eliminar i,
button.editar i{
    display: flex;
    align-items: center;
    margin-right: 5px;
}