.itinerario{
    background: #EEEEEE;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
table.tabla_itinerario_sin_vijes {
    width: 100%;
}
table.table_itinerario {
    width: 100%;
}
.itinerario_name_ruta{
    font-size: 18px;
    color: #12406F;
    font-weight: 600;
    padding: 0rem 0rem 1rem;
}
.itinerario_name_ruta:nth-child(2){
    margin-top: 1rem;
}
.itinerario_name_ruta span{
    border: 1px solid #12406F;
    margin: 0rem .7rem;
}
.itinerario_name_ruta label:nth-child(2){
    margin: 0rem .7rem;
    vertical-align: middle;
    display: inline-flex;
}
.itinerario_name_ruta i{
    display: contents;
}
.content-itinerario{
    background-color: #fff;
}
.itinerario_tabla th{
    color: #12406F;
    font-weight: 600;
    font-size: 18px; 
    padding: 1rem 0rem;
    border-bottom: 1px solid #12406F;
}
.table.table_itinerario {
    width: 100%;
}
.itinerario_tabla td{
    color: #7C7C7C;
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem 0rem;
  }
  .box_second_itinerario{
    margin-top: 3rem;
  }


.notas_itinerario{
    margin-top: 3rem;
}
.notas_itinerario ul{
    margin-left: 0rem;
    padding-left: 0rem;
}





/* Vije en curso phone */

.itinerario_content{
    display: flex;
    background-color: #fff;
    padding: 1rem;
}
.itinerario_box{
    width: 50%;
}
.itinerario_box h3{
    font-size: 12px;
    color: #7C7C7C;
    font-weight: 500;
    margin-bottom: .3rem;
}
.itinerario_box label{
    font-size: 16px;
    color: #545454;
    font-weight: 500;
    margin-bottom: 1rem;
}
.itinerairo_phone{
    padding: 2rem 0rem;
    background: #EEEEEE;
}

@media (max-width: 768px){
    
    .itinerario{
        padding: 2rem 0rem 0rem;
    }
    .itinerario_name_ruta{
        font-size: 16px;
        padding: 0px;
    }
    .itinerario_name_ruta:nth-child(2){
        font-size: 14px;
        color: #7C7C7C;
        margin: .3rem 0rem .8rem;
        font-weight: 400;
    }
    .notas_itinerario{
        font-size: 14px;
    }
    .notas_itinerario li{
        margin-top: 1rem;
    }
}

