.horizontal-scroll-container {
  /*width: 300px;*/ /* Establece el ancho del contenedor */
    width: 100%;
    overflow-x: auto; /* Hace que el desbordamiento horizontal sea visible solo si es necesario */
    white-space: nowrap; /* Evita el retorno de línea para que los elementos se muestren en una línea */
  }
  .horizontal-scroll-container::-webkit-scrollbar {
    display: none;
  }
  .scroll-content {
    display: flex; /* Hace que los elementos internos se muestren en una fila */
  }
  .scroll-item-promociones {
    width: 90%;
    /*min-width: 120px; *//* Establece el ancho mínimo de cada elemento */
    min-width: 90%;
    margin-right: 8px; /* Añade un margen entre los elementos (opcional) */
  }
  .box_promocion_phone{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .box_promocion_phone p{
    width: 100%;
    white-space: normal;
    font-size: 14px;
    margin-bottom: 0px;

    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    filter: drop-shadow(2px 4px 6px black);
    padding: 5px;
  }
  .box_promocion_phone p:nth-child(3){
    font-size: 12px;
    display: none;
  }
  .scroll-item-promociones img{
    width: 100%;
    min-height: 185px;

    border-radius: 3px;
  }
  .promociones_title_home_phone h2 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
.promociones_title_home_phone {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.scroll-item a{
  text-decoration: none;
}
.box_promociones_text_phone {
  padding: 5px;
  border: 1px solid #ccc;
  min-height: 70px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.box_promociones_text_phone p:nth-child(2){
  display: none;
}