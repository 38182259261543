.header_faqs{
    width: 100%;
    color: #fff;
    background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
}
.header_faqs_box{
    display: grid;
}
.header_faqs_title{
    padding: 60px 0px;
    margin-top: 8%;
}
.header_faqs_title h3{
    font-size: 38px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 600;
}
.header_faqs_title p{
    font-size: 18px;
    margin-bottom: 16px;
}
.header_faqs h3, .header_faqs_title p{
    width: 100%;
    justify-content: center;
    display: flex;
}

/**/

.box_titulo_faqs{
    width: 100%;
    display: flex;
    margin-top: 4rem;
}

.lista_preguntas{
    width: 65%;
}
.lista_preguntas ul li, .lista_temas ul li{
    margin-top: 12px;
}
.lista_preguntas ul li a, .lista_temas ul li a{
    color: #141414;
    text-decoration: none;
}
.lista_temas ul li a {
    margin-left: 8px;
}
.lista_preguntas ul li a:hover, .lista_temas ul li a:hover{
    text-decoration: underline;
}

.lista_temas{
    width: auto;
}
.lista_temas img{
    width: 25px;
}
.content_lista_temas{
    display: flex;
    align-items: center;
}

.accordion-section.actived {
    margin-bottom: 2rem;
}
.lista_preguntas h3{
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.box_lista_temas ul{
    padding-left: 0rem;
}

@media (max-width: 768px){
    .lista_preguntas h3{
        font-size: 20px;
    }
    .box_titulo_faqs{
        display: block;
    }
    .lista_preguntas{
        width: 100%;
    }
    .lista_temas{
        margin-top: 2rem;
    }
    .lista_temas h3{
        font-size: 20px;
        margin-top: 1rem;
    }
    .box_lista_temas ul{
        padding-left: 0rem;
    }
    .box_lista_temas li{
        font-size: 14px;
    }
    
}