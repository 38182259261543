.footer_carga{
    width: 100%;
    padding-top: 6rem;
    background-color: #333;
    
}
.footer_content_carga{
    width: 100%;
    display: flex;
}

.footer_main_box_carga img{
    /*width: 200px;*/
    width: 100%;
}
.footer_main_box_carga p{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
    margin-bottom: 0rem;
}
.footer_container_carga{
    display: flex;
    width: 75%;
    justify-content: space-around;
}
.footer_main_box_carga{
    width: 25%;
    align-content: space-between;
    display: grid;
}
.footer_main_box_carga img {
    /* width: 200px; */
    width: 60%;
    align-self: center;
}
.footer_box_carga {
    align-content: center;
}
.footer_box_carga li{
    padding: .5rem 0rem;
}

.footer_box_carga li h3{
    /*color: #747474;*/
    color: #fff;
    font-weight: 500;
    margin-bottom: .5rem;
    font-size: 20px;
}

.footer_box_carga li a{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
}
.footer_copyright_carga{
    background-color: #3f3f3f;
    padding: 1.2rem 0rem;
    text-align: center;
    color: #fff;
}
.footer_copyright_carga label{
    margin-right: 5px;
    font-size: 16px;
}
.box_socials_links.carga{
    margin: 0rem 0rem 1.5rem 0rem;
    justify-content: space-between;
}
.carga .box_link_social{
    /*margin-right: 8px;
    width: 45px;*/
    margin-right: 8px;
    width: 30px;
    margin-top: 8px;
}
@media (max-width: 768px){
    .footer_content_carga{
        display: block;
    }  
    .footer_main_box_carga{
        width: 100%;
    }
    .footer_container_carga{
        width: 100%;
        display: block;
    }
    .footer_box_carga{
        width: 100%;
    }
    .footer_box_carga ul{
        margin-left: 0rem;
        padding-left: 0rem;
    }
    .footer_copyright_carga label{
        font-size: 14px;
    }
}