
.booking_fijo{
    width: 100%;
}
.horizontal_form{
    display: flex;
    justify-content: space-between;
    height: 60px;
}
.formBooking_fijo{
    width: 100%;
    display: grid;
    padding-top: 6rem;
    padding-bottom: 4rem;
}

/* Contents Booking */
.radioBooking_fijo{
    display: flex;
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: #7C7C7C;
    font-weight: 600;
}
.radioBooking_fijo label{
    margin-left: 5px;
    margin-right: 5px;
    color: #7C7C7C;
}

.contentBooking_horizontal {
    display: inline-flex;
}

/* Origen */

.box_formOrigen_horizontal, 
.box_formDestino_horizontal,
.box_fechaSoloIda_horizontal {
    padding: 1px 15px;
    background: #fff;
    border: none;
    border-radius: 4px;
    /*border-right: 1px solid #767676;*/
    height: 45px;
    margin-left: 1%;
    margin-right: 1%;
    width: 220px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.box_formButton_horizontal {
    align-items: center;
    display: flex;
}
.box_formButton_horizontal button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
}
.box_formOrigen_horizontal select,
.box_formDestino_horizontal select {
    /*height: 45px;*/
    width: 100%;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    font-size: 16px;
    color: #7C7C7C;
}
/* Destino */

/* Fechas */
.box_fechaSalida_horizontal,
.box_fechaRegreso_horizontal {
    padding: 1px;
    background: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 1%;
    margin-right: 1%;
    align-items: center;
    display: flex;
}

.box_fechaSalida_fija input, 
.box_fechaRegreso_fija input, 
.box_fechaSoloIda_fija input {
    width: 100%;
    border: none;
    /*border-radius: 3px;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    font-size: 14px;
    color: #7C7C7C;
    height: 24px;
    font-weight: 600;
  
}
.box_fechaSalida_fija input[type="text"]{
    /*background-color: #E8F0FE;*/
    border-bottom: 2px solid #002648;
    width: 175px;
}
.box_formOrigen_horizontal label, .box_formDestino_horizontal label, 
.box_fechaSalida_horizontal label, .box_fechaRegreso_horizontal label, 
.box_fechaSoloIda_horizontal label {
    display: block;
    color: #141414;
    padding: 0px 20px;
    margin-top: 4px;
    margin-bottom: 0px;
}


/* Switch */

.switchBooking{
    display: flex;
    align-items: center;
    margin-right: 1%;
    margin-left: 2%;
}
.switchBooking p {
    color: #fff;
    width: 100px;
    margin-left: 18px;
}
.checkbox_type {
    display: none;
}
  
.slider_switch {
    /*width: 60px;
    height: 30px;*/
    width: 56px;
    height: 26px;
    background-color: lightgray;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 4px solid transparent;
    transition: .3s;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}

.slider_switch::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(-30px);
    border-radius: 20px;
    transition: .3s;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox_type:checked ~ .slider_switch::before {
    transform: translateX(30px);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox_type:checked ~ .slider_switch {
    background-color: #2196F3;
}

.checkbox_type:active ~ .slider_switch::before {
    transform: translate(0);
}

.switchBooking p{
    color: #fff;
}

/* Btn Reservar */
.btnBuscar_fijo button {
    height: 45px;
    width: 100%;
    border-radius: 4px;
    border: none;
    color: #fff;
    z-index: 5;
    /*background: #FF8F00;*/
    background: #F5A800;
    padding: 0px 20px;
    height: 53px;
}

.box_formButton_horizontal svg{
    width: 100%;
    height: 100%;
}

/* Btn Enviar */

.textPlaceholder_fijo {
    /* position: absolute; */
    display: flex;
    color: #fff;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    padding: 0 10px;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    height: 100%;
    width: 115px;
    
}


/* */

option:disabled {
    color: -internal-light-dark(graytext, rgb(170, 170, 170));
    display: none;
}

/* icons */

.fi-rr-calendar-check{
    color: #002648;
    width: 100%;
    font-size: 28px;
}

.icon_select_ruta_horario .fi-sr-calendar-check {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px;
}
.icon_select_ruta_horario .i.fi-sr-calendar-check {
    font-size: 20px;
    color: #002648;
}
.icon_select_ruta_horario  .fi-sr-calendar-check:before {
    color: #002648;
    width: 100%;
    /*font-size: 24px;  */  
    font-size: 22px;
}

/**/
.fi-ss-badge-percent{
    color: #002648;
    width: 100%;
    font-size: 30px;
}

.icon_select_ruta_horario .fi-ss-badge-percent {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px;
}
.icon_select_ruta_horario .i.fi-ss-badge-percent {
    font-size: 20px;
    color: #002648;
}
.icon_select_ruta_horario  .fi-ss-badge-percent:before {
    color: #002648;
    width: 100%;
    font-size: 28px;    
}


/**/
.fi-sr-person-simple{
    color: #002648;
    width: 100%;
    font-size: 28px;
    color: #002648;
}

.icon_select_ruta_horario .fi-sr-person-simple {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px;
}

/**/

.fi-sr-angle-square-down{
    color: #002648;
    width: 100%;
    font-size: 20px;
    color: #002648;
}

.icon_select_ruta_horario .fi-sr-angle-square-down {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px;
}
/*

Tengo un input tipo date, queria un diseño diferente y utilice el siguiente codigo para ocultar el icono del calendario que aparece por default: 
input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
}
Como puedo añadir un boton que funcion en lugar del desplegable calendar picker indicador?

*/

/* */
input[type="date"]::-webkit-calendar-picker-indicator {
    /*background: none;*/
/* Invierte los colores del icono */
    color: red; /* Cambia el color del icono */
  
  }
 
  /**/

ul li{
    list-style: none;
}

.optionListPasajeros{
    width: auto;
    height: auto;
    position: absolute;
    z-index: 7;
    background-color: #fff;
    border-top: 2px solid #7C7C7C;
    padding: 3px 6px;
    border: 1px solid #ddd;
    border-radius: 3px;
    max-width: 289px;
    min-width: 286px;
    max-height: 200px;
    overflow-y: scroll;
}
.optionPasajero{
    color: #7C7C7C;
    margin: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
}

