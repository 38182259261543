/*@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
.formBooking {
    width: 50%;
}

/ RADIO BUTONS/
.radioBooking {

    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 18px;
    width: 56%;
}
.radioButons{
    display: flex;
    align-items: center;
}

input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 7px;
}

.contentBooking{
    display: inline-flex;
    width: 80%;
}
.box_formOrigen, .box_formDestino, 
.box_fechaSalida, .box_fechaRegreso{
    width: 50%;
}
.box_fechaSoloIda{
    width: 100%;
}
.box_formOrigen, .box_fechaSalida {
    padding: 1px;
    background: #fff;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #767676;
}
.box_fechaSoloIda{
    padding: 1px;
    background: #fff;
    border: none;
    border-radius: 4px;

}
.box_fechaSalida, .box_fechaRegreso, .box_fechaSoloIda{
    margin-top: 5px;
}
.box_formOrigen label, .box_formDestino label,
.box_fechaSalida label, .box_fechaRegreso label, 
.box_fechaSoloIda label{
    display: block;
    color: #141414;
    padding: 0px 20px;
    margin-top: 4px;
}
.box_formOrigen select, .box_formDestino select{
    height: 30px;
    width: 100%;
    padding: 0px 20px;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline:none;
    font-size: 16px;
}

.box_formDestino, .box_fechaRegreso{
    padding: 1px;
    background: #fff;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #767676;
}

.box_formDestino{
    margin-left: -15px;
}

.box_fechaSalida input, .box_fechaRegreso input,
.box_fechaSoloIda input{
    height: 30px;
    width: 100%;
    padding: 6px 20px;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline:none;
    font-size: 16px;
}

.ant-picker.css-dev-only-do-not-override-w8mnev {
    width: 100%;
    border: none;
}
:where(.css-dev-only-do-not-override-w8mnev).ant-picker-focused {
    border-color: #4096ff;
     box-shadow: none; 
    border-inline-end-width: 1px;
    outline: 0;
    border: none;
}
:where(.css-dev-only-do-not-override-w8mnev).ant-picker .ant-picker-input >input {
    font-size: 11px!important;
    padding: 0px 10px;
}
/* BOTON INTERCAMBIAR <->

.box_formButton{
    margin-left: -15px;
    z-index: 5;
}

.box_formButton button{
    margin-top: 17px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #767676;
    background-color: #fff;
}
.box_formButton button i{
    font-size: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #4096ff;
}

/ BOTON BUSCAR /

.btnBuscar button{
    height: 55px;
    width: 100%;
    border-radius: 4px;
    border: none;
    color: #fff;
   z-index: 5;
}

span.anticon.anticon-arrow-right{
    background: #ffffff95;
    padding: 2px;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    display: flex;
}
.btnBuscar .icon {
    color: #191919;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .btnBuscar .icon  i.fi.fi-rr-arrow-right {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
    align-items: end;
    display: flex;
}


.contentBooking .anticon svg{
    display: inline-block;
    color: #0066BF;
}

span.anticon.anticon-arrow-right{
    background: #ffffff95;
    padding: 2px;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    display: flex;
}

.btnBuscar{
    position: relative;
    width: 80%;
    margin-top: 5px;
}
button.btnBuscarViaje {
    background: #FF8F0095;
    border-radius: 4px;
}
.formBooking .textPlaceholder{
    
    display: flex;
    color: #fff;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    height: 100%;
 
}



.btnBuscar button:hover{
    background: #FF8F00;
}

.textPlaceholder:hover{
    background: #FF8F00;
    border-radius: 4px;
}

.textPlaceholder .icon{
    display: contents;
}

input::placeholder {
    color: #fff;
    font-size: 1.5em;
}

span.anticon.anticon-swap-right {
    border-radius: 15px;
    background: #fff;
    padding: 5px;
}
*/

.fecha_datepicker {
    width: 100%;
    border: none;
    background: transparent;
    justify-content: space-between;
    display: flex;
    padding: 0px 20px;
    margin-bottom: 0rem;
}

.datepicker_box{
    z-index: 9;
    position: absolute;
    margin-top: 60px;
}
.custom-calendar {
    /* Estilos para el calendario */
    font-family: inherit!important;
    box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07)!important;
    border: none!important;
  
}
.custom-today-day{
    background: #000!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 15px!important;
    background-color: #000!important;
    color: #fff!important;
    border-radius: 3px!important;
}
.react-datepicker__month {
    margin: 0.8rem 3rem;
    text-align: center!important;
}
.react-datepicker__header{
    background: #fff!important;
    border: none!important;
}
.react-datepicker__current-month{  margin-top: 0;
    color: #5B5B5B!important;
    font-weight: 500!important;
    font-size: 1.1rem!important;
    text-transform: capitalize!important;
}
  
.react-datepicker__day--selected{
    border-radius: 23px!important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 23px!important;
    background-color: #000!important;
    color: #fff!important;
}
.react-datepicker__day-name{
    color: #d4d4d4!important;
    display: inline-block!important;
    width: 2.3rem!important;
    line-height: 1.7rem!important;
    text-align: center!important;
    text-transform: capitalize!important;
}
.react-datepicker__day-names {
    margin-top: 10px!important;
}
.react-datepicker__day {
    color: #545454!important;
    display: inline-block!important;
    width: 2.3rem!important;
    line-height: 2.3rem!important;
    text-align: center!important;
    margin: 0.166rem!important;
}
.react-datepicker__day--keyboard-selected{
    border-radius: 25px!important;
}
.react-datepicker__month-container{
    padding: 1rem!important;
}
.react-datepicker__navigation{
    top: 12px!important;
}
.box_radio_booking {
    display: flex;
    /*width: 55%;*/
    width: 50%;
    justify-content: space-between;
}
.box_container_pasajero,
.box_container_auto{
    width: 100%;
    display: grid;
}
.modalPasajero{

    background: rgba(255, 255, 255, .5);
    backdrop-filter: blur(25px);
    width: 80%;
    border-radius: 4px;
    padding: .1rem .5rem;
    -webkit-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    -moz-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    margin-top: 3px;


    z-index: 9;
    position: relative;
}
.modalAuto{
    position: relative;
    background: rgba(255, 255, 255, .5);
    backdrop-filter: blur(25px);
    z-index: 9;
    width: 80%; 
    border-radius: 4px;
    padding: .1rem .5rem;
    -webkit-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    -moz-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    margin-top: 3px;
}
.container_cant_pasajero{
    width: 100%;
    margin-top: 5px;
    padding: 0px 20px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 49px;
    align-items: center;
    cursor: pointer;

}
.container_cant_pasajero.resized{
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.container_auto{
    width: 100%;
    margin-top: 5px;
    padding: 0px 20px;
    border: none;
    /*border-radius: 4px;*/
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 49px;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 4px; 
    border-bottom-right-radius: 4px;
}
.box_container_pasajero.resized{
    width: 49.3%;
}
.box_container_auto.resized{
    width: 49.3%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.container_cant_pasajero label:nth-child(1){
    color:#747474;
    font-size: 14px;
}
.container_auto label:nth-child(1){
    color:#747474;
    font-size: 14px;
}
.container_cant_pasajero label:nth-child(2),
.container_auto label:nth-child(2){
    display: flex;
    align-items: center;
}
.container_auto label:nth-child(2) i{
    display: flex;
    align-items: center;
}
.container_cant_pasajero label:nth-child(2) i{
    display: flex;
}
.content_cant_pasajero{
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, .72);
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 4px;
}
.content_auto{
    display: flex;
    background: rgba(255, 255, 255, .72);
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.content_auto label{
    margin-left: 5px;
    cursor: pointer;
}
.box_cant_pasajero:nth-child(1){
    display: grid;
    
}
.box_cant_pasajero:nth-child(1) label:nth-child(1){
    font-size: 13px;
    color: #747474;
}
.box_cant_pasajero:nth-child(1) label:nth-child(2){
    font-size: 13px;
    color: #747474;    
}
.box_cant_pasajero:nth-child(2){
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    justify-content: end;
    min-width: 125px;
}
.box_cant_pasajero:nth-child(1) label:nth-child(1){
    font-size: 14px;    
    color: #000;
}
.box_cant_pasajero:nth-child(2) label{
    font-size: 20px;
    margin: 0rem 1rem;
}
.box_cant_pasajero:nth-child(2) i{
    font-size: 25px;
    margin: 0rem .3rem;
    align-items: center;
    display: flex;
}
.box_cant_pasajero div:nth-child(2) i{
    color: #d8d8d8;
}
.box_cant_pasajero div:nth-child(3) i{
    color: #F5A800;
}
.modalPasajero button,
.modalAuto button{
    background-color: #F5A800;
    color: #fff;
    width: 100%;
    border: none;
    margin: 5px 0px;
    padding: 10px;
    border-radius: 5px;
}

.box_cant_pasajero div{
    cursor: pointer;
}
.checkAuto label{
    align-items: center;
    display: flex;
    margin-right: 5px;
}
.checkAuto input[type="checkbox"]{
    align-items: center;
    display: flex;
    margin-right: 5px;
    border: none;
    width: 14px;
    height: 14px;
}
.content_auto img{
    width: 25px;
    
}
.box_auto{
    width: 30px;
}