.section-header{
    color: #141414;
    margin-top: 12px;
    margin-bottom: 1rem;
    font-size: 17px;
    cursor: pointer;
}

.section-header:hover{
    text-decoration: underline;
}
.accordion-menu {
    padding: 0rem 3rem 0rem 0rem;
}

@media (max-width: 768px){
    .section-header{
        font-size: 16px;
        margin-bottom: .5rem;
    }
    .section-content{
        font-size: 14px;
    }
}