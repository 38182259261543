
.chartering, .feedering, .proyecto{
    display: grid;
    align-content: center;
}

.servicio_carga_box img{
    width: 100%;
    border-radius: 3px;

}

.box_info_carga{
    display: flex;
}
.box_info_carga i{
    background: #333;
    padding: 1rem;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    margin-right: 1rem;
}
.box_info_carga label{
    font-size: 20px;
    font-weight: 500;
    color: #444444;
}


/* Header carga */
.servicio_carga_header {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 200px;
}
.header_carga.maritimo{
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/images/carga/CARGA-BGI-MARITIMO.webp');
    padding: 8rem 0rem 4rem;
    background-size: cover;
    background-position: bottom;
}
.maritimo h2, .maritimo p{
    color: #fff;
}
.maritimo .servicio_carga_box{
    width: 75%;
    display: block;
    align-content: center;
}


@media (max-width: 952px){
    .btn_servicio_transporte a {
        padding: 8px 8px;
        margin-right: 8px;
        border-radius: 3px;
        color: #7C7C7C;
        background-color: transparent;
        border: 3px solid #7C7C7C;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
    }
}
@media (max-width: 768px)
{
    .btn_servicio_transporte a {
        padding: 5px 5px;
        margin-right: 10px;
        border-radius: 3px;
        color: #7C7C7C;
        background-color: transparent;
        border: 3px solid #7C7C7C;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
    }
    
    .btn_servicio_transporte {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }

    .servicio_carga_content {
        flex-direction: column;
    }
    
    .servicio_carga_box.img {
        order: 2; /* Asegúrate de que el texto viene primero */
    }
    
    .servicio_carga_box.text {
        order: 1;
    }
}