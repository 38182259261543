@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
.header_gobernanza{
    width: 100%;
    background-color: #000;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/gobernanza/banner-gobernanza.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /*filter: brightness(0.9);*/
    height: auto;
    min-height: 60vh;

    display: flex;
    align-items: center;
}
.icons_gobernanza {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon_gobernanza {
    width: 10%;
    margin: 3rem;
    text-align: center;
}
.icon_gobernanza img{
    width: 60px;
}
.icon_gobernanza label{
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-top: .5rem;
}
.title_informes_gobernanza h3{
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 500;
    color: #707070;
}
.box_group_gobernanza{
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 7px;
    height: 300px;
    margin-top: -50px;
    padding: 3rem;
}
.box_content_informes{
    display: flex;
    justify-content: space-between;
}
.informes_list button{
    background-color: #17A2B8;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    color: #fff;
    margin-left: 3rem;
}
.informe_list{
    display: flex;
    padding: 0.6rem 0rem;
    justify-content: space-between;
}
.header_title_gobernanza {
    text-align: center;
    color: #fff;
}
























.informe_year{
    border-bottom: 4px solid #fff;
    margin-bottom: 1rem;
}
.informe_year h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.informe_list p{
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    font-size: 17px;
}
.informe_list_icon{
    margin-left: 5px;
}

.itemList_logo_informe {
    width: 33.3%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.box_text_logo_informe {
    height: 60px;
    background: #F2F3F4;
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border-top: 1px solid #ddd;
}
