
/* Titulos primarios*/

.primary h2{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}


.primary p{
    font-size: 19px;
    margin-bottom: 1rem;
}

/* Titulos secundarios*/

.secondary h2{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}


.secondary p{
    font-size: 19px;
    margin-bottom: 1rem;
}


/* Botones */
.box_content-btn {
    width: 100%;
    margin-top: 14px;
}
.box_content-btn a {
    padding: 10px 19px 9px;
    color: #fff;
    background: #002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-flex;
    text-decoration: none;
}
.box_content-btn a:hover {
    background: #FF8F0095;
    text-decoration: none;
}