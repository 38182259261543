.img_info_util {
    width: 40%;
}
.img_info_util img{
    width: 100%;
}
.info_durante_viajar{
    padding: 6rem 0rem;
    background: #F5F5F5;
    display: flex;
}
@media (max-width: 768px){
    .tabs_viajar{
        display: block;
    }
    .tab-container {
        display: flex;
        width: 100%;
        overflow-x: auto;
        margin-bottom: 1rem;
        background: #fff;
    }
    .tab-boxes {
        display: flex;
        padding: .3rem 0.5rem;
        
    }
    .content-container{
        width: 100%;
        margin-left: 0px;
    }
    .tab_viajar {
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;       
        padding: .3rem 0rem;
        margin: 0rem .5rem;
    }
    .info_antes_viaje{
        padding: 2rem 1rem;
    }
    .info_antes_viaje h4{
        font-size: 18px;
        margin-bottom: 0rem;
    }
    .info_antes_viaje p{
        font-size: 14px;
    }
    .info_antes_viaje ul {
        margin-left: 0rem;
        font-size: 14px;
    }
}