.aviso_importante{
    padding: 3rem 0rem;
}
.aviso_fecha{
    color: #001B71;
    font-size: 18px;
    font-weight: 600;
    text-align: end;
}
.aviso_clientes{
    color: #001B71;
    font-size: 16px;
    font-weight: 500;

}
.aviso_contacto ul a{
    text-decoration: none;
    color: #00335F;
    font-weight: 500;
}