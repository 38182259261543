.footer{
  background-color: #005B81;
  color: #fff;
  padding: 5rem 0rem 0rem;
}
.footer_links{
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
}
.box_links{
  width: 25%;
}
.box_links ul{
  bottom: 0;
  position: absolute;
  padding-left: 0rem;
}
.box_links ul:nth-child(1){
  bottom: 0;
  position: absolute;
  padding-left: 3rem;
}
.box_links li{
   margin: 1.3rem 0rem;
}
.box_links li a{
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}
.form-newsletter{
  display: grid;
  padding: 1rem 2rem 0rem 0rem;
  margin: 1rem 0rem;
}
.form-newsletter label{
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
}
.form-newsletter input{
  padding: 7px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin: 5px 0px;
  background: #fff;
  color: #000;
  opacity: .5;
}
.form-newsletter input::placeholder {
  font-size: 16px;
}
.form-newsletter button{
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: transparent;
  color: #fff;
}
.box_links_logo img{
  width: 200px;

}
.footer_copyright{
  background: #003466;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  width: 100%;
  border-top: 1px solid #999999;
}
.box_links_socials {
  width: 100%;
  height: 45px;
  margin-top: 20px;
}
.box_links_socials{
  display: flex;
  justify-content: space-between;
}
.box_link_social{
  width: 35px;
}

/* Footer Celular */

.footer.phone{
  width: 100%;
  background-color: #005B81;
}
.footer_boxes{
  display: grid;
}
.box_socials{
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  border-bottom: 1px solid #4D7B9A;
}
.box_socials_links{
  display: flex;
}
.box_socials .box_link_social{
  width: 20px;
  margin: 0px 7px;
}
.form-newsletter.phone {
  padding: 1rem 0rem;
  border-bottom: 1px solid #97a8b1;
  margin-bottom: 1.5rem;
}
.form-newsletter.phone label{
  color: #fff;
  font-size: 16px;
  margin-top: 0px;
  font-weight: 500;
}
.form-newsletter.phone input{
  padding: 5px 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin: 8px 0px;
  opacity: .5;
  color: #000;
}
.form-newsletter.phone button{
  padding: 5px 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
}
.menu_list_footer.phone{

}
.menu_list_footer.phone ul{
  padding-left: 0rem;
}
.menu_list_footer.phone ul.submenu_item{
  background-color: transparent;
}
.menu_list_footer.phone ul li{
  padding: .7rem 0rem;
  border-bottom: 1px solid #97a8b1;
}
.menu_list_footer.phone ul li .submenu_item{
  font-size: 14px;
}
.menu_list_footer.phone ul a{
  text-decoration: none;
  color: #fff;
  align-items: center;
  display: flex;    
  justify-content: space-between;
  font-size: 14px;
}
.menu_list_footer.phone ul i{
  display: flex;
}
.box_links_logo.phone{
  justify-content: center;
  align-items: center;
  display: flex;
}
.footer_copyright_text{
  font-size: 10px;
  display: grid;
  text-align: center;
  color: #dbdbdb;
}
.alertMessage_footer{
  
  font-size: 14px;
  margin: .5rem 0;
  padding: .5rem;
}