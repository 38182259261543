.noticias_form_content{
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
}
.noticias_form_content form{
    width: 60%;
}
.form_input_noticias {
    display: grid;
    margin-top: 1rem;
}
.form_input_noticias input[type=text]{
    height: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: .5rem;
}
.form_input_noticias input::placeholder {
    margin-left: 15px;
    font-size: 1rem;
    padding: 1rem .5rem;
}
.form_input_noticias input[type=file]{
    /*display: none;*/
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;    
}
.form_input_noticias input[type=textarea]{
    min-height: 120px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 0.5rem;
}
.form_input_noticias .file-style {
    /*font-family: 'Trebuchet MS';*/
    width: 100%;
    display: grid;
    background-color: #f5f4f4;
    margin: auto;
    cursor: pointer;
    text-align: center;
    padding: 1.5rem;
    border-radius: 5px;
    border: 2px dashed #cdc8c8;
    position: relative;
}
.file-style:hover {
    background-color: #E3EAEF;
}
.form_input_noticias i{
    font-size: 6rem;
    color: #CECECE;
}
.form_input_noticias p{
    color: #545454;
}

.form_input_noticias button{
    background-color: #225981;
    padding: 13px 20px;
    width: 100px;
    border: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}
.img_upload{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f1f1f1;
    margin-top: 1rem;
}
.img_selected {
    display: flex;
}
.img_selected p{
    color: #545454;
    align-items: center;
    display: flex;
    background: #eee;
    border: 1px solid #ddd;
    padding: 3px;
}
.img_upload img{
    width: 6%;
    min-width: 50px;
    min-height: 45px;
}

/* Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
}

.switch label:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    top: 4px;
    left: 4px;
    transition: 0.3s;
}

.switch input:checked + label {
    background-color: #2196F3;
}

.switch input:checked + label:after {
    transform: translateX(26px);
}

/*End swich*/
.form_box_noticias{
    display: flex;
    margin-top: 8px;
}
.form_box_noticias label{
    width: 50%;
}
.form_input_noticias input[type=checkbox]{    
    margin-right: 5px;
}
.visible_switch{
    display: flex;
    justify-content: end;
}
.visible_switch .switch{
    margin-left: 10px;
}

.form_input_noticias .quill {
    margin-bottom: auto;
}
.img_uploaded{
    width: 100%;
}
.img_uploaded img{
    width: 10%;
}