
.callToAction{
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.callToAction h3{
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.box_group-card{
    
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 12px;
    background-size: contain;
    color: #fff;    
}
.contact-box-card{
    height: 250px;
    /*margin-bottom: 4rem;*/
}

.box_content-card{
    padding: 12px 12px; 
    display: flex;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
}/*
.box_content-card{
    padding: 24px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    width: 100%;
    display: flex;
}*/

.box_icon-card{
    display: inline-block;
    width: 10%;
}
.box_icon-card img{
    width: 100%;
}
.boxes_content{
    display: inline-block;
    width: 90%;
    padding: 5px 0px 15px 8px;
}
.box_content-title{
    width: 100%;
    margin-bottom: 3px;
}
.box_content-title h2{
    font-size: 22px;
    font-weight: 500;
    color: #141414;
}
.box_content-subtext {
    margin-bottom: 16px;
}
.box_content-subtext p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li a{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext li:hover{
    color: #141414;
   text-decoration: underline;
    
}
.box_content-btn{
    width: 100%;
}
.box_content-btn a{
/* padding: 8px 11px;*/
    padding: 10px 19px 9px;
    color: #fff;
    /*background: #00155F;*/
    background:#002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3)
}
.box_content-btn a:hover{
    background: #FF8F0095;
}



@media (max-width: 768px){
    .callToAction h3 {
        font-size: 18px;
     
    }
    .box_content-title h2 {
        font-size: 16px;
        font-weight: 500;
        color: #141414;
    }
    .box_content-subtext p {
        font-weight: 500;
        font-size: 14px;
    }
}

/**/
.callToAction_row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
.callToAction_container{
    display: flex;
    border: 1px solid #ddd;
    width: 32.2%;
    border-radius: 5px;
    padding: 1rem;
    flex: 1 0 32.2%;
}
.callToAction_icon{
    width: 15%;
    padding: 0rem .5rem;
}
.callToAction_content{
    display: block;
    align-content: space-between;
    width: fit-content;
    height: 100%;
    display: grid;
}
.callToAction_box:nth-child(2){    
    padding: 1rem 0rem;
}
.callToAction_box h4{
    font-size: 22px;
}
.callToAction_box p{
    font-size: 16px;
}
.callToAction_box a{
    padding: 10px 20px;
    color: #fff;
    /*background: #002648;*/
    background: #225981;
    border-color: transparent;
    border-radius: 5px;
    text-decoration: none;
}
.callToAction_box a:hover{
    
    background: #FF8F0095;
}

@media (max-width: 952px){
    .callToAction_container{
        padding: .5rem;
    }
    .callToAction_icon {
        width: 20%;
        padding: 0rem .2rem;
    }
    .callToAction_box h4{
        font-size: 18px;
    }
    .callToAction_box p{
        font-size: 14px;
    }
    .callToAction_box a{
        padding: 10px 20px;
        font-size: 13px;
    }
    
}
@media (max-width: 768px){
    .callToAction_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        /*flex-direction: column;*/
    }
    .callToAction_container {
        width: 32.2%;
    }
}

@media (max-width: 468px){
    .callToAction{
        display: block;
        flex-wrap: nowrap;
    }
    .callToAction_container{
        width: 100%!important;
        margin-bottom: 1rem;
    }
    .callToAction_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        flex-direction: column;
    }
    .callToAction_icon {
        width: 15%;
        padding: 0rem .2rem;
    }
}