
.slider-destino{
    position: relative;
    width: 100%;
    height: auto;
    margin: 6rem 0rem;

}
.destino_slider_container{
    position: relative;
    display: inline-block;
    text-align: center;
}
.slider-destino img{
    width: 100%;
    filter: brightness(0.8);
    
}
.slide-title{
    position: absolute;
    top: 8%;
    left: 8%;
    font-size: 2rem;
    font-weight: 500;
    color: #FFF;
}

.slide-text{
    position: absolute;
    top: 16%;
    left: 8%;
    width: 39%;
    text-align: initial;
    font-size: 20px;
    font-weight: 400;
    color: #FFF;
}
.slide-subtext{
    position: absolute;
    top: 90%;
    right: 8%;
    font-size: 18px;
    font-weight: 400;
    color: #FFF;
}

@media (max-width: 768px){
    .slide-text {
        position: initial; 
        width: 100%;
        text-align: initial;
        font-size: 16px;
        font-weight: 400;
        color: #747474;
        margin-top: 1rem;
    }
    .slide-title {
        margin-top: 1rem;
        font-size: 1rem;
        position: relative;
        color: #333;
        top: 0;
        left: 0;
    }
    .slide-subtext {
        position: absolute;
        top: 90%;
        right: 8%;
        font-size: 18px;
        font-weight: 400;
        color: #747474;
    }
}


