.linea_tiempo{
  width: 100%;
  padding: 4rem 0rem;
  background-color: #f1f1f1;
}
.linea_tiempo h2{
  text-align: center;
}
.timeline {
  position: relative;
}

.line {
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: #747474;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.events-container {
  display: flex;
  flex-direction: column; /* Cambia la dirección a vertical */
  align-items: center; /* Centra los elementos horizontalmente */
  margin-top: 20px;
}

.timeline-event {
  width: 100%; /* Cambia el ancho para ajustarlo a la línea */
  /*padding: 20px;
  margin-bottom: 20px; *//* Añade un espacio entre eventos */
  background-color: #f0f0f0;
  border-radius: 5px;
  max-width: 1100px;
}

.event-date {
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-event.right{
  justify-content: end;
  display: flex;
  margin-top: -8rem;
}

.timeline-event.left:not(:first-of-type) {
  margin-top: -7rem;
}

.timeline-text{
  display: flex;
}
.event-details{
  width: 100%;
}
.event-details img{
  width: 100%;
  border-radius: 4px;
  border: 2px solid #ddd;
}
.timeline-content {
  width: 50%;
  position: relative;
}
.right .timeline-content {
  width: 50%;
  position: relative;
  justify-content: end;
  display: flex;
}



.left .timeline-content::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #747474;
  right: -8px; /* Ajusta la posición del círculo según sea necesario */
}


.right .timeline-content::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #747474;
  left: -8px; /* Ajusta la posición del círculo según sea necesario */
}

.timeline-content {
 padding: 1rem;

}

.timeline-box{
  width: 95%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Flecha */
.left .timeline-box::after {
  content: "";
  top: 50%;
  position: absolute;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 16px solid #fff;
  right: 5%;
  transform: translateY(-50%);
}

.right .timeline-box::after {
  content: "";
  top: 46%;
  position: absolute;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 16px solid #fff;
  left: 5%;
  transform: translateY(-50%);
  /* rotate: 180deg; */
  transform: rotate(180deg) translateX(0px);
}
.event-title p {
  font-size: 16px;
  font-weight: 500;
  margin-left: 1rem;
  color: #545454;
}
.event-date h3 {
  font-weight: 600;
  color: #535252;
}
