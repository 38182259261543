.slide_nosotros {
    display: none;

    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .slide_nosotros.active {
    display: block;
    opacity: 1;
  }
  
.slide_nosotros img{
  width: 100%;
}

.header_text_nosotros {
  text-align: center;
  color: white;
  font-weight: 500;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 4px 6px black);
  width: 30%;
}




@media (max-width: 768px){
  .slide_nosotros {
    display: none;
    width: 100%;
    height: 39vh;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.slide_nosotros img {
  width: 100%;
  height: 100%;
}
  .header_text_nosotros {
    /* text-align: center; */
    color: white;
    font-weight: 500;
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(2px 4px 6px black);
    width: 100%;
  }

}
