.prepararViaje{
    width: 100%;
    color: #fff;
    background: linear-gradient(71.93deg,#001b33 26.34%,#004887 56.72%,#273b49 94.79%);
}

.prepararViaje_box{
    display: grid;
}
.prepararViaje_title{
    padding: 60px 0px;
    margin-top: 8%;
}
.prepararViaje_title h3{
    font-size: 38px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 600;
}
.prepararViaje_title p{
    font-size: 18px;
    margin-bottom: 16px;
}
.prepararViaje h3, .prepararViaje_title p{
    width: 100%;
    justify-content: center;
    display: flex;
}

/**/

    /*SECCION DESTINOS */
    .box_prepararViaje{
        width: 100%;
        height: auto;
        margin-top: 4rem;
        padding: 0 12px;
    }
    .box_prepararViaje h2{
        font-size: 28px;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 16px;
        font-weight: 400;
    }
    .box_prepararViaje p{
        font-size: 20px;
        margin-bottom: 16px;
        color: #545454;

    }
    .box_prepararViaje button{
        /*padding: 8px 11px;*/
        padding: 10px 19px 9px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        box-shadow: none;
        color: #353535;
    }

    .box_prepararViaje button:hover{
        background-color: #f7f7f7;
        border: 1px solid #cfcfcf;
        box-shadow: none;
        color: #353535;
    }
    .content_documentacion, .content_antesViajar, .content_paraViajar{
        padding: 0 12px;
        margin-top: 35px;
    }
    .content_documentacion a, .content_antesViajar a, .content_paraViajar a{
        text-decoration: none;
    }
    .cardPrepararViaje {
        transition: 0.3s;
        width: 100%;
        border: 1px solid #dbdbdb;
        border-radius: 5px;
    }
    .cardPrepararViaje img{
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    
    .cardPrepararViaje:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    
    .textPrepararViaje {
        padding: 15px 16px;
        min-height: 190px;
        height: 190px;

    }
    .textPrepararViaje h4 {
        color: #000;
        margin-top: 16px;
        font-size: 20px;
        font-weight: 400;
    }
    .textPrepararViaje p {
        color: #545454;;
        margin-top: 10px;
        margin-bottom: 36px;
        font-size: 18px;
    }

    @media (max-width: 768px){
        .textPrepararViaje {
            padding: 15px 16px;
            min-height: 175px;
            height: 175px;
        }
        .box_prepararViaje h2{
            font-size: 20px;    
            margin-bottom: 0px;
        }
        .box_prepararViaje p{
            font-size: 14px;
        }
        .textPrepararViaje h4{
            font-size: 18px;    
            margin-top: 5px;
        }
        .textPrepararViaje p{
            font-size: 14px;
            margin-bottom: 1rem;
        }
    }