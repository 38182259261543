@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:wght@600&display=swap');

/* Sorteo */
.box_sorteo{
    width: 100%;
    padding: 4rem 0rem;
}
.box_sorteo img{
    width: 100%;
}
/* Promociones */
#promociones {    
    padding: 6rem 0rem;
}
.promociones{
    display: flex;
}
.promociones_title_home{
    width: 100%;
    height: auto;
   /* margin-top: 4rem;*/
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
} 
.promociones_title_home button{
    border: none;
} 
.promociones_title_home h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    /*margin-bottom: 16px;*/
    margin-bottom: 0rem;
    font-weight: 400;
}
#promociones .promociones_title_home{
    align-items: center;
    margin-bottom: 1rem;
}
#promociones .promociones_title_home a{
    display: flex;
    align-items: center;
    padding: 10px 19px 9px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    text-decoration: none;
}
#promociones .promociones_title_home a:hover{
    background: #2d5b7b;
    color: #fff;
}
#promociones .promociones_title_home i{
    display: flex;
}
#content{
    margin-top: 4rem;
}
.box_group_card_promo{
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center; 
    color: #fff;
    border-radius: 4px;
    transition: transform .3s;   
    height: 260px; 
    max-height: 260px;/* Cambiar name*/
    /* transition: scale 300ms;*/
}

.box_group_card_promo:hover {
    transform: scale(1.1);
    /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
}

.box_content_card_promo{
    padding: 0 12px;
    width: 33.3%;
}


.boxes_content{
    display: inline-block;
    width: 90%;
    padding: 5px 0px 0px 8px
}
.box_content_title{
    width: 100%;
    margin-bottom: 3px;
}
.box_content_title h2{
    font-size: 22px;
    font-weight: 500;
    color: #FFF;
}
.box_content_subtext {
    margin-bottom: 16px;
}
.box_content_subtext p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
    color: #fff;
    letter-spacing: 0;
    line-height: 24px;
    
}
/*

.box_content_btn a{

    padding: 10px 19px 9px;
    color: #fff;
    background: #002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-decoration: none;
}
.box_content_btn a:hover{
    background: #FF8F0095;
}*/

/* Rutas y Horarios */
.cta_body{
    width: 100%;
    padding: 6rem 0rem;
    border-top: 3px solid #fff;

    /*background:linear-gradient( to right,#2D5B7B 0%,#2D5B7B 50%,#D4EDFF 50%, #D4EDFF 100%);*/
    background: linear-gradient(to right, #2D5B7B 0%, #2D5B7B 50%, #F5A800 50%, #F5A800 100%);
}
.cta_container_body{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cta_box_body{
    transition: 0.3s;
    width: 49%;
    border-radius: 5px;
    min-height: 230px;
    /*background: #D4EDFF;*/
    background: #F5A800;
}
.cta_box_body:nth-child(1){
    /*background-color: #005B81;*/
    background: #2D5B7B;
}
.cta_content_body {
    display: flex;
    padding: 1.5rem 1rem;
    height: 100%;
    min-height: 225px;
}
.cta_info_body{
    align-content: space-between;
    display: grid;
}
.cta_img_body{
    width: 22%;
}
.cta_box_img_body{
    width: 100%;    
    background: #D4EDFF;
    padding: 1rem;
    border-radius: 50%;
}
.cta_box_body:last-child .cta_box_img_body{
    background-color: #005B81;
}
.cta_img_body img{
    width: 100%;
  
}
.cta_text_body {
    width: 100%;
    padding: 0rem 1rem;
    font-weight: 500;
}
.tarifa .cta_text_body h2{
    /*color: #545454;*/
    color: #005B81;
    font-size: 26px;
    font-weight: 500;
}
.ruta-horario .cta_text_body h2{
    color: #fff;
    font-size: 26px;
    font-weight: 500;
}
.tarifa.cta_text_body p {
    color: #545454;
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 16px;
}
.ruta-horario .cta_text_body p{
    color: #fff;
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 16px;
}

.cta_btn_body {
    padding: 0rem 1rem;
    height: 50px;
    align-content: center;
}
.cta_btn_body a{
    padding: 10px 15px;
    color: #005B81;
    background: transparent;
    border: 2px solid #005B81;
    border-radius: 5px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    text-decoration: none;
    font-weight: 500;
}
.cta_box_body:nth-child(1) a{
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
}

/*SECCION DESTINOS */
.container_destinos{
    margin-top: -4rem;
    background: #fff;
    border-radius: 4px;
    padding: 1rem 1rem 3rem 1rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .08);
}
.box_destinos{
    width: 100%;
    height: auto;
    margin-top: 1rem;
    padding: 0 12px;
}
.box_destinos h2{
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    /*margin-bottom: 16px;*/
    font-weight: 400;
}
.box_destinos p{
    font-size: 16px;
    margin-bottom: 0px;
    color: #545454;
}
.box_destinos button{
    /*padding: 8px 11px;*/
    padding: 10px 19px 9px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}
.box_destinos button:hover{
    background-color: #f7f7f7;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}
.content_destino1, .content_destino2, .content_destino3{
    padding: 0 5px;
    margin-top: 35px;
}
.cardDestinos {
    transition: 0.3s;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
}
.cardDestinos img{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cardDestinos:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.textDestino {
    padding: 0px 16px 15px;

}
.textDestino h4 {
    color: #000;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
}
.textDestino p {
    color: #545454;;
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 16px;
}

/*SECCION LOGOS*/

.logos{
    /*background-color: #005276;*/
    width: 100%;
}
.certificado{
    grid-template-columns: auto auto auto auto;
    grid-gap: 0.5rem;
    display: grid;
}
.cert_block{
    display: inline-flex;
}
.certificadoImg{
    width: 25%;
}


/*  SECCION DESCUBRE BF*/
.contentIcons_descubreBF{
    width: 100%;
    display: flex;
    background: antiquewhite;
}
.boxicon_descrubeBF{
    width: 25%;
    display: inline-flex;
    justify-content: center;
}
.iconsDescubreBF{
    width: 200px;
    height: 200px;
}
.rse_section{
    width: 100%;
    margin-top: 6rem;
}
.videoRSE{
    position: relative

}
.videoRSE video{
    width: 100%;
    height: auto;
    display: block;
    min-height: 300px;
    opacity: .9;
    filter: brightness(0.8);
}
.videoRSE .RSElogo{
   /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    position: absolute;
    top: 40%;
    left: 32%;
    transform: translate(-50%, -50%);
    width: 12%;
    max-width: 310px;*/
}
.videoRSE .text_body_rse {
    position: absolute;
    bottom: 15%;
    left: 42%;
    transform: translate(-50%, -50%);
    width: 30%;
    color: #fff;
    font-weight: 500;
    font-size: 22px;
}

.RSEImg {
    display: none;
    padding-top: 6rem;
}
.content-text-rse-mobile{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    color: #fff;
}
.content-text-rse-mobile img{
    width: 70%;
}
.content-text-rse-mobile p{
    margin-top: 1rem;
}
/*SECCION NOTICIAS */
.box_noticias{
    width: 100%;
    height: auto;
    margin-top: 6rem;
    /*padding: 0 12px 1rem;*/
}
.box_noticias h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.box_noticias p{
    font-size: 18px;
    margin-bottom: 16px;
    color: #545454;

}
.box_noticias a{
    /*padding: 8px 11px;*/
    padding: 10px 19px 9px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    text-decoration: none;
}

.box_noticias button:hover{
    background-color: #f7f7f7;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}
.content_noticia1, .content_noticia2, .content_noticia3{
    padding: 0 12px;
    margin-top: 35px;
}
.cardNoticia {
    transition: 0.3s;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    position: relative;
}
.cardNoticia img{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: auto;
}

.cardNoticia:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.textNoticia {
    padding: 2px 16px;
    min-height: 185px;
}
.textNoticia label{
    position: absolute;
    color: #878787;  
    position: absolute;
    margin-bottom: 10px;

}
span.anticon.anticon-tag {
    display: contents;
}
.textNoticia h4 {
    color: #000;
    margin-top: 35px;
    font-size: 20px;
    font-weight: 400;
}
.textNoticia p {
    color: #545454;;
    margin-top: 10px;
    margin-bottom: 36px;
    font-size: 15px;
}
.textNoticia span{
    position: absolute;
    color: #878787;
    bottom: 12px;
    position: absolute;

}

span.anticon.anticon-calendar {
    display: contents;
}

/* SECCION NOTICIAS */
.noticias{
    width: 100%;
    display: flex;
    padding-top: 6rem;
}
.box_noticia{
    display: grid;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.box_noticia:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.box_noticia_img img{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.box_noticia_text{
    padding: 2px 16px;
    min-height: 200px;
    position: relative;
}
.box_noticia_tag{
    color: #878787;
}
.box_noticia_title {
    color: #000;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 400;
    
}
.box_noticia_title h3{
    color: #000;
    margin-top: 15px;
    font-size: 19px;
    font-weight: 400;
}
.box_noticia_fecha_btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    bottom: 0;
    position: absolute;
    width: 92%;
}
.btnLeerMas_noticia {
    padding: 10px 19px 9px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}
.btnLeerMas_noticia a {
    color: #353535;
    text-decoration: none;
}
.btnLeerMas_noticia:hover {
    background-color: #f7f7f7;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}

/* Mosaico Noticias */
.box_mosaico_noticias {
    display: flex;
}
.box_mosaico_block_izq{
    display: grid;
    width: 50%;
}
.box_mosaico_block_der{
    display: flex;
    width: 50%;
}
.mosaico_box_1{
    width: 100%;
}
.mosaico_box_2{
    display: flex;
    width: 100%;
}
.mosaico_box_3{
    display: grid;
    width: 50%;
}
.mosaico_box_4{
    display: grid;
    width: 50%;
}
.mosaico_box_1, .mosaico_box_2 , .mosaico_box_3 , .mosaico_box_4 {
    padding: 5px;
}
.mosaico_box_1 img, .mosaico_box_2 img, .mosaico_box_3 img, .mosaico_box_4 img{
    width: 100%;
    border-radius: 4px;
    filter: brightness(.9);
}
.mosaico_box_img{
    position: relative;
}
/*
.mosaico_box_img:hover{ 
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
*/
.box_mosaico_text{
    position: absolute;
    bottom: 8px;    
    color: #fff;
    padding: 10px;
    background: rgba(0,0,0, .4);
    background: linear-gradient(#ffffff00, #111111b3);
}
.box_mosaico_text h2{
   font-size: 18px;
   font-weight: 500;
   text-shadow: black 0.1em 0.1em 0.2em;
}




/* Spotify */

.spotify_section{
    width: 100%;
    min-height: 300px;
    margin-top: 6rem;
    background-image: url('../assets/images/spotify/banner-spotify.webp');
    text-align: center;
    position: relative;
    padding: 4rem 1rem;
}
.spotify_section img{
    width: 100%;
}
.box_spotify{
    display: flex;
}
.title_spotify{
    font-size: 2.5rem;
    color: #fff;
    text-align: initial;
    text-align: center;
}

.box_text_spotify{
    width: 50%;
    display: grid;
    color: #fff;
}
.text_spotify{
    font-size: 21px;
    align-items: center;
    display: flex;
    text-align: initial;
    margin-left: 2rem;
}
.text_block_spotify{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 8rem;
}
.icon_spotify{
    width: 12%;
}
.content_frame_spotify{
    width: 50%;
    min-width: 250px;
    justify-content: center;
    display: flex;
    min-height: 500px;
    align-items: center;
}
.list_icon_spotify{
    align-content: center;
    width: 50%;
    justify-content: center;
    display: grid;
}
.list_icon_spotify ul{
  padding-left: 0px;
  display: flex;
  padding-top: 1rem;
}
.list_icon_spotify img{
    width: 35px;
}
.list_icon_spotify li{
    justify-content: flex-start;
    display: flex;
    margin: 0rem 1rem;
}
.frame_spotify{
    width: 56%;
}
.list_icon_spotify label{
    margin-left: 1.5rem;
    align-items: center;
    display: flex;
    font-size: 22px;
}

img.RSElogo:hover {
    /* filter: brightness(0.5); */
    cursor: pointer;
}


.btnLeerMas {
    bottom: 10px;
    position: absolute;
    right: 20px;
    padding: 10px 19px 9px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}
.btnLeerMas a{
    color: #353535;
}

.btnLeerMas:hover{
    background-color: #f7f7f7;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
}

ul.submenu-item.bandera-list {
    width: 100px;
}

.chatbot_bf{
    width: 30%;
    float: right;
    position: fixed;
    right: 0;
    justify-content: end;
    display: flex;
    z-index: 2;
    top: 25%;
    bottom: 0px;
    margin-right: 10px;
}
/* certificaciones */

.certificaciones_body{
    display: flex;
    padding: 6rem 0rem;
}
.certificaciones_box_body {
    width: 50%;
}
.certificaciones_box_body img{
    width: 98%;
    border-radius: 3px;
}
.certificaciones_box_body h2{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}
.certificaciones_box_body p{
    font-size: 18px;
    margin-bottom: 16px;
    color: #545454;
}
.text_certificaciones{
    padding: 1rem;
}
.btn_certificaciones_box a{
    text-decoration: none;
    color: #545454;
    border: 1px solid #ddd;
    padding: 10px 10px;
    border-radius: 3px;
    align-items: center;
    display: inline-flex;
}
.btn_certificaciones_box{
    margin-top: 2rem;
}
.btn_certificaciones_box i{
    display: flex;
    margin-left: 5px;
}

.videoRSE {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
}
.videoRSE video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
 
}
.content-text-rse{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea a la izquierda */
    justify-content: center;
    height: 100%;
    padding: 20px; /* Ajusta el padding según sea necesario */
}

.videoRSE .RSElogo {
    max-width: 400px;
}
.container-text-rse{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    width: 70%;
}
.banner-cancer{
    width: 100%;
}

.banner-cancer img{
    width: 100%;
}


@media (max-width: 952px){
    .cta_img_body {
        width: 35%;
    }
    .cta_box_img_body {           
        padding: .8rem;
    }
    .textDestino p {
        font-size: 14px;
    }
}
@media (max-width: 768px){
    /* Promociones */
    #promociones {    
        padding: 2rem 1rem 4rem 1rem;
    }
    .rse_section{
        display: none;
    }
    .list_icon_spotify{
        width: 100%;
    }
    .list_icon_spotify ul{
        justify-content: center;
    }
    .list_icon_spotify li{
        margin: 0rem .2rem;
    }
    .certificaciones_body{
        display: block;        
    }
    .certificaciones_box_body{
        width: 100%;
    }
    .box_noticias h2{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .box_noticias p{
        font-size: 14px;
        margin-bottom: 1rem;
    }
    .noticias_text_home_mobile p {
        color: #707070;
        font-size: 10px;
        margin-bottom: 3px;
    }
    .noticias_text_home_mobile h3 {
        font-size: 12px;
        line-height: normal;
        color: #707070;
        margin-bottom: 0px;
    }
    .noticias_text_home_mobile {
        padding: 0.8rem 0.5rem;
    }
    .box_noticias button {
        padding: 8px 7px;
        font-size: 12px;
    }
    .cta_body{
        padding: 2rem 0rem;
        background: #f5f5f5;
    }
    .cta_img_body {
        width: 40%;
    }
    .cta_container_body {
        display: block;
        padding: 2rem 0rem;
    }
    .cta_box_body {
        width: 100%;
        margin-bottom: 1rem;
        min-height: 100px;
    }
    .tarifa .cta_text_body h3,
    .ruta-horario .cta_text_body h3{
        font-size: 18px;
    }
    .ruta-horario .cta_text_body p,
    .tarifa .cta_text_body p {
        font-size: 14px;
    }

    .cta_box_img_body{

        max-width: 90px;
        
    }
    
    .videoRSE .RSElogo {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12%;
        max-width: 310px;
    }

    .videoRSE .text_body_rse {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        color: #fff;
        font-weight: 500;
        font-size: 17px;
    }

    .content-text-rse-mobile p {
        margin-top: 1rem;
        font-size: 14px;
    }


}

@media only screen and (max-width: 420px){
    .videoRSE .RSElogo {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12%;
        max-width: 310px;
    }

    .videoRSE .text_body_rse {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
    }
}

/* Ipad */
@media (min-width: 768px) and (max-width: 819px){
    .videoRSE .RSElogo {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20%;
        max-width: 310px;
    } 
    .videoRSE .text_body_rse {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        color: #fff;
        font-weight: 500;
        font-size: 18px;
    }
}


@media (min-width: 820px) and (max-width: 1024px){
  /*  .videoRSE .RSElogo {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26%;
        max-width: 310px;
    } */
    .videoRSE .text_body_rse {
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
    }
}