.reserva_mobile{
    width: 100%;
    display: flex;
}
.content_reserva_mobile{
    background-color: #fff;
    border-radius: 3px;
    margin-top: -2rem;
    padding: 1rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .08);
    /*margin-bottom: 2rem;*/
}
.box_reserva_mobile_radioButons{
    width: 100%;
    display: flex;
}
.box_reserva_mobile_radioButons span{
    margin: 0rem .5rem;
}
.box_reserva_mobile_select{
    display: grid;
    border: 1px solid #ddd;
    margin: .5rem 0rem;
    border-radius: 3px;
    padding: .3rem;
}
.box_reserva_mobile_select select{
    padding: 0rem 0rem;
    border: none;
    font-size: 14px;
    color: #b1b1b1;
    font-weight: 500;
}
.title_modal_reservar h3{
    font-size: 18px;
    border-bottom: 1px solid #a4a4a4;
    padding: .5rem 0rem;
}
.box_reserva_mobile_select label{
    color: #343434;
    font-weight: 500;
    font-size: 12px;
    margin-left: 3px; 
}
.box_reserva_mobile_button button{
    width: 100%;
    padding: 8px 0px;
    background: #FF8F00;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    border: none;
}
.box_reserva_mobile_radioButons label{
    align-items: center;
    display: flex;
}
.cupon_reserva_mobile{
    color: #343434;
    font-size: 14px;
    text-align: center;
    margin: .5rem 0rem;
    font-weight: 500;
}
.cupon_reserva_mobile label{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cupon_reserva_mobile label i{
    display: flex;
    margin-left: 5px;
}
.modalPasajeroMobile {
    background: rgba(255, 255, 255, .5);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    width: 100%;
    border-radius: 4px;
    padding: 0rem .5rem;
    border-top: 1px solid #ddd;
}
