.loader_box{
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items:center;
    align-content: center;
    display: flex;
    background-color: #fff;
    z-index: 99;
    position: absolute;
    top: 0;
    /*background-image: url('../assets/images/fireworks.webp');
    background-size: cover;*/
}
.loader_gif{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 9;
}


@media only screen and (max-width: 768px){
    .loader_gif{
        width: 350px;
    }    
}