/*
 .footer_prueba {
    display: flex;
    background: aliceblue;
    width: 100%;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan automáticamente 
}

.footer_prueba1 {
    display: flex;
    background: antiquewhite;
    width: 25%;
    flex-shrink: 0; 
}

.footer_prueba2 {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    background: gray;
    flex-shrink: 0; 
}

.footer_prueba2 div {
    flex-grow: 1;
    flex-basis: calc(33.33% - 10px); 
    margin: 5px;
}


@media (max-width: 768px) {
    .footer_prueba1{        
        flex-basis: calc(80% - 10px); 
    }
    .footer_prueba2 div {
        flex-basis: calc(80% - 10px); 
    }
}
*/
/*
.footerprueba{
    width: 100%;
    background-color: #005B81;
}
.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }    
  .wrap    { 
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }  

  .wrap li a{
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    justify-content: center;
  }
   
  .flex-item {
    background: tomato;
    padding: 5px;
    width: 24%;
    height: auto;
    margin: 5px;
    color: white;
    font-weight: 400;
    font-size: 1em;
    text-align: end;
    min-width: 200px;
  }

  .flex-item ul{
    padding-left: 0px;
  }
.box-flex-item{
    display: inline-block;
    background: pink;
}

*/
.footer{
    width: 100%;
    padding-top: 6rem;
    background-color: #005B81;
    
  }
  .footer_content{
    width: 100%;
    display: flex;
  }
  
  .footer_main_box img{
    /*width: 200px;*/
    width: 60%;
    align-self: center;
  }
  .footer_main_box p{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
  }
  .footer_container{
    display: flex;
    width: 75%;
    justify-content: space-around;
  }
  .footer_main_box{
    width: 25%;  
    margin-bottom: 3rem;
    padding: .5rem 0rem;
    align-content: space-between;
    display: grid;
  }
  .footer_box {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer_box li{
    padding: .5rem 0rem;
  }
  
  .footer_box li h3{
    /*color: #747474;*/
    color: #fff;
    font-weight: 500;
    margin-bottom: .5rem;
    font-size: 20px;
  }
  
  .footer_box li a{
    font-size: 16px;
    /*color: #7C7C7C;*/
    color: #fcfcfc;
    font-weight: 400;
    text-decoration: none;
  }
  .footer_copyright{
    background-color: #003466;
    padding: 1.2rem 0rem;
    text-align: center;
    color: #fff;
  }
  .footer_copyright label{
    margin-right: 5px;
    font-size: 16px;
  }
  .box_socials_links{
    margin: .5rem 0rem;
  }
  .carga .box_link_social{
  margin-right: 8px;
  }
  .footer_main_box img{
    text-align: center;
  }
  .footer_newsletter{
    display: flex;
    justify-content: space-between;
  }
  .footer_newsletter input{
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #000;
    margin: 5px 0;
    opacity: .5;
    padding: 7px 8px;
    width: 73%;
    font-weight: 500;
  }
  .footer_newsletter button{
    width: 25%;
    padding: 7px 8px;
    border-radius: 4px;
    border: 1px solid #fff;
    margin: 5px 0px;
    background: #fff0;
    color: #fff;
  }
  .box_socials_links{
    margin: .5rem 0rem;
  }
  
  @media (max-width: 768px){
    .footer_content{
        display: block;
    }  
    .footer_main_box{
        width: 100%;
    }
    .footer_container{
        width: 100%;
        display: block;
    }
    .footer_box{
        width: 100%;
    }
    .footer_box ul{
        margin-left: 0rem;
        padding-left: 0rem;
    }
    .footer_copyright label{
        font-size: 14px;
    }
  }