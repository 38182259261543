.box_content_itinerario_route_select{
    width: 65%;
    display: flex;
    align-items: center;
}
.box_content_itinerario_route_select2{
    width: 50%;
}

.content_itinerario2{
    display: flex;
}
.box_rutas{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.content_itinerario2 H2{
    font-size: 20px;
    margin-bottom: 0rem;
}


.item_itinerario2{
    width: calc(33% - 10px);
    margin: 5px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem;
}
.item_itinerario1{
    width: 100%;
    margin: 5px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem;
}
.box_itinerario2{
    width: 50%;
    display: inline-grid;
}





.items_box_itinerario{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


/* Descktop */

.rutas_horarios{
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 6rem;
    background: #E8E8E8;
}
.select_ruta_horario {
    display: flex;
    margin-left: 5px;
    justify-content: space-between;
    padding: .5rem;
}
.select_ruta_horario i{
    display: flex;
    align-items: center;
}
.container_rutas_horarios{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.content_rutas_horarios{
    width: 64%;
}
.content_mapa_rutas_horarios{
    width: 35%;
}
.box_rutas_horarios{
    width: 100%;
    display: block;
    background: #fff;
    margin-bottom: 10px;
    padding: 1rem;
}

.box_servicios_itinerario{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.box_buque_itinerario{
    width: 35%;
}
.select_rutas_horarios{
    width: 50%;
}
.box_select_rutas_horarios{
    min-width: 60%;
    margin-left: 1rem;
}
.header_rutas_horarios{
    width: 100%;
    display: flex;
}
.select_rutas_horarios{
    width: 48%;
    display: flex;
    align-items: center;
}
.title_rutas_horarios{
    width: 52%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.item_rutas_horarios{
    width: calc(30% - 10px);
    margin: 5px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem 0rem;
    color: #225981;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.body_rutas_horarios{
    width: 100%;
    display: flex;
}
.box_dias_itinerario{
    width: 48%;
    display: grid;
}
.box_items_itinerario{
    width: 52%;
}
.item_hora_itinerario{
    width: calc(33% - 10px);
    margin: 5px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem .5rem;
    color: #434343;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.item_dia_itinerario{
    width: calc(100% - 10px);
    margin: 5px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 10px;
    padding: 1rem;
}
.item_dia_itinerario h2{
    font-size: 18px;
    margin-bottom: 0rem;
    color: #225981;
    font-weight: 600;
}

.available_services{
    width: 64%;
    padding: 1rem 0rem;
    background-color: #f5f5f5;
    text-align: center;
    align-content: center;
}

.available_services h3{
    font-size: 1rem;
    margin-top: .5rem;
    color: #747474;
}
.available_services_buque{
    width: 100%;
    background: #f5f5f5;
    padding: 1rem 0rem;
    display: grid;
}
.available_services_buque p{
    text-align: center;
    font-size: 1rem;
    margin-top: .5rem;
    font-weight: 500;
    color: #747474;
}

.box_available_services{
    width: 100%;
    justify-content: center;
    display: grid;
    color: #747474;
}
.box_available_services img{
    max-width: 200px;
}
.box_available_services h4{
    font-weight: 600;
    margin-top: 0.5rem;
}
.content_mapa {
    width: 100%;
    height: 100%;
}
.title_rutas_horarios span{
    width: 3px;
    height: 35px;
    background-color: #225981;
}
.item_itinerario.no-viaje {
    width: 100%;
    text-align: center;
}

/* Celular */
.container-rutas-horario{
    background-color: red;
    width: 100%;
    height: auto;
    padding: 3rem 0rem;
    background-color: #E8E8E8;
}
.content-rutas-horario{
    width: 100%;
    display: block;
    padding: 1rem;
    background: #fff;
    margin-bottom: .8rem;
    border-radius: 3px;
}
.box-select-ruta{
    width: 100%;
  
    margin-bottom: .8rem;
    
}
.item-hora-itinerario{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.item-hora-itinerario label:nth-child(1){
    color: #225981;
}
.item-hora-itinerario label:nth-child(2){
    color: #225981;
    font-weight: 600;
    font-size: 16px;
}
.items-box-itinerario h3{
    font-size: 16px;
    font-weight: 600;
}
.item-hora-itinerario label{
    font-size: 14px;
}
.items-box-itinerario{
    margin: 1rem 0rem;
}
.available-services{
    width: 100%;
    background-color: #f5f5f5;
        text-align: center;
}
.available-services h3{
    font-size: 16px;
    margin-bottom: 0rem;
    padding-top: 1rem;
}
.box-servicio-horario{
    margin-bottom: .8rem;
}
.body-rutas-horario p{
    font-size: 12px;
    color: #747474;
    text-align: end;
}
.item-itinerario.no-viaje {
    font-size: 14px;
}
.box_select_rutas_horarios.phone{
    margin-left: 0rem;
}
.select_ruta_horario.phone{
    border: none;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    margin-left: 0px;
}
.select_route_ruta_horario{
    width: 300px;
    margin-bottom: 10px;
}
.box_select_route{
    width: 100%;
    background: #fff;
}
.select_ruta__horario{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: .5rem .5rem;
    cursor: pointer;
}
.select_ruta__horario i{
    display: flex;
}
.box_selected_rutas_horarios{
    width: 50%;
    display: grid;
}
.box_selected_rutas_horarios label{
    width: calc(30% - 10px);
    margin: 5px;
    margin-top: 10px;
    padding: 1rem 0rem;
    color: #225981;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}


.rutas_horario_tabs{
    display: flex;
}
.rutas_horario_tabs .select_route_ruta_horario:nth-child(2){
    margin-left: 1rem;
}

.tb_tyh{
    border: 1px solid transparent;
}
.tb_tyh_edit{
    text-align: center;
}


th.tb_tyh_edit{
    font-size: 18px;
    margin-bottom: 0rem;
    color: #225981;
    font-weight: 600;

}
td.dia_semana{
    font-size: 18px;
    margin-bottom: 0rem;
    color: #225981;
    font-weight: 600;
}




th.tb_tyh_edit:nth-child(2) {
    border-right: 3px solid #225981;
}

th.tb_tyh_edit:nth-child(3) {
    border-right: 3px solid #225981;
}