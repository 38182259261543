.buscador{
    margin-top: 6rem;
}
.box_preguntas_frecuentes_title h3{
    font-size: 25px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
/**/
.box_preguntas_frecuentes_title {
    width: 100%;
    height: auto;
    margin-top: 6rem;
    padding: 0 12px;
}
.box_preguntas_frecuentes_title p {
    font-size: 18px;
    margin-bottom: 2rem;
    color: #545454;
}


.box_group-card{
    
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 12px;
    background-size: contain;
    color: #fff;    
}
.contact-box-card{
    height: 250px;
    /*margin-bottom: 4rem;*/
}

.box_content-card{
    padding: 12px 12px; 
    display: flex;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
}

.box_icon-card{
    display: inline-block;
    width: 10%;
}
.box_icon-card img{
    width: 100%;
}
.boxes_content{
    display: inline-block;
    width: 90%;
    padding: 5px 0px 15px 8px;
}
.box_content-title{
    width: 100%;
    margin-bottom: 3px;
}
.box_content-title h2{
    font-size: 22px;
    font-weight: 500;
    color: #141414;
}
.box_content-subtext {
    margin-bottom: 16px;
}
.box_content-subtext p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext ul li a{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    color: #141414;
    letter-spacing: 0;
    line-height: 24px;
    
}
.box_content-subtext li:hover{
    color: #141414;
   text-decoration: underline;
    
}
.box_content-btn{
    width: 100%;
}
.box_content-btn a{
/* padding: 8px 11px;*/
    padding: 10px 19px 9px;
    color: #fff;
    /*background: #00155F;*/
    background:#002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3)
}
.box_content-btn a:hover{
    background: #FF8F0095;
}


::-webkit-input-placeholder {
    color: #cfcfcf !important;

  }

  input.ant-input.ant-input-lg.css-dev-only-do-not-override-w8mnev {
    font-size: 14px;
}

/* Lista de resultados*/

.list_result{
    width: 50%;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 7px 0px;
}
.list_result li{
    padding: 7px 0px 7px 0;
}

.list_result li:hover{
    background-color: #f7f7f7;
}
.list_result li a{
    text-decoration: none;
    padding-left: 12px;
    color: #141414;
    width: 100%;
    display: flex;
} 


.buscador input[type=text] {
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 48px;
    line-height: 24px;
    border-radius: 5px;
    width: 100%;
}
/*
h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
}*/
.box_content-subtext ul{
    margin-left: 0rem;
    padding-left: 0rem;
}
.box_content-subtext ul li a{
    text-decoration: none;
}

@media (max-width: 768px){
    .box_preguntas_frecuentes_title {
        margin-top: 0rem;
        padding: 0 12px;
    }
    .box_preguntas_frecuentes_title h3{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .box_preguntas_frecuentes_title p{
        font-size: 14px;
        margin-bottom: 1rem;
    }

}