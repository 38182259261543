.avisoHeader{
    width: 100%;
    /*background: #F5A800*/;
    background: #FFC106;
    padding: .6rem 0rem;
}
.avisoHeader_content{
    display: flex;
    justify-content: center;
}
.avisoHeader_content i{
    margin-right: 5px;
}
.avisoHeader_content p{
    margin-bottom: 0rem;
    font-size: 14px;
    color: #353A40;
    font-weight: 700;
}
.btn_aviso_cintillo{
    margin: 0rem 1rem;
    color: #353A40;
    text-decoration: none;
    border: 2px solid #353A40;
    padding: .2rem .4rem;
    border-radius: 4px;
    font-weight: 600;
}
.navbar {
    align-items: center;
    background: linear-gradient(180deg,#373737F2,rgba(40,40,40,0));
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 9;
}
.menu_img_show{
    background-image: url('../assets/images/logo.png');
    width: 100px;
    height: 60px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}  
.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}  
.navbar-toggler, .opc_menu_right  {
    display: none;
}
.navbar-toggler,
.navbar-toggler:hover {
    border: none;
}
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}  
.navbar-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row;
    align-items: center;
}
.navbar-menu-left{
    display: none;
}
.navbar-item {
    margin: 0.5rem 0rem;
    justify-content: space-between;
    display: block;
    font-size: 13px;
    align-items: center;
}
.navbar-item i{
    color: #FFF;
    display: flex;
    align-items: center;
}
.navbar-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    display: flex;
    background-color: transparent;
    border: none;
}  
.navbar-link:hover {
    color: #ccc;
}
.navbar-item  .navbar-link:hover:after {
    left: 0;
    right: 0;
}
.navbar-item > .navbar-link:after{
    background-color: #fff;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    right: 50%;
    top: 27px;
    transition-duration: .3s;
    transition-property: left,right,width;
    transition-timing-function: ease-out;
}
.navbar-menu-open-left{
    display: none;
}
.box_menu_right{
    display: flex;
    align-items: center;
}
.main_menu{
    display: flex;
    list-style: none;
    flex-direction: row;
    align-items: center;
}
.navbar-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.content_menu_right{            
    display: flex;
    align-items: center;
}
.navbar-item button{
    border: none;
    background: transparent;
}
i.fi.fi-rs-truck-moving {
    margin-right: 5px;
    font-size: 15px;
}
i.fi.fi-rs-document {
    margin-right: 5px;
    font-size: 15px;
}
i.fi.fi-rr-globe {
    margin-right: 4px;
    font-size: 15px;
}
.navbar-menu-left i.fi.fi-rr-caret-down {
    color: #fff;
}
.botton_opcion_menu{
    display: none;
}

.asidebar_icons_socials{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.asidebar_icons_socials img{
    width: 25px;
}

li.navbar-item.idioma {
    color: #fff;

}
li.navbar-item.idioma:hover {
    cursor: pointer;
    
}


/* Estilo para destacar la nueva sección */
.itinerario_option::after {
    content: "";
    position: absolute;
    top: 35%; /* Ajusta según el diseño */
    right: -10px; /* Ajusta según el diseño */
    width: 10px;
    height: 10px;
    background-color: #A1E2A0;
    border-radius: 50%;
    animation: blink 1.40s infinite;
  }
  
  /* Animación para el parpadeo */
  @keyframes blink {
    0%, 60%, 100% {
      opacity: 1;
    }
    55%, 95% {
      opacity: 0;
    }
  }

@media (max-width: 768px) {

    .itinerario_option{
        width: max-content!important;
    }
    .itinerario_option a{
        justify-content: left!important;
        width: auto;
    }
    
    .navbar-menu, .navbar-menu-left {
      display: none;
    }
    .navbar-brand {
        width: 100%;
        margin-right: 0px;
    }
    .menu_img_show{
        background-image: url('../assets/images/bf-iso-blanco.png');
        width: 45px;
        height: 45px;
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .menu_img_show_left{
        background-image: url('../assets/images/bajaferries-logo-gris.svg');
        width: 70px;
        height: 45px;
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
    }  
    .navbar-toggler, .opc_menu_right {
      display: block;
    }
    .navbar-toggler i{
        color: #fff;
        display: flex;
    }  
    .navbar-menu-open {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0px;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: #003466;
      padding: 1rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      transform: translateX(100%);
      transition: transform 0.3s ease;
    }
    .navbar-menu-open-left {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0px;
        right: 0;
        bottom: 0;
        width: 100%;
        background-color: #FFFFFF;
        padding: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        transform: translateX(100%);
        transition: transform 0.3s ease;
        margin-bottom: 0;
      }  
    .navbar-menu-open.navbar-menu, .navbar-menu-open-left.navbar-menu-left {
      transform: translateX(0);
    }
    .navbar-menu-open li  {
        /*border-bottom: 1px solid #fff;
        margin: 0.5rem 0.5rem;*/
    }
    .navbar-menu-open-left li{
        border-bottom: 1px solid #d4d4d4;
    }
    .opc_menu_right{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 2rem;
        padding: 0px 10px;
    }
    .menu_cerrar_option{
        display: flex;
    }
    .idioma_option{
        color: #fff;
        align-items: center;
        display: flex;
        margin-right: 2rem;
    }
    .idioma_option_left{
        color: #707070;
        align-items: center;
        display: flex;
        margin-right: 2rem;
    }
    .idioma_option i, .idioma_option_left i{
        display: flex;
    }
    .idioma_option label, .idioma_option_left label{
        margin-right: 3px;
    }
    .btn_cerrar_option{
        color: #fff;
        align-items: center;
        display: flex;
    }
    .btn_cerrar_option_left{
        color: #707070;
        align-items: center;
        display: flex;
    }
    .btn_cerrar_option i, .btn_cerrar_option_left i{
        display: flex;
    }
    .navbar-menu-open-left .navbar-link {
        color: #707070;
        text-decoration: none;
        transition: color 0.3s ease;        
    }      
    .navbar-item {
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding: 0.1rem 0rem;
    }
    .main_menu{
        display: block;
        width: 100%;
    }
    .content_menu_right{            
        display: none;
    }
    .navbar-menu-left i.fi.fi-rr-caret-down {
        color: #707070;
    }
    .botton_opcion_menu{
        display: block;
    }
    .avisoHeader_content label{
        width: auto;
        min-width: 120px;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
   
    

  }


.navbar-item-left{
    padding: 7px 7px;
    position: relative;
    display: block;
}
.navbar-item-left a{
    display: flex;
    justify-content: space-between;
    color: #707070;
    text-decoration: none;    
}
.navbar-item-left ul {
    background: #7070700d;
    padding-left: 1rem;
}

.navbar-item-left ul li a{
    padding: 5px 5px;
    color: #707070;
    text-decoration: none;
}

