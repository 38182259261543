.box_context_kit h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
.box_context_kit p{
    font-size: 17px;
    margin-bottom: 16px;
    color: #545454;
}
.optionsDownload h3{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
.context_kit{
    display: inline-flex;
    margin-top: 4rem;
}
.context_text_kit{
    width: 50%;
}
.box_context_kit{
    max-width: 80%;
    text-align: justify;
   
}
.box_context_kit h3{
    margin-bottom: 1rem;
}
.box_btn_kit {
    margin-top: 2rem;
}
.box_btn_kit a{
    text-decoration: none;
    padding: 10px 19px 11px;
    color: #fff;
    background: #002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.context_gif_kit{
    width: 50%;
    background: aliceblue;
    justify-content: center;
    align-items: center;
    display: flex;
}
.context_gif_kit img{
    width: 50%;
}
.banner-kit-descarga{
   width: 100%;
   justify-content: center;
   align-items: center;
   display: flex;
}
.banner-descarga{
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
    position: relative;
    display: inline-block;
    text-align: center;
}
.box_msg_descarga{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.box_msg_descarga h3{
    color: #fff;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 400;
}
.box_msg_descarga p{
    color: #fff;  

}
.banner-descarga img{
    width: 100%;
    border-radius: 5px;
}

.optionsDownload button{
    margin-right: 5px;
    padding: 14px 25px;
    padding: 10px 19px 10px;
    color: #002648;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #002648;
    margin-bottom: 2rem;
}

button.active {
    padding: 14px 25px;
    padding: 10px 19px 9px;
    color: #fff;
    background: #002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.logosList{
    display: flex;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;
   
}
.itemList_logo{
    width: 25%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.box_content_logo{
    width: 90%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
}
.box_content_logo:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.box_logo{
    padding: 1%;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 150px;
}
.box_text_logo{
    height: 50px;
    background: #F2F3F4;
    align-items: center;
    display: flex;
    justify-content: space-around;
}
.box_text_logo:hover{
    cursor: pointer;
}

/*

hr{
    border-color: #F1f1f195;
}

*/

.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
}
.modal-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
    width: 15%;
}
.cerrarbtn_modal{
    width: 100%;
    align-items: end;
    justify-content: end;
    display: flex;
    padding: 10px;
    
}
.cerrarbtn_modal button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.modal_box{
    padding: 10px;
}

.modal-content .anticon svg {
    width: 30px;
    height: 30px;
    color: #515151;
}

.logoModal{
    justify-content: center;
    align-items: center;
    display: flex;
}


/* Formulario */


.formulario_contacto{
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.contentbox_contactform{
    width: 100%;
}
.box_contactform{
    width: 100%;
    display: inline-flex;
    margin-top: 6px;
}
.boxes_contactform{
    display: grid;
    width: 100%;
    margin-right: 40px;

}
.boxes_contactform_check{
    display: inline-flex;
    width: 100%;
    margin: 0rem 40px 1rem 40px;
}


.boxes_contactform label{
    display: inline-block;
    margin-bottom: 0px;
}
.boxes_contactform input[type=text]{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 48px;
    line-height: 24px;
    border-radius: 5px;
    width: 100%;
}
.boxes_contactform_check input[type=checkbox]{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    height: 18px;
    line-height: 24px;
    border-radius: 5px;
    margin-right: 5px;
    width: 10%;
    margin-top: 4px;
}

.boxes_contactform textarea{
    padding: 7px 11px 9px;
    border: 1px solid #cfcfcf;
    box-shadow: none;
    color: #353535;
    /*min-height: 120px;*/
    line-height: 24px;
    border-radius: 5px; 
    width: 100%;
}

.custom-input::placeholder {
    /*font-size: 16px;  Cambia el tamaño del placeholder */
    /*color: #999;    /* Cambia el color del placeholder */
    /* Otros estilos de placeholder si es necesario */
}

/* Boton enviar */

.box_content-btn{
    width: 100%;
    margin-top: 14px;
   /* margin-left: 40px;*/
}

.boxes_contactform button{
    padding: 10px 19px 9px;
    color: #fff;
    background:#002648;
    border-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-flex;
    text-decoration: none;
    justify-content: center;
    margin-bottom: 2rem;
    cursor: pointer;
}
.boxes_contactform button:disabled{
    background-color: #32404d;
  }
.boxes_contactform button:disabled:hover{
  background-color: #32404d;
}


.boxes_contactform button:hover{
    background: #FF8F0095;
}
.vineta{
    color: red;
}

/*Legal media kit*/

.legal_media_kit{
    width: 100%;
    display: grid;
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.legal_logo_kit{
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 2rem;
}
.legal_logo_kit img{
    width: 100px;
}
.legal_text_kit{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.legal_text_kit p{
    width: 50%;
    text-align: center;
}




/* Responsivo */
@media screen and (max-width:652px){
    .context_kit{
        display: block;
    }
    .context_text_kit {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;    
    }    
    .box_context_kit {
        max-width: 90%;
    }
    .context_gif_kit {
        width: 100%;
        margin-top: 3rem;

    }
    .box_msg_descarga h3{
        font-size: 20px;
    }
    .box_msg_descarga p{
        font-size: 12px;
    }
    .box_logo img{
        width: 68%;
    }
    .logosList{
        display: block;
    }
    .itemList_logo{
        width: 100%;
        margin-bottom: 1rem;
    }
    .legal_text_kit p{
        width: 90%;
    }
    .modal-content{
        width: 90%;
    }
}

@media screen and (max-width: 858px) and (orientation:  landscape){
  

}
