.form_viaje_en_grupo{    
    background: #E8E8E8;
    padding: 6rem 0rem;
}
.form_viaje_en_grupo_container{
    width: 100%;
    display: flex;
}
.form_viaje_en_grupo_img{
    width: 100%;
    height: 100%;
}
.form_viaje_en_grupo_content:nth-child(1){
    width: 50%;
    background: #E8E8E8;
    padding: 3rem 0rem;
}
.form_viaje_en_grupo_content:nth-child(2){
    width: 50%;
    background: #f5f5f5;
    padding: 3rem;
}
.form_viaje_en_grupo_img img{
    width: 100%;
    height: 100%;
}
.form_viaje_en_grupo_content h2{
    font-size: 22px;
}

.form_viaje_en_grupo_content .input-group{
    width: 100%;
}
.input-group{
    margin: .5rem 0rem;
    justify-content: space-between;
}
.input-group input, .input-group select{
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.input-group input:first-child{
    width: calc(60% - 10px);
    margin-right: 10px;
}
.input-group input:nth-child(2),
.input-group select{
    width: 40%
}
.content-input-group{
    display: flex;
}
.box-input-group{
    display: grid;
}
.box-input-group:nth-child(2){
    display: flex;
    align-items: center;
}
.box-input-group div i{
    font-size: 24px;
    cursor: pointer;
}
.box-input-group div:nth-child(2) i{
    color: #E2E2E2;
    margin-right: 4px;
}
.box-input-group div:nth-child(3) i{
    color: #FFC106;
}
.content-input-group{
    width: calc(50% - 5px);
    border: 1px solid #ddd;
    background: #fff;
    padding: 6px 10px;
    border-radius: 4px;
    justify-content: space-between;
}
.box-input-group label:nth-child(1){
    font-size: 14px;
    margin-right: 5px;
}
.box-input-group label:nth-child(2){
    font-size: 12px;
    color: #CFCFCF;   
}
.input-group textarea {
    width: 100%;
    min-height: 90px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /*margin-bottom: 1.5rem;*/
    padding: 4px 10px;
}
.input-group label{
    width: 100%;
    text-align: left;
}
.input-group input[type='checkbox']{
    width: 1rem;
}

.input-group button{
    background: #FFC106;
    padding: 8px 50px;
    color: #545454;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.input-group:nth-child(3),
.input-group:nth-child(5){
    margin-top: 1.5rem;
}





@media only screen and (max-width: 992px) {
    .form_viaje_en_grupo_content:nth-child(1){
        width: 40%;
    }
    .form_viaje_en_grupo_content:nth-child(2){
        width: 60%;
    }
}
@media only screen and (max-width: 820px){

}
@media only screen and (max-width: 768px){
    .form_viaje_en_grupo_container{
        display: block;
    }
    .form_viaje_en_grupo_img img {
        width: 100%;
        height: 56%;
        max-height: 400px;
        object-fit: cover;
        object-position: bottom;
        opacity: .9;
        border-radius: 3px;
    }
    .form_viaje_en_grupo_content:nth-child(1){
        width: 100%;
    }
    .form_viaje_en_grupo_content:nth-child(2){
        width: 100%;
        margin-top: -8rem;
        position: relative;
        border-radius: 8px;
        background: none;
        padding: 0;
    }
    .form_viaje_en_grupo_content_div{
        background: #f5f5f5;
        padding: 2rem;
        margin: 2rem;
        border-radius: 3px;
    }

    .input-group:nth-child(3), .input-group:nth-child(5) {
        margin-top: 1.5rem;
        display: grid;
        width: 100%;
        justify-content: normal;
    }
    .content-input-group {
        width: 100%;
        display: flex;
        margin-bottom: 8px;
    }
}